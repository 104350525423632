import React from 'react';
import Font from 'components/shared/Font';
import { Box } from '@mui/material';

interface IPermissionsBoxProps {
  title: string;
  children: React.ReactNode;
}

export const PermissionsBox: React.FC<IPermissionsBoxProps> = ({title,children}) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        padding: '16px',
      }}
    >
      <Font
        variant="h6"
        sx={{
        mb: 1,
      }}>
        {title}
      </Font>
      {children}
    </Box>
  );
};

export default PermissionsBox;