import { FORM_INPUT_PASSWORD_TEST_ID } from 'components/shared/FormInputPassword/FormInputPassword';
import { FORM_UPLOAD_FILE_INPUT_TESTID } from 'components/shared/FormUploadFileInput/FormUploadFileInput';
import { DIALOG_ID } from 'components/presentation/Dialog/Dialog';
import { useCallback, useEffect, useState } from 'react';

export type OnClickEvent = (doClick: (target: HTMLElement | null) => void) => void;

/**
 * A custom React hook that detects clicks both inside and outside specified elements based on their IDs.
 *
 * @param {string[]} ids - An array of IDs of elements to detect clicks inside and outside of.
 * @param {() => void} onClickOutside - A callback function to be executed when a click occurs outside the specified elements.
 */
export const useClickOutside = (ids: string[], onClickOutside: OnClickEvent) => {
  const [clickedEl, setClickedEl] = useState<HTMLElement>();
  const nonActionableIds = [
    ...ids, 
    `${DIALOG_ID}_CANCEL_BUTTON`,
    `${DIALOG_ID}_ACCEPT_BUTTON`,
    `${FORM_INPUT_PASSWORD_TEST_ID}_SHOW_PASSWORD`,
    `${FORM_UPLOAD_FILE_INPUT_TESTID}_BUTTON`
  ];


  const doClick = (target: HTMLElement | null) => {
    target?.click();
  };

  /**
   * Event handler that checks if a click occurred inside or outside the specified elements and triggers the appropriate callback.
   *
   * @param {MouseEvent} event - The mouse event object.
   */

  // MuiListItemText-root
  // MuiListItemText-primary
  const handleClick = useCallback(
    (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      const clickedId = clickedElement.id;

      const actionableElements = ['A', 'BUTTON'];
      const isAppBarLink = 
        clickedElement.classList.contains('MuiListItemText-primary') ||
        clickedElement.classList.contains('MuiListItemText-root') ||
        clickedElement.classList.contains('MuiListItemButton-root') ||
        clickedElement?.parentElement?.classList.contains('MuiListItemText-primary') ||
        clickedElement?.parentElement?.classList.contains('MuiListItemText-root');

      const isSelectItem =
        clickedElement.classList.contains('MuiMenuItem-root') ||
        clickedElement?.parentElement?.classList.contains('MuiList-root');

      if (isSelectItem) return;

      const isActionableElement = actionableElements.includes(clickedElement.nodeName);

      if ((isAppBarLink || isActionableElement) && !nonActionableIds.some((id) => id === clickedId)) {
        setClickedEl(clickedElement);
        onClickOutside(() => doClick(clickedElement));
      }
    },
    [ids, clickedEl]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
};

export default useClickOutside;
