import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { formLabelClasses } from '@mui/material/FormLabel';
import { iconButtonClasses } from '@mui/material/IconButton';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import { switchClasses } from '@mui/material/Switch';
import { selectClasses } from '@mui/material/Select';
import styled from 'styled-components';

import {
  disabled,
  disabledBackground,
  knowFillDisabled,
} from 'config/theme/colors';
import { formHelperTextClasses } from '@mui/material';

export const Container = styled.form<{
  noMarginsOnFormControls?: boolean;
}>`
  width: 100%;
  overflow: visible;
  fieldset {
    & .MuiFormControl-root {
      vertical-align: unset;
      &:not(:first-child):not(:nth-child(2)) {
        margin-top: ${(props) =>
          props.noMarginsOnFormControls ? '0px' : '2rem'};
      }
      &:nth-child(even) {
        &:not(.MuiFormControl-fullWidth) {
          margin-right: ${(props) =>
            props.noMarginsOnFormControls ? '0px' : '1.5rem'};
        }
      }
    }
  }

  &.switches {
    fieldset {
      & .MuiFormControl-root {
        vertical-align: unset;
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }
  }
`;

export const Fieldset = styled.fieldset<{
  isFlex?: boolean;
  isFlexColumn?: boolean;
  disabled?: boolean;
}>`
  margin: 0;
  border: none;
  margin-inline-start: 0rem;
  margin-inline-end: 0rem;
  padding-block-start: 0rem;
  padding-inline-start: 0rem;
  padding-inline-end: 0rem;
  padding-block-end: 0rem;
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.isFlexColumn ? 'column' : 'row')};
  flex-wrap: wrap;

  &:disabled {
    color: ${disabled};
    pointer-events: none; /* Prevent interaction */
    input,
    &
      .${inputLabelClasses.root},
      &
      .${formLabelClasses.root},
      &
      .${formControlLabelClasses.label},
      &
      .${switchClasses.track},
      &
      .${inputBaseClasses.input},
      &
      .${iconButtonClasses.root},
      &
      .${selectClasses.icon},
      &
      .${inputLabelClasses.formControl},
      &
      .${formHelperTextClasses.root},
      &
      .Mui-checked,
    & .MuiCheckbox-root.Mui-checked,
    & .MuiButton-Adorned {
      color: ${disabled};
    }
    & .${switchClasses.track} {
      background-color: ${disabledBackground};
    }
    & .${switchClasses.checked} {
      color: ${knowFillDisabled};
    }
    & .MuiButton-Adorned {
      border-color: ${disabledBackground};
    }
  }
`;
