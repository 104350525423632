import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IDefaultDashboard } from 'types/defaultDashboard';

type IFetchDefaultDashboard = IDefaultDashboard;

export function useFetchDefaultDashboard(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchDefaultDashboard>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchDefaultDashboard>, IResponseError>(
    [QueryKeys.GET_DEFAULT_DASHBOARD],
    () => {
      return api.get<IFetchDefaultDashboard>('/dashboards/getdashboard');
    },
    queryOptions,
  );
}
