import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { divider, primaryMain, textSecondary } from 'config/theme/colors';

export interface ITabPanelProps extends CardProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  title?: string;
  description?: string;
  isIntersectingBottom?: boolean;
  isTabsInTabs?: boolean;
}

export const TabPanel = styled(
  ({
    children,
    index,
    value,
    title,
    description,
    isIntersectingBottom,
    isTabsInTabs: _isTabsInTabs,
    ...props
  }: ITabPanelProps) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{
        width: '100%',
      }}
      {...props}
    >
      {value === index && (
        // If the tab is intersecting with the bottom of the page and the element fits the page,
        // then we should remove the border.
        <Card className={isIntersectingBottom ? '' : 'has-border'}>
          {title && <TabTitle variant="h6">{title}</TabTitle>}
          {description && (
            <TabDescription variant="body1">{description}</TabDescription>
          )}
          {children}
        </Card>
      )}
    </div>
  ),
)`
  & .MuiCard-root:first-child {
    padding: ${({ isTabsInTabs }) =>
      isTabsInTabs ? '30px 0px 0px 0px' : '3rem'};
    width: 100%;
    box-shadow: ${({ isTabsInTabs }) =>
      isTabsInTabs
        ? 'none'
        : '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'};
    overflow: visible;
  }

  & .has-border {
    & #action-bar {
      z-index: 9;
      border-top: 1px solid ${divider};
      border-radius: 0px;
    }
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  width: 100%;
  max-width: 57.5rem;
`;

export const TabsContentWrapper = styled(Box)`
  width: 100%;
`;

export const Tab = styled(MuiTab)`
  padding: 0.563rem 1rem;
  margin-top: 2rem;

  &.Mui-selected {
    color: ${primaryMain};
  }
`;

export const Tabs = styled(MuiTabs)`
  & .MuiTabs-indicator {
    background-color: ${primaryMain};
  }

  & .MuiTabs-scrollButtons {
    align-items: flex-end;
    padding-bottom: 0.9rem;
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    width: 0px;
  }
`;

export const TabTitle = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TabDescription = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: ${textSecondary};
  margin: 0.5rem 0rem 2.5rem 0rem;
`;

export const TabContent: React.FC<PropsWithChildren> = ({ children }) => {
  return <div>{children}</div>;
};
