import { Value } from 'components/shared/Form/Form';
import {
  InputType,
  InputTypeRegExpMap,
} from 'components/shared/FormInput/FormInput';

export const validationPatterns: InputTypeRegExpMap = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  text: /^[\s\S]*$/,
  phone: /^[0-9]{10}$/,
  number: /^[0-9]+(\.[0-9]+)?$/,
  password: /^[\s\S]*$/,
  file: /^[\s\S]*$/,
};

export const isInputValid = (
  value: Value | undefined,
  inputType: InputType,
  isRequired?: boolean,
): boolean => {
  if (!value && isRequired) return false;
  if (!value) return true;

  return validationPatterns[inputType].test(value.toString());
};

export const detailedIsInputValid = (
  value: Value | undefined,
  inputType: InputType,
  isRequired?: boolean,
): {
  isValid: boolean;
  failedValidationType: 'required' | 'invalid' | null;
} => {
  // If required and no value then it is invalid
  if (!value && isRequired) {
    return {
      isValid: false,
      failedValidationType: 'required',
    };
  }

  // If not required and no value then it is valid
  if (!value) {
    return {
      isValid: true,
      failedValidationType: null,
    };
  }

  // If value and required or not then check if it is valid
  const matchPattern = validationPatterns[inputType].test(value.toString());

  return {
    isValid: matchPattern,
    failedValidationType: matchPattern ? null : 'invalid',
  };
};
