import React from 'react';
import External from './Tabs/External';
import Internal from './Tabs/Internal';

export const TabContentFn = ({ isTenantScoped = false }) => ({
  Internal: {
    label: 'Internal',
    title: undefined,
    description: undefined,
    children: <Internal isTenantScoped={isTenantScoped} />,
  },
  External: {
    label: 'External',
    title: undefined,
    description: undefined,
    children: <External isTenantScoped={isTenantScoped} />,
  },
});
