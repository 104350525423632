import React, { SVGProps } from 'react';

export const LockOpenFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="LockOpenFilled">
      <path
        id="Vector"
        d="M12.0042 17C12.5347 17 12.9875 16.8164 13.3625 16.4492C13.7375 16.082 13.925 15.6406 13.925 15.125C13.925 14.625 13.7361 14.1708 13.3583 13.7625C12.9805 13.3542 12.5263 13.15 11.9958 13.15C11.4653 13.15 11.0125 13.3542 10.6375 13.7625C10.2625 14.1708 10.075 14.6292 10.075 15.1375C10.075 15.6458 10.2639 16.0833 10.6417 16.45C11.0195 16.8167 11.4737 17 12.0042 17ZM5.5 22C5.0875 22 4.73438 21.8531 4.44063 21.5594C4.14688 21.2656 4 20.9125 4 20.5V9.65C4 9.2375 4.14688 8.88438 4.44063 8.59063C4.73438 8.29688 5.0875 8.15 5.5 8.15H13.75V5.75C13.75 4.43583 14.2134 3.31563 15.1402 2.38938C16.0669 1.46313 17.1878 1 18.5027 1C19.8176 1 20.9375 1.46313 21.8625 2.38938C22.7875 3.31563 23.25 4.43583 23.25 5.75H21.75C21.75 4.85 21.4343 4.08333 20.8029 3.45C20.1716 2.81667 19.4049 2.5 18.5029 2.5C17.601 2.5 16.8333 2.81597 16.2 3.44792C15.5667 4.07986 15.25 4.84722 15.25 5.75V8.15H18.5C18.9125 8.15 19.2656 8.29688 19.5594 8.59063C19.8531 8.88438 20 9.2375 20 9.65V20.5C20 20.9125 19.8531 21.2656 19.5594 21.5594C19.2656 21.8531 18.9125 22 18.5 22H5.5Z"
        fill="black"
        fillOpacity="0.56"
      />
    </g>
  </svg>
);

export default LockOpenFilled;
