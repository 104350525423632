import MuiTypography, { TypographyProps, typographyClasses } from '@mui/material/Typography';
import { textSecondary } from 'config/theme/colors';
import React from 'react';
import styled from 'styled-components';

export interface ITitleProps extends TypographyProps {
  children: string | React.ReactNode;
}

export const Font: React.FC<ITitleProps> = ({ children,...props }: ITitleProps) => {
  return (
    <Typography {...props}>
      {children}
    </Typography>
  );
};

const Typography = styled(MuiTypography)`
  font-family: Gotham;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);

  &.${typographyClasses.h1} {
    font-size: 96px;
    font-weight: 300;
    line-height: 116.7%; /* 112.032px */
    letter-spacing: -1.5px;
  }

  &.${typographyClasses.h2} {
    font-size: 60px;
    font-weight: 300;
    line-height: 120%; /* 72px */
    letter-spacing: -0.5px;
  }

  &.${typographyClasses.h3} {
    font-size: 48px;
    font-weight: 400;
    line-height: 116.7%; /* 56.016px */
  }

  &.${typographyClasses.h4} {
    font-size: 34px;
    font-weight: 400;
    line-height: 123.5%;
    letter-spacing: 0.25px;
  }

  &.${typographyClasses.h5} {
    font-size: 24px;
    font-weight: 400;
    line-height: 133.4%; /* 32.016px */
  }

  &.${typographyClasses.h6} {
    font-size: 20px;
    font-weight: 400;
    line-height: 160%; /* 32px */
    letter-spacing: 0.15px;
  }

  &.${typographyClasses.body1} {
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.15px;
    color: ${textSecondary};
  }
`;

export default Font;
