import React, { useMemo } from 'react';
import { InsertChart, Settings, Apartment } from '@mui/icons-material';
import { Drawer } from 'components/presentation';
import { INavItem } from 'components/presentation/NavItem/NavItem';
import { useAuth } from 'context/Auth';

interface IAppDrawer {
  onSelectItem: (item: INavItem) => void;
  currentRoute: string;
}

const AppDrawer = ({ onSelectItem, currentRoute }: IAppDrawer) => {
  const { user } = useAuth();

  const DRAWER_ITEMS = useMemo(() => {
    const rawItems = [
      {
        icon: <InsertChart />,
        name: 'Reports',
        url: '/',
        filterFn: () => true,
      },
      {
        icon: <Settings />,
        name: 'Settings',
        url: '/settings',
        filterFn: () => { return user?.isTenantAdmin || user?.isPermissionsAdmin; },
      },
      {
        icon: <Apartment />,
        name: 'Tenants',
        url: '/tenants',
        filterFn: () => user?.isProductAdmin,
      },
    ];

    return rawItems.filter((item) => item.filterFn());
  }, [user]);

  return (
    <Drawer
      items={DRAWER_ITEMS}
      onSelectItem={onSelectItem}
      currentRoute={currentRoute}
      isStatic
    />
  );
};

export default AppDrawer;
