import React, { SVGProps } from 'react';

export const DeltaDentalVector = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={236}
    height={266}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M76.896 71.463 3.126 200.096h149.932L78.092 68.683l-1.196 2.78ZM117.569 0 236 200.096 192.934 266H-46.719L-85 202.081.334 67.095h77.36L41.405 0h76.163Z"
      opacity={0.5}
    />
    <defs>
      <linearGradient
        id="a"
        x1={15.137}
        x2={291.896}
        y1={69.615}
        y2={316.677}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACF65" />
        <stop offset={1} stopColor="#41A928" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeltaDentalVector;