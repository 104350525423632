import React, { useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormControlProps } from '@mui/material/FormControl';

import { IState } from '../Form/Form';
import { Container, InputLabel, MenuItem, Select } from './FormSelect.styles';
import { SxProps } from '@mui/material';

export type Value = string | number;

export const SELECT_ID = 'SELECT';

export interface IFormSelectOption {
  value: Value;
  label: string;
}

export interface IFormSelectProps extends FormControlProps {
  name: string;
  options: IFormSelectOption[];
  label: string;
  prop: keyof IState;
  handleOnSelect?: (prop: keyof IState, value: Value) => void;
  defaultValue?: Value;
  required?: boolean;
  labelSx?: SxProps;
  hasGlobalError?: {
    hasError?: boolean;
    failedValidationType?: 'required' | 'invalid' | null;
  };
}

export type FormSelectType = React.ReactElement<typeof FormSelect>;

export const FormSelect: React.FC<IFormSelectProps> = ({
  disabled,
  label,
  prop,
  options,
  defaultValue,
  handleOnSelect,
  labelSx,
  hasGlobalError,
  ...props
}: IFormSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<Value>();
  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as Value;
      setSelectedValue(value);
      handleOnSelect?.(prop, value); // Handle Parent State
    },
    [prop],
  );

  const renderOptions = options.map((option: IFormSelectOption) => (
    <MenuItem value={option.value} key={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <Container {...props} key={SELECT_ID}>
      <InputLabel
        sx={labelSx}
        id={`${prop}-label`}
        key={`${SELECT_ID}_LABEL`}
        disabled={disabled}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${prop}-label`}
        id={props.id}
        defaultValue={defaultValue}
        label={label}
        onChange={handleChange}
        key={`${SELECT_ID}_SELECT`}
        disabled={disabled}
        displayEmpty
        // value={selectedValue}
        placeholder={`Select ${label.toLocaleLowerCase()}`}
        renderValue={
          selectedValue !== ''
            ? undefined
            : () => <span>{`Select ${label.toLocaleLowerCase()}`}</span>
        }
        error={hasGlobalError?.hasError}
      >
        {renderOptions}
      </Select>
    </Container>
  );
};

export default FormSelect;
