import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';

type ILogOutData = any

export function useLogOut(
  mutationOptions?: UseMutationOptions<
    IResponse<ILogOutData>,
    IResponseError
  >,
) {
  return useMutation<
    IResponse<ILogOutData>,
    IResponseError
  >(
    [QueryKeys.LOGOUT],
    () => {
      return api.get<ILogOutData>('/logout');
    },
    mutationOptions,
  );
}
