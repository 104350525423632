import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Form, { IState } from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { FormInput } from 'components/shared/FormInput/FormInput';
import { useFormContext } from 'context/formContext';
import { useSetTenantBranding } from 'api/hooks/useSetTenantBranding';
import { useFetchTenantBranding } from 'api/hooks/useFetchTenantBranding';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { QueryKeys } from 'constants/query-keys';

const FORM_DATA_DEFAULTS: IState = {
  companyName: {
    legend: 'Company Name',
  },
};

const DISCARD_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Discard changes?',
  content: 'Are you sure you want to stop editing without saving changes?',
  acceptButtonText: 'Discard changes',
  cancelButtonText: 'Cancel',
};

const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Apply changes?',
  content:
    'Are you sure you want to save the changes made to your Company name?',
  acceptButtonText: 'Apply Changes',
  cancelButtonText: 'Cancel',
};

const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Edit Branding details?',
  content:
    'Remember that any changes made here will affect company’s branding details.<br /><br /> Are you sure you want to proceed?',
  acceptButtonText: 'Start Editing',
  cancelButtonText: 'Cancel',
};

const DEFAULT_DIALOG_PROPS = {
  discard: DISCARD_DIALOG_PROPS,
  applyChanges: APPLY_CHANGES_DIALOG_PROPS,
  editApproval: EDIT_APPROVAL_DIALOG_PROPS,
};

const FORM_KEY = 'form-branding';

export const BrandingTab = () => {
  const queryClient = useQueryClient();
  const { setIsFormEnabled, resetForm } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );

  const {
    isFetching: isTenantResponseFetching,
    data: fetchTenantBrandingResponse,
  } = useFetchTenantBranding();

  useEffect(() => {
    // Only initialize the form data if the fetchTenantBrandingResponse is not null
    if (fetchTenantBrandingResponse?.data?.response && !data) {
      setData((prevData) => ({
        ...prevData,
        companyName: {
          ...FORM_DATA_DEFAULTS.companyName,
          value: fetchTenantBrandingResponse.data?.response?.tenantName,
          defaultValue: fetchTenantBrandingResponse.data?.response?.tenantName,
        },
      }));
      setInitialData((prevData) => ({
        ...prevData,
        companyName: {
          ...FORM_DATA_DEFAULTS.companyName,
          value: fetchTenantBrandingResponse.data?.response?.tenantName,
          defaultValue: fetchTenantBrandingResponse.data?.response?.tenantName,
        },
      }));
    }
  }, [fetchTenantBrandingResponse, data]);

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Company name have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    // refetching tenant branding to get the latest changes
    queryClient.refetchQueries([QueryKeys.TENANT_BRANDING]);
    document.title = `${data?.companyName.value} - infobridgeanalytics.com`;
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: updateSettingsMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetTenantBranding({
    onError: onApplyChangesError,
    onSuccess: onApplyChangesSuccess,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleOnChange = useCallback((prop: keyof IState, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...(prevData as IState)[prop],
        value,
        defaultValue: value,
      },
    }));
  }, []);

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = useCallback(async () => {
    await updateSettingsMutation({
      tenantName: (data as IState)?.companyName.value as string,
    });
  }, [data]);

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;
  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        instantlyCloseOnAccept={false}
        isLoading={isMutating}
      />
      <ComponentSpinner active={isTenantResponseFetching}>
        {data && (
          <Form
            state={data as IState}
            handleCancel={handleOnCancelEditMode}
            handleSubmit={handleSubmitChanges}
            key={formKey}
            handleShowApproveEditDialog={handleShowApproveEditDialog}
          >
            <FormInput
              required
              type="text"
              id="companyName"
              name="companyName"
              prop={'companyName'}
              label="Company Name"
              handleOnChange={handleOnChange}
              defaultValue={(data as IState)['companyName'].defaultValue}
            />
          </Form>
        )}
      </ComponentSpinner>
    </>
  );
};

export default BrandingTab;
