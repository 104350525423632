import React, { useEffect,  useState, useCallback } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, TableSortLabel, Tooltip } from '@mui/material';
import { FormControl, Button } from '@mui/material';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import Toast from 'components/shared/Toast/Toast';
import { useFetchPermissionTable, IFetchTenantPermission } from 'api/hooks/useFetchPermissions';
import { useSetRemovePermissions } from 'api/hooks/useSetRemovePermissions';

//https://mui.com/material-ui/react-table/

export interface IPermissionsTableProps {
  rows: IFetchTenantPermission[];
  setRows: React.Dispatch<React.SetStateAction<IFetchTenantPermission[]>>
}

export const PermissionsTable: React.FC<IPermissionsTableProps> = ({rows, setRows}) => {

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();

  const {
    isFetching : isPermissionsResponseFetching,
    data : fetchPermissionsTableResponse
  } = useFetchPermissionTable();

  useEffect(() => {
    if (fetchPermissionsTableResponse?.data?.response && initialLoad)
    {
      setRows(fetchPermissionsTableResponse.data.response);
      setInitialLoad(false);
    }
    
    setDeleteDisabled(selectedRows.length==0);
  });

  const handleSelectAllClick = (event: React.FormEvent<HTMLTableCellElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.id == 'select-all-check') {
      if (target.checked)
      {
        setSelectedRows(rows.map((row) => { return row.permissionId.toString(); }));
        setSelectAll(true);
      }
      else
      {
        setSelectedRows([]);
        setSelectAll(false);     
      }
    }
  };

  const onApplyChangesSuccess = () => {
    const newRows = rows.filter((element) => {
      return selectedRows.indexOf(element.permissionId.toString())===-1;
    });
    setRows(newRows);
    setSelectedRows([]);
    setToastOptions({
      message:
        'Permissions changes have been saved and applied successfully.',
      toastType: 'success',
    });
    setSelectAll(false);
  };
  const onApplyChangesFailure = () => {
    setToastOptions({
      message:
        'An unexpected error occured while saving permissions changes.',
      toastType: 'error',
    });
  };
  const {
    mutate: removePermissionsMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetRemovePermissions({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesFailure
  });
  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleDeleteClick = (event: React.FormEvent<HTMLButtonElement>) => {
    if (selectedRows.length>0) {
      //then something has been selected, so we should apply that change
      applyChanges();
    }
  };
  
  const applyChanges = useCallback(async () => {
    await removePermissionsMutation({
      permissionIds: selectedRows
    });
  }, [selectedRows]);

  const addSelectedRow = (id: string) => {
    if (selectedRows.indexOf(id)===-1) {
      setSelectedRows([...selectedRows, id]);
    }
  };
  const removeSelectedRow = (id: string) => {
    setSelectedRows(selectedRows.filter((element) => {
      return element!=id;
    }));
    if (selectAll) {
      setSelectAll(false);
    }
  };
  
  const handleSelectClick = (event: React.FormEvent<HTMLTableCellElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      addSelectedRow(target.id);
    }else{
      removeSelectedRow(target.id);
    }
  };


  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            onChange={handleSelectAllClick}
          >
            <Checkbox 
              id="select-all-check"
              checked={selectAll}
            />
          </TableCell>
          <TableCell>
            <TableSortLabel>Client</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>Group</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>Subgroup</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>User</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const isChecked = (id: number) => {
    return selectedRows.indexOf(id.toString())!==-1;
  };

  const TableContent = () => {
    return (
      <TableBody>
        { rows?.map((row) => {
          return (
            <TableRow 
              key={row.permissionId}
            >
              <TableCell
                padding="checkbox"
                onChange={handleSelectClick}
              >
                <Checkbox
                  id={row.permissionId.toString()}
                  checked={isChecked(row.permissionId)}
                />
              </TableCell>
              <TableCell>{row.clientId}</TableCell>
              <TableCell>
                <Tooltip title={row.groupName} arrow>
                  <div>{row.groupId}</div>
                </Tooltip>
              </TableCell>
              <TableCell>{row.subgroupId}</TableCell>
              <TableCell>{row.userLogin}</TableCell>
            </TableRow>
          );
          })}
      </TableBody>
    );
  };

  return (
    <div>
      <Toast {...toastOptions} open={isRequestFinished} />
      <TableContainer>
        <Table size="small">
          <TableHeader></TableHeader>
          <TableContent></TableContent>
        </Table>
      </TableContainer>
      <FormControl sx={{ m:1, minWidth: 120 }}>
        <Button
              disabled={deleteDisabled}
              variant="outlined"
              color="success"
              onClick={handleDeleteClick}
        >
              Remove selected
        </Button>
      </FormControl>
    </div>
  );
};

export default PermissionsTable;