import React from 'react';
import Box from '@mui/material/Box';
import Font from 'components/shared/Font';
import { BoxProps } from '@mui/system';
import styled from 'styled-components';

export const DashboardContainer = styled(Box)`
  width: 100%;
  max-width: 57.5rem;
`;

export const DashboardInnerContainer = styled.div`
  width: 100%;
`;

export const DashboardViewer = styled(
  (props: BoxProps) => (
    <DashboardContainer {...props}>
      <DashboardInnerContainer id='dashboard'></DashboardInnerContainer>
    </DashboardContainer>
  )
)`
  .boldbi-dashboarddesigner,
  .bbi-dashboarddesigner-surfaceContainer {
    height: 100vh;
  }
`;

export const DashboardTitle = styled(Font)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  max-width: 57.5rem;
`;

export const DashboardSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  max-width: 57.5rem;
`;
