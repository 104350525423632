import React, { useCallback, useMemo, useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import { BaseTextFieldProps } from '@mui/material/TextField';
import { IState, Value } from '../Form/Form';
import {
  FormInputPassword as Input,
  Container,
  InputLabel,
} from './FormInputPassword.styles';
import { detailedIsInputValid } from 'util/validation';
import { SxProps } from '@mui/material';

export const FORM_INPUT_PASSWORD_TEST_ID = 'FORM_INPUT_PASSWORD';

export interface IFormInputPasswordProps extends BaseTextFieldProps {
  id: string;
  name: string;
  prop: keyof IState;
  handleOnChange: (prop: keyof IState, value: string) => void;
  error?: boolean;
  required?: boolean;
  value?: Value;
  errorLabel?: string;
  inputSx?: SxProps;
  inputContainerSx?: SxProps;
  hasGlobalError?: {
    hasError?: boolean;
    failedValidationType?: 'required' | 'invalid' | null;
  };
}

export type FormInputPasswordType = React.ReactElement<
  typeof FormInputPassword
>;

export const FormInputPassword: React.FC<IFormInputPasswordProps> = ({
  prop,
  required,
  value,
  handleOnChange,
  errorLabel,
  inputSx,
  inputContainerSx,
  hasGlobalError,
  ...props
}: IFormInputPasswordProps) => {
  const [error, setError] = useState<{
    isValid: boolean;
    failedValidationType: 'required' | 'invalid' | null;
  }>({
    isValid: true,
    failedValidationType: null,
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const inputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleOnChange(prop, event.target.value);
      validateInput(event.target.value);
    },
    [prop],
  );

  const validateInput = (value?: Value) => {
    const validationsDetails = detailedIsInputValid(value, inputType, required);
    setError(validationsDetails);
  };

  const errorMessage = useMemo(() => {
    // If error is required then show required error message
    if (
      error?.failedValidationType === 'required' ||
      hasGlobalError?.failedValidationType === 'required'
    ) {
      return 'This field is required.';
    }

    // If error is invalid then show invalid error message or custom error message
    if (
      error?.failedValidationType === 'invalid' ||
      hasGlobalError?.failedValidationType === 'invalid'
    ) {
      return errorLabel || 'Invalid input.';
    }

    // default to custom error message (Should never happen, just a safety mechanism)
    return errorLabel;
  }, [error, errorLabel, hasGlobalError]);

  const hasError = !error.isValid || hasGlobalError?.hasError;

  return (
    <Container data-testid={FORM_INPUT_PASSWORD_TEST_ID} sx={inputContainerSx}>
      <InputLabel htmlFor={props.id} className={hasError ? 'Mui-error' : ''}>
        Password
      </InputLabel>
      <Input
        type={inputType}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              id={`${FORM_INPUT_PASSWORD_TEST_ID}_SHOW_PASSWORD`}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        onChange={handleChange}
        defaultValue={props.defaultValue}
        id={props.id}
        value={value}
        required={required}
        fullWidth={props.fullWidth}
        error={hasError}
        sx={inputSx}
      />
      {hasError && errorMessage && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </Container>
  );
};

export default FormInputPassword;
