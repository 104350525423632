import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';

interface IRemovePermissionsList {
  permissionIds: string[];
}

export function useSetRemovePermissions(
  mutationOptions?: UseMutationOptions<
    IResponse<IRemovePermissionsList>, //CHECK THIS
    IResponseError,
    IRemovePermissionsList>
)
{
  return useMutation<
    IResponse<IRemovePermissionsList>, //CHECK THIS
    IResponseError,
    IRemovePermissionsList>
    (
      [QueryKeys.SET_REMOVE_PERMISSIONS],
      (args) => {
        return api.post<IRemovePermissionsList>('/permission/removepermissions', args);
      },
      mutationOptions
    );
}