import React from 'react';
import OutlinedInput, {
  outlinedInputClasses,
  OutlinedInputProps,
} from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import MuiInputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import styled from 'styled-components';
import { primaryMain, textPrimaryRGB } from 'config/theme/colors';

export const Container = styled(FormControl)`
  display: inline-flex;

  & .${inputLabelClasses.root}:not(.${inputLabelClasses.shrink}) {
    transform: translate(0.875rem, 0.5rem) scale(1);
  }
`;

export const InputLabel = styled(MuiInputLabel)`
  &.${inputLabelClasses.root} {
    &.Mui-focused:not(.Mui-error) {
      color: ${primaryMain};
    }
  }
  & .Mui-focused:not(.Mui-error) {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${primaryMain};
    }
  }
`;

export const FormInputPassword = styled((props: OutlinedInputProps) => (
  <OutlinedInput size="small" {...props} />
))`
  & .${outlinedInputClasses.root} {
    font-family: Gotham;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.009rem;
    color: ${textPrimaryRGB};
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 8px 0px;
    align-items: center;
    align-self: stretch;
  }

  & .MuiInputBase-input {
    padding: 0.5rem 0.75rem;
  }

  &.Mui-focused:not(.Mui-error) {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${primaryMain};
    }
  }
`;
