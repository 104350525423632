import axios, { InternalAxiosRequestConfig } from 'axios';
import { IDeltaDentalAPIInstance, IResponseError } from './types';
import { getAuthTokenFromCookie, removeAuthTokenFromCookie } from 'util/auth';

export const AXIOS_TIMEOUT_ERROR_MESSAGE = 'ERR_TIMEOUT';

const defaultHeaders = {
  'Content-type': 'application/json',
};

const ServiceFactory = (baseURL = ''): IDeltaDentalAPIInstance => {
  if (baseURL === '' && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.log(
      'base URL for delta dental reporting service factory is empty!',
    );
  }
  const client = axios.create({
    baseURL,
    headers: defaultHeaders,
    withCredentials: false,
    timeoutErrorMessage: AXIOS_TIMEOUT_ERROR_MESSAGE,
    timeout: 60_000,
  });

  // Authorization header
  client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || {};

    if (getAuthTokenFromCookie()) {
      config.headers['Authorization'] = `Bearer ${getAuthTokenFromCookie()}`;
    }
    return config;
  });

  // Authorization error handling
  client.interceptors.response.use(
    (response) => response,
    (error: IResponseError) => {
      // If there is a 401
      if (error.response?.data?.status === 401) {
        // removing the cookie
        removeAuthTokenFromCookie();
        // reloading the page
        // just to be safe we use a requestAnimationFrame so that 100% the token is removed before the page is reloaded
        setTimeout(() => {
          requestAnimationFrame(() => {
            window.location.reload();
          });
        }, 100);
      }

      return Promise.reject(error);
    },
  );

  return client;
};

export default ServiceFactory(process.env.REACT_APP_API_BASE_URL || window.location.protocol+'//'+window.location.host+'/api');
