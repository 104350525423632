import React from 'react';
import MuiAlert from '@mui/material/Alert';
import styled from 'styled-components';

import { alertInfoBackground, alertInfoColor } from 'config/theme/colors';
import { BODY_MEDIUM } from 'config/theme/typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { IAlertCallToAction } from './Alert';

export interface IAlertCallToActionButtonProps extends ButtonProps {
  callToAction: IAlertCallToAction;
}

export const Alert = styled(
  MuiAlert
)`
  & .MuiAlert-standardInfo {
    background-color: ${alertInfoBackground};
    color: ${alertInfoColor};
  }
  & .MuiTypography-root {
    ${BODY_MEDIUM};
    line-height: 143%;
    letter-spacing: 0.15px;
  }
  & .MuiAlert-icon {
    margin-right: 0.875rem;
  }
`;

export const AlertCallToActionButton = styled(
  ({ callToAction, ...props }: IAlertCallToActionButtonProps) => {
    if (!callToAction || !callToAction?.text) return null;

    return <Button {...props} onClick={callToAction.onClick} variant='text'>{callToAction.text}</Button>;
  }
)`
  background-color: none;
  border: none;
  box-shadow: none;
  padding: 0.25rem 0.313rem;
  margin: 0rem 1rem 0rem 0rem;
`;