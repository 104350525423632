import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IToastProps } from './Toast.types';
import { Alert, Container } from './Toast.styles';

const AUTO_HIDE_DURATION = 5_000;

export const Toast: React.FC<IToastProps> = ({ message, toastType, open: isOpen }) => {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(
    () => {
      if (isOpen && isOpen !== open) {
        setOpen(isOpen);
        setTimeout(
          () => setOpen(false),
          AUTO_HIDE_DURATION,
        );
      }
    },
    [isOpen],
  );

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity={toastType} icon={false} action={action}>{message}</Alert>
    </Container>
  );
};

export default Toast;
