import React, { SVGProps } from 'react';

export const DeltaDentalLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="180"
    height="22"
    viewBox="0 0 180 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="DD-Logo">
      <path
        id="Vector"
        d="M37.1699 16.475C37.1699 14.555 37.1699 12.605 37.1699 10.715C41.9999 10.295 41.9699 16.925 37.1699 16.475ZM44.2199 8.28504C42.4199 6.51504 39.9899 6.03504 36.8999 5.91504C36.8999 5.91504 32.7299 6.00504 31.6799 6.00504C31.6799 11.015 31.6799 16.025 31.6799 21.035C35.0399 21.035 39.6599 21.335 41.9999 20.375C46.3499 18.545 47.8799 11.885 44.2199 8.28504Z"
        fill="#41A928"
      />
      <path
        id="Vector_2"
        d="M123.96 6.18506C125.7 6.18506 127.56 6.18506 129.33 6.18506C131.97 11.2851 133.89 13.8351 133.89 13.8351C133.89 11.2851 133.89 8.73506 133.89 6.18506C135.63 6.18506 137.46 6.21506 139.2 6.21506C139.2 9.93506 139.17 15.3351 139.17 21.0951C137.31 21.0951 135.48 21.0951 133.62 21.0951C132.72 19.5651 130.95 16.4751 129.3 13.9851C129.3 15.2151 129.33 19.2951 129.33 21.0951C127.53 21.0951 125.76 21.0951 123.96 21.0951C123.9 19.0551 124.02 9.90506 123.96 6.18506Z"
        fill="#41A928"
      />
      <path
        id="Vector_3"
        d="M12.18 6.30503L6.63 16.025H17.91L12.27 6.09503L12.18 6.30503ZM15.24 0.905029L24.15 16.025L20.91 21.005H2.88L0 16.175L6.42 5.97503H11.97H12.24L9.51 0.905029H15.24Z"
        fill="#41A928"
      />
      <path
        id="Vector_4"
        d="M86.52 6.005H81.42L75.99 21.005H81.21L83.97 12.065L86.73 21.005H91.95L86.52 6.005Z"
        fill="#41A928"
      />
      <path
        id="Vector_5"
        d="M160.08 6.005H154.95L149.52 21.005H154.77L157.53 12.065L160.26 21.005H165.51L160.08 6.005Z"
        fill="#41A928"
      />
      <path
        id="Vector_6"
        d="M57.2701 21.005H47.1301V6.005H57.3001V10.025H52.5601V11.495H56.7601V15.425H52.5901V16.925H57.2701V21.005Z"
        fill="#41A928"
      />
      <path
        id="Vector_7"
        d="M102.54 16.475C102.54 14.555 102.54 12.605 102.54 10.715C107.4 10.295 107.34 16.925 102.54 16.475ZM109.59 8.28504C107.79 6.51504 105.36 6.03504 102.27 5.91504C102.27 5.91504 98.0998 6.00504 97.0498 6.00504C97.0498 11.015 97.0498 16.025 97.0498 21.035C100.41 21.035 105.06 21.335 107.37 20.375C111.72 18.545 113.25 11.885 109.59 8.28504Z"
        fill="#41A928"
      />
      <path
        id="Vector_8"
        d="M122.67 21.005H112.5V6.005H122.67V10.025H117.93V11.495H122.13V15.425H117.96V16.925H122.67V21.005Z"
        fill="#41A928"
      />
      <path
        id="Vector_9"
        d="M58.5601 21.005V6.005H63.9601V16.145H68.5501V21.005H58.5601Z"
        fill="#41A928"
      />
      <path
        id="Vector_10"
        d="M166.23 21.005V6.005H171.63V16.145H176.22V21.005H166.23Z"
        fill="#41A928"
      />
      <path
        id="Vector_11"
        d="M66.8701 10.835V6.005H78.3001V10.835H75.3901V21.035H69.7501V10.835H66.8701Z"
        fill="#41A928"
      />
      <path
        id="Vector_12"
        d="M140.34 10.835V6.005H151.77V10.835H148.83V21.035H143.19V10.835H140.34Z"
        fill="#41A928"
      />
      <path
        id="Vector_13"
        d="M177.87 6.87495C178.14 6.87495 178.62 6.93495 178.62 6.57495C178.62 6.33495 178.32 6.30495 178.08 6.30495H177.48V6.87495H177.87ZM178.92 8.22495H178.53L177.87 7.20495H177.48V8.22495H177.18V6.00495H178.14C178.53 6.00495 178.92 6.12495 178.92 6.60495C178.92 7.05495 178.62 7.20495 178.23 7.20495L178.92 8.22495ZM179.67 7.11496C179.67 6.15496 178.89 5.40495 177.93 5.40495C177 5.40495 176.19 6.15496 176.19 7.11496C176.19 8.07496 177 8.82495 177.93 8.82495C178.89 8.82495 179.67 8.07496 179.67 7.11496ZM175.92 7.11496C175.92 5.97496 176.82 5.07495 177.96 5.07495C179.1 5.07495 180 5.97496 180 7.11496C180 8.25496 179.1 9.15495 177.96 9.15495C176.82 9.15495 175.92 8.25496 175.92 7.11496Z"
        fill="#41A928"
      />
    </g>
  </svg>
);

export default DeltaDentalLogo;
