import React from 'react';
import MuiTextField, {
  textFieldClasses,
  TextFieldProps,
} from '@mui/material/TextField';
import styled from 'styled-components';
import { primaryMain, textPrimaryRGB } from 'config/theme/colors';

export const FormInput = styled(
  ({
    width: _width,
    ...rest
  }: TextFieldProps & {
    isFlex?: boolean;
    width?: string;
  }) => <MuiTextField size="small" {...rest} />,
)`
  display: inline-flex;
  width: ${(props) => (props.width ? props.width : '48.55%')};
  max-width: ${(props) => (props.width ? props.width : '25rem')};

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    max-width: ${(props) => (props.width ? props.width : '17.875rem')};
  }

  & .MuiInputLabel-root {
    &.Mui-focused:not(.Mui-error) {
      color: ${primaryMain};
    }
  }

  & .${textFieldClasses.root} {
    font-family: Gotham;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.009rem;
    color: ${textPrimaryRGB};
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 8px 0px;
    align-items: center;
    align-self: stretch;
  }

  & .MuiInputBase-input {
    padding: 0.5rem 0.75rem;
  }

  & .Mui-focused:not(.Mui-error) {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${primaryMain};
    }
  }
`;
