import cookie from 'js-cookie';

export const getAuthTokenFromCookie = () => {
  return cookie.get('Authorization');
};

export const setAuthTokenFromCookie = (value: string) => {
  return cookie.set('Authorization', value);
};

export const removeAuthTokenFromCookie = () => {
  return cookie.remove('Authorization');
};
