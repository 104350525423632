/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  QueryCache,
  QueryClient,
  QueryClientConfig,
} from '@tanstack/react-query';

function createQueryCacheOptions(): QueryCache['config'] {
  return {
    onError: (_error, _query) => {
      return false;
    },
    onSuccess: (_data, _query) => {
      return true;
    },
  };
}

const defaultQueryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const status = (error as Response)?.status;
        return failureCount < 2 && (!status || status < 400 || status >= 500);
      },
      staleTime: 2 * 60 * 1000, //time in milliseconds after which the returned data from a successful query is considered stale
      cacheTime: 2 * 60 * 1000, //time in milliseconds that an unused/inactive cached data is allowed to remain in memory
    },
  },
};

const testQueryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      ...defaultQueryClientOptions.defaultOptions?.queries,
      retry: false,
      staleTime: 0,
      cacheTime: Infinity,
    },
  },
  logger: {
    // eslint-disable-next-line no-console
    log: console.log.bind(console),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    error() {},
    // eslint-disable-next-line no-console
    warn: console.warn.bind(console),
  },
};

export function createDefaultQueryClient() {
  return new QueryClient({
    ...defaultQueryClientOptions,
    queryCache: new QueryCache(createQueryCacheOptions()),
  });
}

export function createTestQueryClient() {
  return new QueryClient({
    ...testQueryClientOptions,
    queryCache: new QueryCache(createQueryCacheOptions()),
  });
}
