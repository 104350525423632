import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosProgressEvent } from 'axios';
import { QueryKeys } from 'constants/query-keys';

export const UPLOAD_FILE_API_URL =
  process.env.REACT_APP_UPLOAD_FILE_API_URL ||
  'https://api.bytescale.com/v2/accounts/W142iEs/uploads/form_data';
const TOKEN = 'public_W142iEsGEZGW1reiQhjC3h6GFEAH';

export interface IUseFileUploadProps {
  permittedFileTypes: string[];
  maxFileSize: number;
  token?: string;
  url?: string;
}

export interface IUseFileUploadState {
  isMutating: boolean,
  isError: boolean,
  isSuccess: boolean,
  uploadFile: UseMutateFunction<any, Error, File, unknown>,
  uploadProgress: number,
  error?: string,
}

export const useFileUpload = ({
  url,
  token,
  permittedFileTypes,
  maxFileSize,
}: IUseFileUploadProps): IUseFileUploadState => {
  const queryClient = useQueryClient();

  const uploadFile = async (file: File) => {
    const formData = new FormData();

    const fileType = file?.type;
    const isFileNotPermitted = !(
      permittedFileTypes.includes(fileType) ||
      permittedFileTypes.includes(fileType.split('/')[1])
    );

    if (isFileNotPermitted) {
      throw new Error('File type not accepted.');
    }

    if (file?.size > maxFileSize) {
      throw new Error('File exceeds max size.');
    }

    formData.append('file', file);

    const response = await axios.post(url || UPLOAD_FILE_API_URL, formData, {
      headers: {
        Authorization: `Bearer ${token || TOKEN}`,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (
          !progressEvent ||
          progressEvent === undefined ||
          !progressEvent?.total
        )
          return;

        const progress = (progressEvent.loaded / progressEvent?.total) * 100;
        queryClient.setQueryData([QueryKeys.FILE_UPLOAD], progress);
      },
    });

    return response.data;
  };

  const uploadFileMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.FILE_UPLOAD]);
    },
    onError: (error: Error) => {
      throw error;
    },
  });

  return {
    isMutating: uploadFileMutation.isLoading,
    isError: uploadFileMutation.isError,
    isSuccess: uploadFileMutation.isSuccess,
    uploadFile: uploadFileMutation.mutate,
    uploadProgress:
      queryClient.getQueryData<number>([QueryKeys.FILE_UPLOAD]) || 0,
    error: uploadFileMutation.error?.message,
  };
};

export default useFileUpload;
