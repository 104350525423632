import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IPublishedDashboards } from 'types/publishedDashboards';

type ISetPublishedDashboards = IPublishedDashboards;

interface ISetPublishedDashboardsVariables {
  tenantCode: string;
  dashboards: {
    id: string;
    isPublished: boolean;
  }[];
}

export function useSetPublishedDashboards(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetPublishedDashboards>,
    IResponseError,
    ISetPublishedDashboardsVariables
  >,
) {
  return useMutation<
    IResponse<ISetPublishedDashboards>,
    IResponseError,
    ISetPublishedDashboardsVariables
  >(
    [QueryKeys.SET_PUBLISHED_DASHBOARDS],
    (args) => {
      return api.post<ISetPublishedDashboards>(
        '/dashboards/setpublisheddashboards',
        args,
      );
    },
    mutationOptions,
  );
}
