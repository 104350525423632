import React, { SyntheticEvent, useEffect } from 'react';
import { useFormContext } from 'context/formContext';
import { useGetFormChildren } from 'util/hooks/useGetFormChildren';
import ActionBar from '../ActionBar/ActionBar';
import { FormInputType, InputType } from '../FormInput/FormInput';
import { IFormSelectOption } from '../FormSelect/FormSelect';
import { FormSwitchType } from '../FormSwitch/FormSwitch';
import { Container, Fieldset } from './Form.styles';

export type Value = string | number | boolean | null | File;

export const FORM_KEY = 'FORM';

export interface IState {
  [key: string]: {
    value?: Value;
    defaultValue?: Value;
    legend?: string;
    options?: IFormSelectOption[];
    required?: boolean;
    disabled?: boolean;
    type?: InputType;
  };
}

export type FormChildren =
  | FormSwitchType
  | FormSwitchType[]
  | FormInputType
  | FormInputType[]
  | React.ReactNode;

export interface IFormProps {
  children: FormChildren | [FormChildren];
  state: IState;
  handleCancel?: () => void;
  handleSubmit?: () => void;
  disableInputs?: boolean;
  triggerCancelAction?: boolean;
  handleShowApproveEditDialog?: () => void;
  className?: 'switches' | undefined;
  isFlex?: boolean;
  isFlexColumn?: boolean;
  lockUnlockText?: {
    disabled: string;
    enabled: string;
  };
  isLockAble?: boolean;
  noMarginsOnFormControls?: boolean;
  onLoadForm?: (ref: IForwardedProps) => void;
}

export interface IForwardedProps {
  validateAllInputs: () => boolean;
  validateInputByName: (name: string) => boolean;
}

export const Form: React.FC<IFormProps> = ({
  handleCancel,
  handleSubmit,
  handleShowApproveEditDialog,
  children,
  className,
  triggerCancelAction,
  isFlex,
  isFlexColumn,
  lockUnlockText,
  isLockAble = true,
  noMarginsOnFormControls = false,
  state,
  onLoadForm,
}: IFormProps) => {
  const formContext = useFormContext();
  const { isFormEnabled, setFormIsSubmitted } = formContext;

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setFormIsSubmitted(true);
    handleSubmit?.();
  };

  const {
    formChildren,
    isChanged: enableSave,
    validateAllInputs,
    validateInputByName,
  } = useGetFormChildren({
    children,
    formContext,
    formData: state,
  });

  useEffect(() => {
    onLoadForm?.({
      validateAllInputs,
      validateInputByName,
    });

    // Only when state changes
    // Otherwise we'll get an infinite loop
  }, [state]);

  return (
    <Container
      className={className}
      onSubmit={onSubmit}
      noMarginsOnFormControls={noMarginsOnFormControls}
    >
      <Fieldset
        disabled={!isFormEnabled}
        isFlex={isFlex}
        isFlexColumn={isFlexColumn}
      >
        {formChildren}
      </Fieldset>
      {isLockAble && (
        <ActionBar
          enableSave={enableSave}
          onCancel={handleCancel}
          onEditApproval={handleShowApproveEditDialog}
          triggerCancelAction={triggerCancelAction}
          lockUnlockText={lockUnlockText}
        />
      )}
    </Container>
  );
};

export default Form;
