import React from 'react';
import * as ReactDOM from 'react-dom/client';
import reactToWebComponent from 'react-to-webcomponent';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from 'App';

const DeltaDentalApp = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

const DeltaDentalWebComponent = reactToWebComponent(
  DeltaDentalApp as any,
  React as any,
  ReactDOM as any,
);

customElements.define('delta-dental', DeltaDentalWebComponent as any);
