import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import { TenantsTableWrapper } from './TenantsView.styles';
import PageContent from 'components/shared/PageContent';
import { ITenant } from 'types/tenant';
import { useFetchTenants } from 'api/hooks/useFetchTenants';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';

export interface ITenantsViewProps {
  className?: string;
}

export const TenantsView: React.FC<
  ITenantsViewProps
> = ({}: ITenantsViewProps) => {
  const navigate = useNavigate();

  const {
    data: fetchTenantsResponse,
    isFetching: isTenantsFetching,
    isFetched: isTenantsFetched,
  } = useFetchTenants();

  const tenants: ITenant[] = fetchTenantsResponse?.data?.response || [];

  const onTenantClick = (tenant: ITenant) => {
    navigate(`/tenants/${tenant.tenantCode}`);
  };

  return (
    <PageContent>
      <TenantsTableWrapper>
        <ComponentSpinner active={isTenantsFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenants?.map((tenant) => (
                <TableRow
                  key={tenant.tenantId}
                  onClick={() => onTenantClick(tenant)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <TableCell>{tenant.tenantName}</TableCell>
                  <TableCell>{tenant.tenantCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isTenantsFetched && tenants?.length === 0 && (
            <Typography
              sx={{
                textAlign: 'center',
                width: '100%',
                my: 3,
              }}
            >
              No tenants found
            </Typography>
          )}
        </ComponentSpinner>
      </TenantsTableWrapper>
    </PageContent>
  );
};

export default TenantsView;
