import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';

export interface IPageContentProps {
  children: ReactElement[] | ReactElement;
  fullWidth?: boolean;
}

export const PageContent: React.FC<IPageContentProps> = ({
  children,
  fullWidth = false,
}: IPageContentProps) => {
  return (
    <Container>
      <Box
        sx={{
          width: fullWidth ? '100%' : '920px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 7rem;
  padding: 0rem 3rem;
`;

export default PageContent;
