export interface IDataSource {
  itemCreatedDate: string;
  itemModifiedDate: string;
  providerType: string;
}

export enum DataSourceType {
  SNOWFLAKE = 'snowflake',
  REDSHIFT = 'redshift',
  AMAZONRDSMYSQL = 'amazonrdsmysql',
  AMAZONRDSPOSTGREQL = 'amazonrdspostgresql',
  AMAZONRDSSQLSERVER = 'amazonrdssqlserver',
  AMAZONRDSMARIADB = 'amazonrdsmariadb',
  AMAZONRDSAURORAMYSQL = 'amazonrdsauroramysql',
  AMAZONRDSAURORAPGSQL = 'amazonrdsaurorapostgresql',
}
