import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form, { IState } from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { FormInput } from 'components/shared/FormInput/FormInput';
import { useFormContext } from 'context/formContext';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { useFetchJWTConfiguration } from 'api/hooks/useFetchJWTConfiguration';
import { useSetJWTConfiguration } from 'api/hooks/useSetJWTConfiguration';
import { useAuth } from 'context/Auth';

const FORM_DATA_DEFAULTS = {
  signingKey: {
    legend: 'Secret for Signature',
  },
  encryptionKey: {
    legend: 'Secret for Encryption',
  },
  corsDomain: {
    legend: 'CORS',
  },
};

const FORM_KEY = 'form-contact';

interface IExternalTabProps {
  isTenantScoped?: boolean;
}

export const ExternalTab: React.FC<IExternalTabProps> = ({
  isTenantScoped = false,
}: IExternalTabProps) => {
  const DISCARD_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Discard changes?',
    content: 'Are you sure you want to stop editing without saving changes?',
    acceptButtonText: 'Discard changes',
    cancelButtonText: 'Cancel',
  };

  const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Apply changes?',
    content: `Remember that you are changing sensitive details that will affect login into ${ isTenantScoped ? 'the' : 'your'} Tenant’s instance. <br /><br />Are you sure you want to proceed?`,
    acceptButtonText: 'Apply Changes',
    cancelButtonText: 'Cancel',
  };

  const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Edit Single Sign-On details?',
    content:
      `Remember that you are changing sensitive details that will affect login into ${ isTenantScoped ? 'the' : 'your'} Tenant’s instance.<br /><br /> Are you sure you want to proceed?`,
    acceptButtonText: 'Start Editing',
    cancelButtonText: 'Cancel',
  };

  const DEFAULT_DIALOG_PROPS = {
    discard: DISCARD_DIALOG_PROPS,
    applyChanges: APPLY_CHANGES_DIALOG_PROPS,
    editApproval: EDIT_APPROVAL_DIALOG_PROPS,
  };

  const queryParams = useParams<{ tenantCode: string }>();
  const { user } = useAuth();
  const { setIsFormEnabled, setFormIsSubmitted, resetForm } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );
  const tenantCode = isTenantScoped
    ? queryParams?.tenantCode
    : user?.tenantCode;

  const {
    data: fetchedJWTConfigurationResponse,
    isFetching: isJWTConfigurationFetching,
  } = useFetchJWTConfiguration(
    {
      tenantCode: tenantCode,
    },
    {
      // Never cache this request
      cacheTime: 0,
    },
  );

  useEffect(() => {
    // Only initialize the form data if the data null
    if (fetchedJWTConfigurationResponse?.data && !data) {
      const { corsDomain, encryptionKey, signingKey } =
        fetchedJWTConfigurationResponse?.data?.response || {};

      const defaultValue = {
        encryptionKey: {
          ...FORM_DATA_DEFAULTS.encryptionKey,
          value: encryptionKey,
          defaultValue: encryptionKey,
        },
        signingKey: {
          ...FORM_DATA_DEFAULTS.signingKey,
          value: signingKey,
          defaultValue: signingKey,
        },
        corsDomain: {
          ...FORM_DATA_DEFAULTS.corsDomain,
          value: corsDomain,
          defaultValue: corsDomain,
        },
      };

      setData(defaultValue);
      setInitialData(defaultValue);
    }
  }, [fetchedJWTConfigurationResponse]);

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Single Sign-On have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    setFormIsSubmitted(true);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: setJWTConfigurationMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetJWTConfiguration({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleOnChange = useCallback((prop: keyof IState, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...(prevData as IState)[prop],
        value,
      },
    }));
  }, []);

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = async () => {
    await setJWTConfigurationMutation({
      encryptionKey: data?.encryptionKey.value as string,
      signingKey: data?.signingKey.value as string,
      corsDomain: data?.corsDomain.value as string,
      tenantCode: tenantCode as string,
    });
  };

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;
  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        isLoading={isMutating}
        instantlyCloseOnAccept={false}
      />
      <ComponentSpinner active={isJWTConfigurationFetching}>
        {data && (
          <Form
            state={data}
            handleCancel={handleOnCancelEditMode}
            handleSubmit={handleSubmitChanges}
            key={formKey}
            isFlex
            handleShowApproveEditDialog={handleShowApproveEditDialog}
          >
            <FormInput
              required
              type="text"
              id="signingKey"
              name="signingKey"
              prop={'signingKey'}
              label={FORM_DATA_DEFAULTS.signingKey.legend}
              handleOnChange={handleOnChange}
              defaultValue={data['signingKey'].defaultValue}
              width="50%"
              sx={{
                marginRight: '0 !important',
                pr: '12px',
              }}
            />
            <FormInput
              required
              type="text"
              id="encryptionKey"
              name="encryptionKey"
              prop={'encryptionKey'}
              label={FORM_DATA_DEFAULTS.encryptionKey.legend}
              handleOnChange={handleOnChange}
              defaultValue={data['encryptionKey'].defaultValue}
              width="50%"
              sx={{
                marginRight: '0 !important',
                pl: '12px',
              }}
              InputLabelProps={{
                sx: {
                  left: '12px',
                },
              }}
            />
            <FormInput
              required
              type="text"
              id="corsDomain"
              name="corsDomain"
              prop={'corsDomain'}
              label={FORM_DATA_DEFAULTS.corsDomain.legend}
              handleOnChange={handleOnChange}
              defaultValue={data['corsDomain'].defaultValue}
              width="50%"
              sx={{
                marginRight: '0 !important',
                pr: '12px',
              }}
            />
          </Form>
        )}
      </ComponentSpinner>
    </>
  );
};

export default ExternalTab;
