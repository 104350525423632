import FormControl from '@mui/material/FormControl';
import MuiInputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect, { selectClasses } from '@mui/material/Select';
import { primaryMain, textPrimaryRGB } from 'config/theme/colors';
import styled from 'styled-components';

export const Container = styled(FormControl)`
  display: inline-flex;
  width: 48.55%;
  max-width: 25rem;
  gap: 1.5rem;

  &.MuiFormControl-fullWidth {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    &:not(.MuiFormControl-fullWidth) {
      max-width: 17.875rem;
    }
  }
`;

export const InputLabel = styled(MuiInputLabel)`
  &.${inputLabelClasses.root} {
    &.Mui-focused:not(.Mui-error) {
      color: ${primaryMain};
    }
  }
  & .Mui-focused:not(.Mui-error) {
    & .MuiOutlinedInput-notchedOutline {
    border-color: ${primaryMain};
  }
`;

export const Select = styled(MuiSelect)`
  & .${selectClasses.select} {
    font-family: Gotham;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.009rem;
    color: ${textPrimaryRGB};
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 8px 0px;
    align-items: center;
    align-self: stretch;
  }

  & .MuiInputBase-input {
    padding: 0.5rem 0.75rem;
  }

  &.Mui-focused:not(.Mui-error) {
    & .MuiOutlinedInput-notchedOutline {
    border-color: ${primaryMain};
  }
`;

export const MenuItem = styled(MuiMenuItem)``;
