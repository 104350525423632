import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form, { IState } from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { FormInput } from 'components/shared/FormInput/FormInput';
import { useFormContext } from 'context/formContext';
import { useFetchContact } from 'api/hooks/useFetchContact';
import { useSetContact } from 'api/hooks/useSetContact';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';

const FORM_DATA_DEFAULTS = {
  name: {
    legend: 'Name',
  },
  email: {
    legend: 'Email',
  },
};

const DISCARD_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Discard changes?',
  content: 'Are you sure you want to stop editing without saving changes?',
  acceptButtonText: 'Discard changes',
  cancelButtonText: 'Cancel',
};

const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Apply changes?',
  content: 'Are you sure you want to save the changes made to Contact details?',
  acceptButtonText: 'Apply Changes',
  cancelButtonText: 'Cancel',
};

const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Edit Contact details?',
  content:
    'Remember that any changes made here will affect company’s Contact details.<br /><br /> Are you sure you want to proceed?',
  acceptButtonText: 'Start Editing',
  cancelButtonText: 'Cancel',
};

const DEFAULT_DIALOG_PROPS = {
  discard: DISCARD_DIALOG_PROPS,
  applyChanges: APPLY_CHANGES_DIALOG_PROPS,
  editApproval: EDIT_APPROVAL_DIALOG_PROPS,
};

const FORM_KEY = 'form-contact';

export const ContactTab = () => {
  const { setIsFormEnabled, setFormIsSubmitted, resetForm } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );

  const { data: fetchedContactResponse, isFetching: isContactFetching } =
    useFetchContact();

  useEffect(() => {
    // Only initialize the form data if the data null
    if (fetchedContactResponse?.data && !data) {
      const { name, email } = fetchedContactResponse?.data?.response || {};

      const defaultValue = {
        name: {
          ...FORM_DATA_DEFAULTS.name,
          value: name,
          defaultValue: name,
        },
        email: {
          ...FORM_DATA_DEFAULTS.email,
          value: email,
          defaultValue: email,
        },
      };

      setData(defaultValue);
      setInitialData(defaultValue);
    }
  }, [fetchedContactResponse]);

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Contact Person have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    setFormIsSubmitted(true);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: setContactMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetContact({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleOnChange = useCallback((prop: keyof IState, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...(prevData as IState)[prop],
        value,
      },
    }));
  }, []);

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = async () => {
    await setContactMutation({
      email: data?.email?.value as string,
      name: data?.name?.value as string,
    });
  };

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;

  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        isLoading={isMutating}
        instantlyCloseOnAccept={false}
      />
      <ComponentSpinner active={isContactFetching}>
        {data && (
          <Form
            state={data}
            handleCancel={handleOnCancelEditMode}
            handleSubmit={handleSubmitChanges}
            key={formKey}
            handleShowApproveEditDialog={handleShowApproveEditDialog}
          >
            <FormInput
              required
              type="text"
              id="name"
              name="name"
              prop={'name'}
              label="Name"
              handleOnChange={handleOnChange}
              sx={{
                marginRight: '1.5rem',
              }}
              defaultValue={data['name'].defaultValue}
            />
            <FormInput
              required
              type="email"
              id="email"
              name="email"
              prop={'email'}
              label="Email"
              handleOnChange={handleOnChange}
              errorLabel="Please enter a valid email address"
              sx={{
                marginRight: '0px !important',
              }}
              defaultValue={data['email'].defaultValue}
            />
          </Form>
        )}
      </ComponentSpinner>
    </>
  );
};

export default ContactTab;
