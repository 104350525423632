import { HeaderStatus } from 'types/header';

export const formatFileSizeToKB = (sizeInBytes: number): string => {
  const sizeInKB = sizeInBytes / 1024;
  return `${sizeInKB.toFixed(2)}KB`;
};


export const formatStatusToText = (status: HeaderStatus): string | null => {
  const statusText = {
    'pending': 'Pending Setup',
    'in-review': 'In Review',
    'published': 'Published',
  };

  return statusText[status] || null;
};

export const formatBytesToSize = (bytes: number) => {
  if (!Number(bytes)) {
    return '0 Bytes';
  }

  const sizes = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];

  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
