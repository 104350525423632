import React, { useMemo } from 'react';
import TabsPanel, { TabContent } from 'components/shared/Tabs/TabsPanel';
import { TabContentFn } from './config';
import { Box } from '@mui/material';

export interface ISSOSettingsProps {
  className?: string;
  isTenantScoped: boolean;
}

export const SSO_SETTINGS_KEY = 'SSO_Settings';

export const SSOSettings: React.FC<ISSOSettingsProps> = ({
  isTenantScoped = false,
}: ISSOSettingsProps) => {
  const TAB_CONTENT = useMemo(
    () => TabContentFn({ isTenantScoped }),
    [isTenantScoped],
  );
  return (
    <Box
      sx={{
        mt: -5,
      }}
    >
      <TabsPanel name="SSO_Settings" isTabsInTabs>
        <TabContent {...TAB_CONTENT.Internal} />
        <TabContent {...TAB_CONTENT.External} />
      </TabsPanel>
    </Box>
  );
};

export default SSOSettings;
