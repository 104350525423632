import React from 'react';
import { BoldBI } from '@boldbi/boldbi-embedded-sdk';
import { IBasicComponentProps } from 'globals';
import PageContent from 'components/shared/PageContent';
import {
  DashboardViewer,
  DashboardTitle,
  DashboardSubTitle,
} from './DashboardView.styles';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { useFetchDefaultDashboard } from 'api/hooks/useFetchDefaultDashboard';
import { getAuthTokenFromCookie } from 'util/auth';

type Props = IBasicComponentProps & {
  offsetTop?: number;
};

const DashboardsView = ({}: Props) => {
  const getEnvironment = (environment: string) => {
    if (environment === 'enterprise') {
      return BoldBI.Environment.Enterprise;
    }

    return environment;
  };

  const renderDashboard = ({
    dashboardId,
    rootUrl,
    environment,
    siteIdentifier,
  }: {
    dashboardId: string;
    rootUrl: string;
    environment: string;
    siteIdentifier: string;
  }) => {
    const dashboard = BoldBI.create({
      serverUrl: `${rootUrl}/${siteIdentifier}`,
      dashboardId: dashboardId,
      embedContainerId: 'dashboard',
      embedType: BoldBI.EmbedType.Component,
      environment: getEnvironment(environment),
      width: '100%',
      height: '100%',
      expirationTime: 100000,
      authorizationServer: {
        url: `${
          process.env.REACT_APP_API_BASE_URL ||
          window.location.protocol + '//' + window.location.host + '/api'
        }/embed/config`,
        headers: {
          'Authorization' : `Bearer ${getAuthTokenFromCookie()}` 
      }
      },
      autoRefreshSettings: {
        enabled: false,
      },
      actionBegin: 'emdbedDashboardActionBegin',
      actionComplete: 'emdbedDashboardActionComplete',
    });

    dashboard.loadDashboard();
  };

  const { isFetching, data: fetchedResponse } = useFetchDefaultDashboard({
    onSuccess: (rawResponse) => {
      if (rawResponse?.data?.response?.dashboardId) {
        renderDashboard({
          dashboardId: rawResponse.data.response.dashboardId as string,
          rootUrl: rawResponse.data.response.rootUrl as string,
          siteIdentifier: rawResponse.data.response.siteIdentifier as string,
          environment: rawResponse.data.response.environment as string,
        });
      }
    },
  });

  return (
    <PageContent>
      <DashboardTitle variant="h4">Reports</DashboardTitle>
      <DashboardSubTitle>
        <ComponentSpinner active={isFetching}>
          <div
            key={fetchedResponse?.data?.response?.dashboardId}
            className={`dashboard-item selected`}
            data-name={fetchedResponse?.data?.response?.name}
            data-itemid={fetchedResponse?.data?.response?.dashboardId}
            data-version={fetchedResponse?.data?.response?.version}
            data-ispublic={fetchedResponse?.data?.response?.isPublic}
          >
          </div>
        </ComponentSpinner>
      </DashboardSubTitle>
      <DashboardViewer id="viewer-section" />
    </PageContent>
  );
};

export default DashboardsView;
