export const isAtBottomOfScreen = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight
  );

  return (scrollTop + windowHeight) >= documentHeight;
};

export const isComponentHeigthBiggerThanBottomOfPage = (element: HTMLDivElement | null, reachedBottom?: boolean) => {
  if (reachedBottom) return false;

  let isAtBottom = false;

  if (element) {
    const elementRect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const elemHeight = elementRect.y + elementRect.height;
    isAtBottom = elemHeight > windowHeight;
  }

  return isAtBottom;
};
