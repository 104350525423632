import React, { useCallback, useMemo, useState } from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField';

import { IState, Value } from '../Form/Form';
import { FormInput as Input } from './FormInput.styles';
import { detailedIsInputValid } from 'util/validation';

export const FORM_INPUT_TESTID = 'FORM-INPUT';

export type FormInputType = React.ReactElement<typeof FormInput>;

export type InputType =
  | 'email'
  | 'text'
  | 'phone'
  | 'number'
  | 'password'
  | 'file';

export type InputTypeRegExpMap = {
  [key in InputType]: RegExp;
};

export interface IFormInputProps extends BaseTextFieldProps {
  label: string;
  id: string;
  name: string;
  type: InputType;
  prop: keyof IState;
  handleOnChange: (prop: keyof IState, value: string) => void;
  autoFocus?: boolean;
  autoComplete?: string;
  required?: boolean;
  errorLabel?: string;
  width?: string;
  hasGlobalError?: {
    hasError?: boolean;
    failedValidationType?: 'required' | 'invalid' | null;
  };
}

export const FormInput: React.FC<IFormInputProps> = ({
  prop,
  handleOnChange,
  required,
  type: inputType,
  errorLabel,
  width,
  hasGlobalError,
  ...props
}: IFormInputProps) => {
  const [error, setError] = useState<{
    isValid: boolean;
    failedValidationType: 'required' | 'invalid' | null;
  }>({
    isValid: true,
    failedValidationType: null,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleOnChange(prop, event.target.value);
      validateInput(event.target.value);
    },
    [prop],
  );

  const validateInput = (value?: Value) => {
    const validationsDetails = detailedIsInputValid(value, inputType, required);
    setError(validationsDetails);
  };

  const errorMessage = useMemo(() => {
    // If error is required then show required error message
    if (
      error?.failedValidationType === 'required' ||
      hasGlobalError?.failedValidationType === 'required'
    ) {
      return 'This field is required.';
    }

    // If error is invalid then show invalid error message or custom error message
    if (
      error?.failedValidationType === 'invalid' ||
      hasGlobalError?.failedValidationType === 'invalid'
    ) {
      return errorLabel || 'Invalid input.';
    }

    // default to custom error message (Should never happen, just a safety mechanism)
    return errorLabel;
  }, [error, errorLabel, hasGlobalError]);

  const hasError = !error.isValid || hasGlobalError?.hasError;

  return (
    <Input
      {...props}
      required={required}
      onChange={handleChange}
      data-testid={FORM_INPUT_TESTID}
      error={hasError}
      type={inputType}
      helperText={hasError ? errorMessage : null}
      width={width}
    />
  );
};

export default FormInput;
