import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { secondaryBlue7 } from 'config/theme/colors';

interface IReloadProps {
  children: string;
  onClick?: MouseEventHandler;
}

export const ReloadLink: React.FC<IReloadProps> = (props) => {
  const navigate = useNavigate();
  return (
    <StyledLink
      href="#"
      onClick={(e) => {
        e.preventDefault();

        if (props.onClick) {
          return props.onClick(e);
        } else {
          navigate(0);
        }
      }}
    >
      {props.children}
    </StyledLink>
  );
};

const StyledLink = styled('a')`
  &:visited {
    color: ${secondaryBlue7};
  }
`;
