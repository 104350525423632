import MuiFormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import MuiFormLabel, { formLabelClasses } from '@mui/material/FormLabel';
import MuiSwitch, { switchClasses } from '@mui/material/Switch';
import MuiFormControl from '@mui/material/FormControl';
import styled from 'styled-components';
import { primaryHover, primaryMain, textSecondary } from 'config/theme/colors';

export const Container = styled(MuiFormControl)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
`;

export const Switch = styled(MuiSwitch)`
  & .${switchClasses.checked} {
    color: ${primaryMain};
    &:hover {
      background-color: ${primaryHover};
    }
  }
  & .${switchClasses.checked} + .${switchClasses.track} {
    background-color: ${primaryMain};
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  flex-grow: 1;
  text-align: right;
  margin: 0;

  & .${formControlLabelClasses.label} {
    font-size: 12px;
    color: ${textSecondary};
  }

  &[data-is-checked="true"] {
    & .${formControlLabelClasses.label} {
      color: ${primaryMain};
    }
  }
`;

export const FormLabel = styled(MuiFormLabel)`
  text-align: left;
  display: flex;
  align-items: center;

  &.${formLabelClasses.focused} {
    color: ${textSecondary};
  }
`;
