import React from 'react';
import { useFormContext } from 'context/formContext';
import { ActionsBox, CancelButton, Container, EditModeBox, EditModeButtonIcon, EditModeLabel, SaveChangesButton } from './ActionBar.styles';

export const ACTION_BAR_ID = 'action-bar';

export interface IActionBarProps {
  enableSave?: boolean;
  onCancel?: () => void;
  onEditApproval?: () => void;
  triggerCancelAction?: boolean;
  lockUnlockText?: {
    disabled: string;
    enabled: string;
  };
}

const LOCK_UNLOCK_TEXT = {
  disabled: 'Click the lock to enable changes',
  enabled: 'Edit mode enabled',
};

export const ActionBar: React.FC<IActionBarProps> = ({
  enableSave = false,
  onCancel,
  onEditApproval,
  triggerCancelAction,
  lockUnlockText = LOCK_UNLOCK_TEXT,
}: IActionBarProps) => {
  const { isFormEnabled, setIsFormEnabled, setFormIsSubmitted, hasError } = useFormContext();
  const label = isFormEnabled ? lockUnlockText.enabled : lockUnlockText.disabled;
  const isSaveButtonDisabled = hasError ? true : !enableSave;

  const handleEditModeClick = () => {
    // If there is an edit approval needed we must ensure that the dialog should only
    // be shown when the form is not enabled.
    if (onEditApproval && !isFormEnabled) {
      onEditApproval();
    } else {
      setIsFormEnabled(!isFormEnabled);
    }
  };

  const handleCancelModeClick = () => {
    // If there is a cancel action nothing happens with the enable mode.
    if (onCancel && (enableSave || hasError || triggerCancelAction)) {
      onCancel?.();
    } else {
      setIsFormEnabled(!isFormEnabled);
    }
  };

  const handleSubmitClick = () => {
    setFormIsSubmitted(true);
  };

  return (
    <Container id={ACTION_BAR_ID}>
      <EditModeBox>
        <EditModeButtonIcon
          onClick={handleEditModeClick}
          isEnabled={isFormEnabled}
          id={`${ACTION_BAR_ID}_EDIT_MODE_BUTTON`}
        />
        <EditModeLabel>{label}</EditModeLabel>
      </EditModeBox>
      <ActionsBox isEnabled={isFormEnabled}>
        <CancelButton onClick={handleCancelModeClick} id={`${ACTION_BAR_ID}_CANCEL_BUTTON`} />
        <SaveChangesButton disabled={isSaveButtonDisabled} onClick={handleSubmitClick} id={`${ACTION_BAR_ID}_SAVE_CHANGES_BUTTON`} type='submit' />
      </ActionsBox>
    </Container>
  );
};

export default ActionBar;
