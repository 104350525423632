import React, { useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import DashboardsView from 'components/views/Dashboards/DashboardsView';
import SettingsView from 'components/views/Settings/SettingsView';
import TenantsView from 'components/views/Tenants/TenantsView';
import { DeltaDental404Page } from './components/DeltaDental404Page';
import theme from './config/theme/theme';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { DeltaDentalCrashPage } from 'components/DeltaDentalCrashPage';
import { AppBar } from 'components/presentation';
import { INavItem } from 'components/presentation/NavItem/NavItem';
import { createDefaultQueryClient } from './util/reactQuery';
import { FormProvider } from 'context/formContext';
import { DialogProvider } from 'context/dialogContext';
import { AppLayoutContainer } from 'App.styles';
import AuthProvider from 'context/Auth';
import TenantSettings from 'components/views/Tenants/TenantSettings';
import AppDrawer from 'components/presentation/AppDrawer';

const queryClient = createDefaultQueryClient();

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const handleSelectItem = useCallback(
    (item: INavItem) => navigate(item.url),
    [],
  );

  return (
    <ErrorBoundary defaultView={DeltaDentalCrashPage}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <FormProvider>
              <AuthProvider>
                <main id="delta-dental-container">
                  <AppBar />
                  <AppLayoutContainer>
                    <AppDrawer
                      onSelectItem={handleSelectItem}
                      currentRoute={location.pathname}
                    />
                    <Routes>
                      <Route path="/" element={<DashboardsView />}>
                        <Route path="*" element={<DeltaDental404Page />} />
                      </Route>
                      <Route path="/settings" element={<SettingsView />}>
                        <Route path="*" element={<DeltaDental404Page />} />
                      </Route>
                      <Route path="/tenants" element={<TenantsView />}>
                        <Route path="*" element={<DeltaDental404Page />} />
                      </Route>
                      <Route
                        path="/tenants/:tenantCode"
                        element={<TenantSettings />}
                      >
                        <Route path="*" element={<DeltaDental404Page />} />
                      </Route>
                    </Routes>
                  </AppLayoutContainer>
                </main>
              </AuthProvider>
            </FormProvider>
          </DialogProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
