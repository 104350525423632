import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import Grid from '@mui/material/Grid';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import useFileUpload from 'api/hooks/useFileUpload';
import { formatBytesToSize } from 'util/formats';
import {
  Container,
  FormUploadFileInput as Input,
  InputLabel,
  SelectFileButton,
} from './FormUploadFileInput.styles';
import { IState, Value } from '../Form/Form';
import { useFormContext } from 'context/formContext';

export const FORM_UPLOAD_FILE_INPUT_TESTID = 'FORM_UPLOAD_FILE_INPUT';

export const API_URL =
  process.env.REACT_APP_API_URL ||
  'https://api.bytescale.com/v2/accounts/W142iEs/uploads/binary';
export const TOKEN = 'public_W142iEsGEZGW1reiQhjC3h6GFEAH';

// 500KB default max file size
export const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10)
  : 512000;

export interface IJSONContent {
  [k: string]: any;
}
export interface IFormUploadFileInputProps extends OutlinedInputProps {
  fileTypes: string[];
  prop: keyof IState;
  handleOnChange: (prop: keyof IState, value: Value | null) => void;
  onSuccess?: (file: File, content?: IJSONContent) => void;
  onFailure?: (error?: string) => void;
  fullWidth?: boolean;
}

export type FileEventTarget = EventTarget & { files: FileList };

export const FormUploadFileInput: React.FC<IFormUploadFileInputProps> = ({
  onFailure,
  onSuccess,
  fileTypes,
  fullWidth,
  prop,
  handleOnChange,
  ...props
}: IFormUploadFileInputProps) => {
  const { setInputHasError } = useFormContext();
  const [file, setFile] = useState<File>();
  const [fileStatusText, setFileStatusText] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputTypesPermitted = fileTypes.join(', ');
  const label = `${inputTypesPermitted.toUpperCase()} File${
    fileTypes.length > 1 ? 's' : ''
  }`;
  const inputAcceptTypes = fileTypes
    .map((type: string) => `.${type}`)
    .join(', ');
  const [hasContentError, setHasContentError] = useState<boolean>(false);

  const { uploadFile, uploadProgress, error, isError, isMutating, isSuccess } =
    useFileUpload({
      permittedFileTypes: fileTypes,
      maxFileSize: MAX_FILE_SIZE,
    });

  const fileConstraintsHelperText = useMemo(() => {
    const fileTypesList = inputTypesPermitted.toUpperCase();
    const fileSize = formatBytesToSize(MAX_FILE_SIZE);
    return `File type: ${fileTypesList} — Max size: ${fileSize}`;
  }, []);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const [selectedFile] = event?.target?.files || [];
      if (!selectedFile) return;

      setHasContentError(false);
      handleOnChange(prop, selectedFile);
      uploadFile(selectedFile);

      setFile(selectedFile || null);
    },
    [],
  );

  const handleOpenFileSelector = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const onReadFileContent = (file?: File): void => {
    // For now we are getting content from json files
    // Later we might use another fileType
    if (file?.type === 'application/json') {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onloadend = () => {
        try {
          const jsonContent = JSON.parse(reader.result as string);
          onSuccess?.(file, jsonContent);
        } catch (error) {
          const errorText = 'JSON File not valid';
          setFileStatusText(errorText);
          setInputHasError(prop.toString(), true, 'invalid');
          onFailure?.(errorText);
          setHasContentError(true);
        }
      };
    }
  };

  useEffect(() => {
    const inputId = prop.toString();

    if (isMutating) {
      setFileStatusText(`Uploading... ${uploadProgress}%`);
    } else if (isError) {
      setFileStatusText(error?.toString());
      setInputHasError(inputId, true, 'invalid');
      onFailure?.(error);
    } else if (isSuccess) {
      setFileStatusText('Upload completed');
      onReadFileContent(file);
    }
  }, [isSuccess, isMutating, isError]);

  return (
    <Container
      data-testid={FORM_UPLOAD_FILE_INPUT_TESTID}
      fullWidth={fullWidth}
    >
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        multiple={false}
        accept={inputAcceptTypes}
        data-testid={`${FORM_UPLOAD_FILE_INPUT_TESTID}_FILE_INPUT`}
        data-idx={`${FORM_UPLOAD_FILE_INPUT_TESTID}_FILE_INPUT`}
      />
      <InputLabel
        htmlFor={props.id}
        required={props.required}
        error={isError || hasContentError}
        data-testid={`${FORM_UPLOAD_FILE_INPUT_TESTID}_LABEL`}
        data-idx={`${FORM_UPLOAD_FILE_INPUT_TESTID}_LABEL`}
      >
        {label}
      </InputLabel>
      <Input
        onClick={handleOpenFileSelector}
        endAdornment={
          <SelectFileButton
            variant="outlined"
            id={`${FORM_UPLOAD_FILE_INPUT_TESTID}_BUTTON`}
            data-testid={`${FORM_UPLOAD_FILE_INPUT_TESTID}_BUTTON`}
            data-idx={`${FORM_UPLOAD_FILE_INPUT_TESTID}_BUTTON`}
          >
            Select File
          </SelectFileButton>
        }
        label={label}
        id={props.id}
        value={file ? file.name : ''}
        error={isError || hasContentError}
        fullWidth={fullWidth}
        placeholder={'No file selected'}
        data-testid={`${FORM_UPLOAD_FILE_INPUT_TESTID}_INPUT`}
        data-idx={`${FORM_UPLOAD_FILE_INPUT_TESTID}_INPUT`}
        {...props}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormHelperText
            id="left-helper"
            data-testid={`${FORM_UPLOAD_FILE_INPUT_TESTID}_STATUS_TEXT`}
            data-idx={`${FORM_UPLOAD_FILE_INPUT_TESTID}_STATUS_TEXT`}
            error={isError || hasContentError}
          >
            {fileStatusText}
          </FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <FormHelperText
            id="right-helper"
            style={{
              textAlign: 'right',
            }}
          >
            {fileConstraintsHelperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormUploadFileInput;
