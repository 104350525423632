import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form, { IState } from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { useFormContext } from 'context/formContext';
import { useFetchAllDashboards } from 'api/hooks/useFetchAllDashboards';
import { useSetDefaultDashboards } from 'api/hooks/useSetDefaultDashboards';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import FormSelect, { Value } from 'components/shared/FormSelect/FormSelect';
import { DashboardTypes } from 'types/dashboards';
import { useAuth } from 'context/Auth';

const FORM_DATA_DEFAULTS = {
  internal: {
    value: '',
    defaultValue: '',
    legend: 'Dashboard for Internal Users',
    options: [],
    required: true,
  },
  external: {
    value: '',
    defaultValue: '',
    legend: 'Dashboard for External Users',
    options: [],
    required: true,
  },
};

const DISCARD_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Discard changes?',
  content: 'Are you sure you want to stop editing without saving changes?',
  acceptButtonText: 'Discard changes',
  cancelButtonText: 'Cancel',
};

const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Apply changes?',
  content:
    'Are you sure you want to save the changes made to Dashboard Visibility details?',
  acceptButtonText: 'Apply Changes',
  cancelButtonText: 'Cancel',
};

const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Edit Dashboards Visibility details?',
  content:
    'Remember that any changes made here will affect which Dashboards will be visible within your tenant’s instance by internal users (i.e. Sales) and by external users (i.e. Brokers).<br /><br /> Are you sure you want to proceed?',
  acceptButtonText: 'Start Editing',
  cancelButtonText: 'Cancel',
};

const DEFAULT_DIALOG_PROPS = {
  discard: DISCARD_DIALOG_PROPS,
  applyChanges: APPLY_CHANGES_DIALOG_PROPS,
  editApproval: EDIT_APPROVAL_DIALOG_PROPS,
};

const FORM_KEY = 'form-dashboards-visibility';

export const DashboardsVisibilityTab = () => {
  const { user } = useAuth();
  const { setIsFormEnabled, setFormIsSubmitted, resetForm } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );

  const {
    data: fetchedAllDashboardsResponse,
    isFetching: isDashboardsVisibilityFetching,
  } = useFetchAllDashboards();

  useEffect(() => {
    // Only initialize the form data if the data null
    if (fetchedAllDashboardsResponse?.data && !data) {
      const { dashboards, defaults } =
        fetchedAllDashboardsResponse?.data?.response || {};

      const internalOptions =
        dashboards?.map((dashboard) => ({
          value: dashboard.id as string,
          label: dashboard.name as string,
        })) || [];

      const externalOptions =
        dashboards?.map((dashboard) => ({
          value: dashboard.id as string,
          label: dashboard.name as string,
        })) || [];

      const defaultValue = {
        internal: {
          ...FORM_DATA_DEFAULTS.internal,
          defaultValue: defaults?.internal,
          value: defaults?.internal,
          options: internalOptions,
        },
        external: {
          ...FORM_DATA_DEFAULTS.external,
          defaultValue: defaults?.external,
          value: defaults?.external,
          options: externalOptions,
        },
      };

      setData(defaultValue);
      setInitialData(defaultValue);
    }
  }, [fetchedAllDashboardsResponse]);

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Dashboard Visibility have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    setFormIsSubmitted(true);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: setDefaultDashboardsMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetDefaultDashboards({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleOnChange = useCallback(
    (prop: keyof IState, value: string | number | undefined) => {
      setData((prevData) => ({
        ...prevData,
        [prop]: {
          ...(prevData as IState)[prop],
          value,
        },
      }));
    },
    [],
  );

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = async () => {
    await setDefaultDashboardsMutation({
      tenantCode: user?.tenantCode as string,
      request: [
        {
          dashboardId: data?.internal?.value as string,
          userClass: DashboardTypes.INTERNAL,
        },
        {
          dashboardId: data?.external?.value as string,
          userClass: DashboardTypes.EXTERNAL,
        },
      ],
    });
  };

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;
  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        isLoading={isMutating}
        instantlyCloseOnAccept={false}
      />
      <ComponentSpinner active={isDashboardsVisibilityFetching}>
        {data && (
          <Form
            state={data}
            handleCancel={handleOnCancelEditMode}
            handleSubmit={handleSubmitChanges}
            key={formKey}
            isFlex
            handleShowApproveEditDialog={handleShowApproveEditDialog}
          >
            <FormSelect
              key="internal"
              name="internal"
              required
              id="internal"
              prop="internal"
              options={data['internal'].options || []}
              label={data['internal'].legend || ''}
              handleOnSelect={handleOnChange}
              defaultValue={data['internal']?.defaultValue as Value}
              disabled={data['internal'].disabled}
              sx={{
                flex: 1,
                pr: '12px',
              }}
            />
            <FormSelect
              key="external"
              name="external"
              required
              id="external"
              prop="external"
              options={data['external'].options || []}
              label={data['external'].legend || ''}
              handleOnSelect={handleOnChange}
              defaultValue={data['external']?.defaultValue as Value}
              disabled={data['external'].disabled}
              sx={{
                flex: 1,
                pl: '12px',
              }}
              labelSx={{
                left: '12px',
              }}
            />
          </Form>
        )}
      </ComponentSpinner>
    </>
  );
};

export default DashboardsVisibilityTab;
