import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IContact } from 'types/contact';

type IFetchContactData = IContact

export function useFetchContact(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchContactData>,
    IResponseError
  >,
) {
  return useQuery<
    IResponse<IFetchContactData>,
    IResponseError
  >(
    [QueryKeys.GET_CONTACT],
    () => {
      return api.get<IFetchContactData>('/contact/getcontact');
    },
    queryOptions,
  );
}
