import React from 'react';
import MuiAlert, { alertClasses } from '@mui/material/Alert';
import { iconButtonClasses } from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import styled from 'styled-components';
import { IToastProps } from './Toast.types';
import { errorMain, successMain, white } from 'config/theme/colors';

export const MuiSnackbar = styled(Snackbar)`
  width: 34.375rem;
`;

interface IStyledToastContainer {
  $toastType: IToastProps['toastType'];
}

export const ToastContainer = styled.div<IStyledToastContainer>`
  width: 34.375rem;
`;

export const Container = styled((props) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    {...props}
  >
    <div>{props.children}</div>
  </Snackbar>
))`
  bottom: 3rem;
`;

export const Alert = styled(MuiAlert)`
  &.${alertClasses.root} {
    color: ${white};
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  & .${alertClasses.message} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
  &.${alertClasses.standardSuccess} {
    background-color: ${successMain};
  }
  &.${alertClasses.standardError} {
    background-color: ${errorMain};
  }
  & .${iconButtonClasses.root} {
    padding: 0.313rem;
    margin-left: 2rem;
  }
`;
