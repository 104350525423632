import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Font from '../Font';

interface IFullPageSpinner {
  text?: string;
}

const FullPageSpinner: React.FC<IFullPageSpinner> = ({
  text
}: IFullPageSpinner) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <CircularProgress color='success'  />
      <Font sx={{ mt: 2 }}>
        {text || 'Loading...'}
      </Font>
    </Box>
  );
};

export default FullPageSpinner;
