import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IAllDashboards } from 'types/dashboards';

type IFetchAllDashboards = IAllDashboards;

export function useFetchAllDashboards(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchAllDashboards>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchAllDashboards>, IResponseError>(
    [QueryKeys.GET_ALL_DASHBOARDS],
    () => {
      return api.get<IFetchAllDashboards>(`/dashboards/getalldashboards`);
    },
    queryOptions,
  );
}
