import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { DashboardTypes, IAllDashboards } from 'types/dashboards';

type ISetDefaultDashboards = IAllDashboards;

interface ISetDefaultDashboardsVariables {
  request?: {
    dashboardId?: string;
    userClass?: DashboardTypes;
  }[];
  tenantCode: string;
}

export function useSetDefaultDashboards(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetDefaultDashboards>,
    IResponseError,
    ISetDefaultDashboardsVariables
  >,
) {
  return useMutation<
    IResponse<IAllDashboards>,
    IResponseError,
    ISetDefaultDashboardsVariables
  >(
    [QueryKeys.SET_DEFAULT_DASHBOARDS],
    (args) => {
      return api.post<IAllDashboards>(`/dashboards/setdefaultdashboard`, args);
    },
    mutationOptions,
  );
}
