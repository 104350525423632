import React, { useCallback } from 'react';
import TabsPanel, { TabContent } from 'components/shared/Tabs/TabsPanel';
import PageContent from 'components/shared/PageContent';
import BrandingTab from './Tabs/Branding';
import ContactTab from './Tabs/Contact';
import ReportingDataTab from './Tabs/ReportingData';
import { ACTION_BAR_ID } from 'components/shared/ActionBar/ActionBar';
import { useFormContext } from 'context/formContext';
import { useDialogContext } from 'context/dialogContext';
import useClickOutside from 'util/hooks/useClickOutside';
import { SettingsTitle } from './SettingsView.styles';
import DashboardsVisibilityTab from './Tabs/DashboardsVisibility';
import PermissionsTab from './Tabs/Permissions/Permissions';
import SSOSettings from '../Tenants/Tabs/SSO/SSOSettings';
import { useAuth } from 'context/Auth';
import { IUser } from 'types/user';

export interface ISettingsViewProps {
  className?: string;
}

export const SETTINGS_KEY = 'settings';

interface ITabContentItem {
  label: string,
  title: string,
  description: string,
  children: JSX.Element,
  filterFn?(user: IUser): boolean,
};

const TAB_CONTENT: Array<ITabContentItem> = [
  {
    label: 'Contact',
    title: 'Contact Person',
    description:
      'This information will be included in error messages where the user should contact someone within your company to resolve the issue.',
    children: <ContactTab />,
    filterFn: () => { return false; }
  },
  {
    label: 'Branding',
    title: 'Branding',
    description:
      'Insert your Company name below to brand your tenant’s instance and dashboards accordingly.',
    children: <BrandingTab />,
    filterFn: (user) => { return user?.isTenantAdmin || user?.isProductAdmin; },
  },
  {
    label: 'Single Sign-On',
    title: 'Single Sign-On',
    description:
      'These are the details required to allow users to login to your Tenant’s instance.',
    children: <SSOSettings isTenantScoped={false} />,
    filterFn: (user) => { return user.isTenantAdmin || user.isProductAdmin; },
  },
  {
    label: 'Reporting Data',
    title: 'Reporting Data Details',
    description:
      'These are the details used to connect your Data Source to your Reports.',
    children: <ReportingDataTab isTenantScoped={false} />,
    filterFn: (user) => { return user.isTenantAdmin || user.isProductAdmin; },
  },
  {
    label: 'Dashboard Visibility',
    title: 'Dashboard Visibility',
    description:
      'Define which Dashboards will be visible within your tenant’s instance by internal users (i.e. Sales) and by external users (i.e. Brokers).',
    children: <DashboardsVisibilityTab />,
    filterFn: (user) => { return user?.isTenantAdmin || user?.isProductAdmin; },
  },
  {
    label: 'Permissions',
    title: 'Permissions',
    description:
    'Set reporting permissions for users',
    children: <PermissionsTab />,
    filterFn: (user) => { return user.isPermissionsAdmin || user.isTenantAdmin || user.isProductAdmin; }
  },
];

export const SettingsView: React.FC<ISettingsViewProps> = ({}: ISettingsViewProps) => {
  const { user } = useAuth();

  if (!user) { return (<PageContent key={SETTINGS_KEY}><SettingsTitle variant="h4">Settings</SettingsTitle></PageContent>); }

  const FILTERED_TABS = TAB_CONTENT.map((element, index) => { if (element.filterFn && element.filterFn(user)) { return { 
    label : element.label,
    title : element.title,
    description : element.description,
    children : element.children,
    key : index,
  };}}).filter((element) => element);

  const {
    hasChanges: formHasChanges,
    hasError,
    setFormHasChanges,
    resetForm,
    isFormEnabled,
    isSubmitted,
  } = useFormContext();
  const { showDialog } = useDialogContext();

  const onDialogDiscardChanges = useCallback((doClick: () => void) => {
    setFormHasChanges(false);
    resetForm();
    doClick();
  }, []);

  const renderDialog = useCallback(
    (doClick: any) => {
      if (!isFormEnabled || isSubmitted) return;
      if (formHasChanges || hasError) {
        showDialog({
          title: 'Discard changes?',
          content:
            'Are you sure you want to stop editing without saving changes?',
          acceptButtonText: 'Discard changes',
          cancelButtonText: 'Cancel',
          onAccept: () => onDialogDiscardChanges(doClick),
        });
      }
    },
    [formHasChanges, hasError, isFormEnabled, isSubmitted],
  );

  useClickOutside(
    [
      `${ACTION_BAR_ID}_EDIT_MODE_BUTTON`,
      `${ACTION_BAR_ID}_SAVE_CHANGES_BUTTON`,
      `${ACTION_BAR_ID}_CANCEL_BUTTON`,
    ],
    renderDialog,
  );

  return (
    <PageContent key={SETTINGS_KEY}>
      <SettingsTitle variant="h4">Settings</SettingsTitle>
      <TabsPanel name="settings">
        { FILTERED_TABS
          .map((item) => { return <TabContent {...item} key={item?.key}/>;}) }
      </TabsPanel>
    </PageContent>
  );
};

export default SettingsView;
