import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IContact } from 'types/contact';

type ISetContactData = IContact;

interface ISetContactVariables {
  name?: string;
  email?: string;
}

export function useSetContact(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetContactData>,
    IResponseError,
    ISetContactVariables
  >,
) {
  return useMutation<
    IResponse<ISetContactData>,
    IResponseError,
    ISetContactVariables
  >(
    [QueryKeys.SET_CONTACT],
    (args) => {
      return api.post<ISetContactData>('/contact/setcontact', args);
    },
    mutationOptions,
  );
}
