import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'context/formContext';
import {
  Container,
  Tab,
  TabPanel,
  Tabs,
  TabContent as StyledTabContent,
  TabsContentWrapper,
} from './TabsPanel.styles';
import {
  isAtBottomOfScreen,
  isComponentHeigthBiggerThanBottomOfPage,
} from 'util/math';

export const TABPANEL_ID = 'TABPANEL_ID';

export interface ITabContentProps {
  label: string;
  children: ReactNode;
  title?: string;
  description?: string;
}

export interface ITabsPanelProps {
  name: string;
  children: ReactElement<ITabContentProps> | ReactElement<ITabContentProps>[];
  isTabsInTabs?: boolean;
}

export const TabContent = StyledTabContent;

const ForwardRerfTabsPanel = React.forwardRef<HTMLDivElement, ITabsPanelProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, name, isTabsInTabs }, _ref) => {
    const { setIsFormEnabled } = useFormContext();
    const [isIntersectingBottom, setIsIntersectingBottom] =
      useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // Check if the component's height is bigger than the bottom of the page
      if (!isComponentHeigthBiggerThanBottomOfPage(targetRef.current)) {
        return; // If not, there's no need to proceed, so return early
      }

      // Check if the component is intersecting with the bottom of the screen
      setIsIntersectingBottom(isAtBottomOfScreen());
    }, [value, targetRef]);

    useEffect(() => {
      const elementToObserve = targetRef.current;

      if (!elementToObserve) {
        return;
      }

      const intersectionObserver = new IntersectionObserver(
        ([entry]) => {
          // Check if the element initially enters the viewport
          if (entry.isIntersecting) {
            setIsIntersectingBottom(true);
          }
        },
        { root: null, rootMargin: '0px 0px 48px 0px', threshold: [1] },
      );

      intersectionObserver.observe(elementToObserve);

      const handleScroll = () => {
        if (!elementToObserve) {
          return;
        }

        const rect = elementToObserve.getBoundingClientRect();
        const isIntersecting = rect.bottom <= window.innerHeight;

        // Check if the element is intersecting with the bottom of the viewport while scrolling
        setIsIntersectingBottom(isIntersecting);
      };

      // Add a scroll event listener to continuously check intersection while scrolling
      window.addEventListener('scroll', handleScroll);

      return () => {
        // Clean up the IntersectionObserver and scroll event listener when the component unmounts
        intersectionObserver.unobserve(elementToObserve);
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
      setIsFormEnabled(false);
      setValue(newValue);
    };

    const tabsChildren = Array.isArray(children) ? children : [children];

    const tabs = React.Children.map(tabsChildren, (child, index: number) => {
      if (React.isValidElement(child)) {
        return (
          <Tab key={index} label={child.props.label} id={`tab-${index}`} />
        );
      }
      return null;
    });

    const tabPanels = React.Children.map(tabsChildren, (child, index) => {
      if (React.isValidElement(child)) {
        return (
          <TabPanel
            key={index}
            value={value}
            index={index}
            isIntersectingBottom={isIntersectingBottom}
            isTabsInTabs={isTabsInTabs}
            {...child.props}
          >
            {child.props.children}
          </TabPanel>
        );
      }
      return null;
    });

    return (
      <Container>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={name}
          scrollButtons="auto"
          variant="scrollable"
        >
          {tabs}
        </Tabs>
        <TabsContentWrapper ref={targetRef}>
          {tabPanels}
        </TabsContentWrapper>
      </Container>
    );
  },
);

export const TabsPanel = ForwardRerfTabsPanel;

ForwardRerfTabsPanel.displayName = 'TabsPanel';

export default React.memo(ForwardRerfTabsPanel);
