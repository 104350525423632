import React, { useMemo } from 'react';

import NavItem, { INavItem } from 'components/presentation/NavItem/NavItem';
import { StyledAvatar, Container } from './Drawer.styles';
import List from '@mui/material/List';

export interface IDrawerProps {
  name?: string;
  items: INavItem[];
  onSelectItem?: (item: INavItem) => void;
  currentRoute: string;
  isStatic?: boolean;
}

export const Drawer: React.FC<IDrawerProps> = ({
  items,
  onSelectItem,
  currentRoute,
  isStatic = false,
}: IDrawerProps) => {
  const renderItems = useMemo(
    () => items.map((item: INavItem) =>
      <NavItem item={item} onSelectItem={onSelectItem} key={item.name} currentRoute={currentRoute} />
    ),
    [items, currentRoute],
  );

  return (
    <Container isStatic={isStatic}>
      <List disablePadding style={{ width: '100%', overflow: 'auto' }}>
        {renderItems}
      </List>
      <StyledAvatar />
    </Container>
  );
};

export default Drawer;
