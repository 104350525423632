import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button';
import OutlinedInput, {
  outlinedInputClasses,
  OutlinedInputProps,
} from '@mui/material/OutlinedInput';
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import styled from 'styled-components';

import { actionHover, primaryMain, textPrimaryRGB, white } from 'config/theme/colors';

interface IContainerProps extends BoxProps {
  fullWidth?: boolean;
}

export const Container = styled(({ fullWidth, ...props }: IContainerProps) => (
  <Box
    {...props}
    className={`MuiFormControl-root ${
      fullWidth ? 'MuiFormControl-fullWidth' : ''
    }`}
  />
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & .${inputBaseClasses.adornedEnd},
  & .${inputBaseClasses.root},
  & .MuiButton-Adorned {
    padding-right: 5px;
  }
`;

export const InputLabel = styled(FormLabel)`
  &.${formLabelClasses.root} {
    margin: 2rem 0rem 0rem 0rem;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 2rem);
    position: absolute;
    padding: 0rem 0.3125rem 0rem 0.3125rem;
    transform: translate(0.563rem, -0.563rem) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    user-select: none;
    background-color: ${white};

    &.Mui-focused:not(.Mui-error) {
      color: ${primaryMain};
    }
  }
`;

export const FormUploadFileInput = styled((props: OutlinedInputProps) => (
  <OutlinedInput size="small" {...props} />
))`
  margin: 2rem 0rem 0rem 0rem;
  padding-right: 5px;
  & .${outlinedInputClasses.root} {
    font-family: Gotham;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.009rem;
    color: ${textPrimaryRGB};
    padding: 8px 0px;
    align-items: center;
    align-self: stretch;
  }

  & .MuiInputBase-input {
    cursor: pointer;
  }
`;

export const SelectFileButton = styled((props: ButtonProps) => (
  <Button
    variant="outlined"
    size="small"
    {...props}
    className={`${props.className} MuiButton-Adorned`}
  />
))`
  &.${buttonClasses.root} {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    color: ${textPrimaryRGB};
    border-radius: 0.25rem;
    border: 1px solid ${textPrimaryRGB};
    padding: 0.2rem 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 7rem;
    &:hover {
      background-color: ${actionHover};
    }
  }
`;
