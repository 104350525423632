import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import React, { createContext, useContext, useState } from 'react';

export type IShowDialogProps = Omit<IDialogProps, 'isOpen'>;
export type CallbackFunction = (...args: any[]) => any;

export interface IDialogContextState {
  showDialog: (dialog: IShowDialogProps) => void;
}

const defaultState: IDialogContextState = {
  showDialog: () => false,
};

const DialogContext = createContext(defaultState);

export const useDialogContext = () => {
  return useContext(DialogContext);
};

export interface IDialogProviderProps {
  children?: React.ReactNode;
}

export const DialogProvider: React.FC<IDialogProviderProps> = ({ children }: IDialogProviderProps) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<IShowDialogProps>();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const showDialog = (content: IShowDialogProps) => {
    const newContent: IShowDialogProps = {
      ...content,
      onCancel: handleClose,
      onClose: handleClose,
    };
    setDialogContent(
      newContent
    );
    setDialogOpen(true);
  };
 
  return (
    <DialogContext.Provider value={{ showDialog }}>
      {children}
      <Dialog isOpen={isDialogOpen} {...dialogContent} />
    </DialogContext.Provider>
  );
};
