import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ITenant } from 'types/tenant';

type IFetchTenants = ITenant[];

export function useFetchTenants(
  queryOptions?: UseQueryOptions<IResponse<IFetchTenants>, IResponseError>,
) {
  return useQuery<IResponse<IFetchTenants>, IResponseError>(
    [QueryKeys.GET_TENANTS],
    () => {
      return api.get<IFetchTenants>('/tenant/gettenants');
    },
    queryOptions,
  );
}
