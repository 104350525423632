import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IAllDashboards } from 'types/dashboards';

export type IFetchTenantPermissions = [IFetchTenantPermission];

export interface IFetchTenantPermission {
  permissionId: number;
  tenant: {
    tenantId: number;
    code: string;
  };
  userLogin: string;
  clientId: string;
  groupId: string;
  subgroupId: string;
  groupName: string;
};

export function useFetchPermissionTable(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchTenantPermissions>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchTenantPermissions>, IResponseError>(
    [QueryKeys.GET_PERMISSIONS_TABLE],
    () => {
      return api.get<IFetchTenantPermissions>(`/permission/gettenantpermissions`);
    },
    queryOptions,
  );
}
