import React, { useState } from 'react';
import { IFetchTenantPermission } from 'api/hooks/useFetchPermissions';
import AddPermissions from './AddPermissions';
import PermissionsTable from './PermissionsTable';
import PermissionsBox from './PermissionsBox';

export interface IPermissionsTableProps {
  rows: IFetchTenantPermission[];
  setRows: React.Dispatch<React.SetStateAction<IFetchTenantPermission[]>>
}

export const PermissionsTab = () => {

  const [rows, setRows] = useState<IFetchTenantPermission[]>([]);

  return (
    <div>
      <PermissionsBox title='Add Permissions'>
        <AddPermissions rows={rows} setRows={setRows}></AddPermissions>
      </PermissionsBox>
      <br></br>
      <PermissionsBox title='Permissions'>
        <PermissionsTable rows={rows} setRows={setRows}></PermissionsTable>
      </PermissionsBox>
    </div>
  );
};

export default PermissionsTab;