import React from 'react';
import EnabledReports from './EnabledReports';
import SSOSettings from './SSO/SSOSettings';
import ReportingDataTab from 'components/views/Settings/Tabs/ReportingData';

export const TAB_CONTENT = {
  SSO: {
    label: 'Single Sign-On',
    title: 'Single Sign-On',
    description:
      'These are the details required to allow users to login to the Tenant’s instance.',
    children: <SSOSettings isTenantScoped />,
  },
  ReportingData: {
    label: 'Reporting Data',
    title: 'Reporting Data Details',
    description:
      'These are the details for the Data Source connected to the Tenant’s Reports.',
    children: <ReportingDataTab isTenantScoped />,
  },
  EnabledReports: {
    label: 'Enabled Dashboards',
    title: 'Enabled Dashboards',
    description:
      'Define which Dashboards will be enabled within the Tenant’s instance.',
    children: <EnabledReports />,
  },
};
