import React from 'react';

interface IErrorBoundaryProps {
  children: React.ReactNode | React.ReactNode[];

  defaultView: React.ComponentType<Record<string, never>>;
}

interface IErrorBoundaryState {
  readonly error?: Error;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public componentDidUpdate(previousProps: IErrorBoundaryProps) {
    if (previousProps.children !== this.props.children) {
      this.setState({ error: undefined });
    }
  }

  public render() {
    if (!this.state?.error) {
      return this.props.children;
    }

    return <this.props.defaultView />;
  }
}
