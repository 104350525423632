import styled from 'styled-components';
import Font from 'components/shared/Font';

export const SettingsTitle = styled(Font)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  max-width: 57.5rem;
`;
