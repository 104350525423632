import React from 'react';
import { Error } from '@mui/icons-material';
import BlankSlate from './BlankSlate';
import { ReloadLink } from './presentation/ReloadLink';

export function DeltaDentalCrashPage() {
  return (
    <BlankSlate
      fitToViewport
      icon={<Error />}
      titleText="Delta Dental could not be loaded"
      description={
        <>
          An error occurred and Delta Dental could not be loaded. Please{' '}
          <ReloadLink>reload</ReloadLink> the page or contact the IT Service.
        </>
      }
    />
  );
}
