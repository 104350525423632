import React, { useCallback } from 'react';
import { IState } from '../Form/Form';
import { Container, FormControlLabel, FormLabel, Switch } from './FormSwitch.styles';

export interface IFormSwitchProps {
  legend: string;
  prop: keyof IState;
  handleOnChange?: (prop: keyof IState, checked: boolean) => void;
  isChecked?: boolean;
}

export type FormSwitchType = React.ReactElement<typeof FormSwitch>;

export const FormSwitch = ({ legend, isChecked, prop, handleOnChange, ...props }: IFormSwitchProps) => {
  const label = isChecked ? 'Visible' : 'Hidden';

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      handleOnChange?.(prop, checked); // Handle Parent State
    },
    []
  );

  return (
    <Container {...props}>
      <FormLabel>{legend}</FormLabel>
      <FormControlLabel
        data-is-checked={isChecked}
        value={isChecked}
        control={<Switch color="primary" onChange={handleChange} checked={isChecked} />}
        label={label}
        labelPlacement="start"
      />
    </Container>
  );
};

export default FormSwitch;
