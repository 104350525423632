import { Alert } from '@mui/material';
import { useFetchLoginConfiguration } from 'api/hooks/useFetchLoginConfiguration';
import { useFetchMe } from 'api/hooks/useFetchMe';
import FullPageSpinner from 'components/shared/Spinner/FullPageSpinner';
import React, { createContext } from 'react';
import { IUser } from 'types/user';
import { getAuthTokenFromCookie } from 'util/auth';

export interface IAuthContextType {
  user?: IUser;
}

export const AuthContext = createContext<IAuthContextType>({});

type IAuthProviderProps = React.PropsWithChildren<{
  checkAuth?: boolean;
}>;

const AuthProvider: React.FC<IAuthProviderProps> = ({
  children,
}: IAuthProviderProps) => {
  const [isSomeTransitionLoading, setIsSomeTransitionLoading] =
    React.useState<boolean>(false);
  const [transitionError, setTransitionError] = React.useState<{
    message: string;
    code?: number;
  } | null>(null);

  const {
    isFetching: isLoginConfigurationFetching,
    error: loginConfigurationError,
  } = useFetchLoginConfiguration({
    // Retrieving configuration only if user does not have a token
    enabled: !getAuthTokenFromCookie(),
    onSuccess(response) {
      setIsSomeTransitionLoading(true);
      if (response?.data?.response?.loginClass?.redirectTarget) {
        // Redirecting to the login page
        window.location.replace(
          response.data?.response?.loginClass.redirectTarget,
        );
        setIsSomeTransitionLoading(false);
      } else {
        setTransitionError({
          message: 'Login configuration is not valid.',
        });
        setIsSomeTransitionLoading(false);
      }
    },
  });

  const {
    data: fetchMeResponse,
    error: fetchMeError,
    isFetching: isMeFetching,
  } = useFetchMe({
    // Fetching only when we have a token
    enabled: !!getAuthTokenFromCookie(),
  });

  const contextValue: IAuthContextType = {
    user: fetchMeResponse?.data?.response,
  };

  const isSomethingLoading =
    isLoginConfigurationFetching || isSomeTransitionLoading || isMeFetching;

  const isSomeError =
    loginConfigurationError || transitionError || fetchMeError; //ALEX - CHANGE THIS TO CATCH NO ACCESS?

  return (
    <AuthContext.Provider value={contextValue}>
      {isSomeError && (
        <Alert severity="error">
          Error while retrieving data. Please try to reload or contact support.
        </Alert>
      )}
      {/* ALEX - ADD DIFFERENT ERROR MESSAGING HERE FOR USER WITH NO ACCESS */}
      {!isSomeError && !isSomethingLoading && contextValue?.user && children}
      {!isSomeError && isSomethingLoading && (
        <FullPageSpinner text="Retrieving data..." />
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextType => React.useContext(AuthContext);

export default AuthProvider;
