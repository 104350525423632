import React, { useEffect, useState, useCallback } from 'react';
import { Button, FormControl, InputLabel, TextField, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { useFetchPermissionMapClient, IFetchPermissionMapClient
  , useFetchPermissionMapGroup, IFetchPermissionMapGroup
  , useFetchPermissionMapSubgroup, IFetchPermissionMapSubgroup
 } from 'api/hooks/useFetchPermissionsMap';
import { useAddPermission } from 'api/hooks/useAddPermission';
import { IFetchTenantPermission } from 'api/hooks/useFetchPermissions';
import { IPermissionsTableProps } from './PermissionsTable';

export const AddPermissions: React.FC<IPermissionsTableProps> = ({rows, setRows}) => {

  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const [clients, setClients] = useState<IFetchPermissionMapClient[]>([]);
  const [groups, setGroups] = useState<IFetchPermissionMapGroup[]>([]);
  const [subgroups, setSubgroups] = useState<IFetchPermissionMapSubgroup[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [userlogin, setUserLogin] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [groupId, setGroupId] = useState<string>('');
  const [subgroupId, setSubgroupId] = useState<string>('');

  const [toastOptions, setToastOptions] = useState<IToastProps | null>();

  //Fetch permissions map information from API
  //Render to console.log (for now)..

  const handleClientIdChange = (event: SelectChangeEvent) => {
    setClientId(event.target.value);
    checkEnableButton();
  };
  const handleGroupIdChange = (event: SelectChangeEvent) => {
    setGroupId(event.target.value);
    checkEnableButton();
  };
  const handleSubgroupIdChange = (event: SelectChangeEvent) => {
    setSubgroupId(event.target.value);
    checkEnableButton();
  };
  const handleUserLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserLogin(event.target.value);
    checkEnableButton();
  };
  const valuesInFields = () => {
    return (userlogin!='' && subgroupId!='' && groupId!='' && clientId!='');
  };

  const checkEnableButton = () => {
      setAddDisabled(!valuesInFields());
  };

  const {
    isFetching : isFetchingClients,
    data : fetchPermissionsMapClients
  } = useFetchPermissionMapClient();
  const {
    isFetching : isFetchingGroups,
    data : fetchPermissionMapGroups
  } = useFetchPermissionMapGroup();
  const {
    isFetching : isFetchingSubgroups,
    data : fetchPermissionMapSubgroups
  } = useFetchPermissionMapSubgroup();

  useEffect(() => {
    if (initialLoad && fetchPermissionsMapClients?.data?.response) {
      setClients(fetchPermissionsMapClients.data.response);
    }
    if (initialLoad && fetchPermissionMapGroups?.data?.response) {
      setGroups(fetchPermissionMapGroups.data.response);
    }
    if (initialLoad && fetchPermissionMapSubgroups?.data?.response) {
      setSubgroups(fetchPermissionMapSubgroups.data.response);
    }

    checkEnableButton();
  });

  const handleAddPermissionClick = (event: React.FormEvent<HTMLButtonElement>) => {
    if (valuesInFields()) {
      addPermissionChange();
    }
  };

  const onApplyChangesSuccess = (rawResponse: any) => {
    setToastOptions({
      message: 'Permission added',
      toastType: 'success'
    });
    const newRow = rawResponse?.data?.response as IFetchTenantPermission || null;
    setRows([...rows, newRow]);
    setUserLogin('');
    setClientId('');
    setGroupId('');
    setSubgroupId('');
  };
  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occured while saving. Please try again.',
      toastType: 'error'
    });
  };

  const {
    mutate: setAddPermissionMutation,
    isLoading: isMutating,
    isSuccess,
    isError
  } = useAddPermission({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const addPermissionChange = async () => {
    await setAddPermissionMutation({
      clientId: clientId,
      groupId: groupId,
      subgroupId: subgroupId,
      userLogin: userlogin
    });
  };

  return (
    <div>
      <Toast {...toastOptions} open={isRequestFinished} />
      <FormControl sx={{ m:1, minWidth: 120 }} size="small">
        <InputLabel id="client-label">Client</InputLabel>
        <Select
          labelId='client-label'
          id='client'
          label='Client'
          value={clientId}
          onChange={handleClientIdChange}
          >
            { clients.map((c) => {
              return (
                <MenuItem
                  key={c.clientId}
                  value={c.clientId}
                  >
                    {c.clientId}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl sx={{ m:1, minWidth: 120 }} size="small">
        <InputLabel id="group-label">Group</InputLabel>
        <Select
          labelId='group-label'
          id='group'
          label='Group'
          value={groupId}
          onChange={handleGroupIdChange}
        >
          { groups.map((g) => {
            return (
              <MenuItem
                key={g.groupId}
                value={g.groupId}
                >
                  {g.groupId}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m:1, minWidth: 120 }} size="small">
        <InputLabel id="subgroup-label">Subgroup</InputLabel>
        <Select
          labelId='subgroup-label'
          id='subgroup'
          label='Subgroup'
          value={subgroupId}
          onChange={handleSubgroupIdChange}
        >
          { subgroups.map((sg) => {
            return (
              <MenuItem
                key={sg.subgroupId}
                value={sg.subgroupId}
                >
                  {sg.subgroupId}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
        <TextField
          label='Username or Email'
          sx= {{ m:1, minWidth: 120 }}
          size="small"
          value={userlogin}
          onChange={handleUserLoginChange}
        ></TextField>
      <FormControl sx={{ m:1, minWidth: 120 }}>
        {/* This needs to align right */}
        <Button
          disabled={addDisabled}
          variant="outlined"
          color="success"
          onClick={handleAddPermissionClick}
        >
          Add Permission
        </Button>
      </FormControl>
      
    </div>
  );
};

export default AddPermissions;
