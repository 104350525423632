import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IJWTConfiguration } from 'types/jwtConfiguration';

type IFetchJWTConfiguration = IJWTConfiguration;

interface IFetchJWTConfigurationVariables {
  tenantCode?: string;
}

export function useFetchJWTConfiguration(
  variables: IFetchJWTConfigurationVariables,
  queryOptions?: UseQueryOptions<
    IResponse<IFetchJWTConfiguration>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchJWTConfiguration>, IResponseError>(
    [QueryKeys.GET_JWT_CONFIGURATION],
    () => {
      return api.get<IFetchJWTConfiguration>(
        `/login/jwtconfiguration/${variables.tenantCode}`,
      );
    },
    queryOptions,
  );
}
