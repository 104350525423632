import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IUser } from 'types/user';

type IFetchMeData = IUser;

export function useFetchMe(
  queryOptions?: UseQueryOptions<IResponse<IFetchMeData>, IResponseError>,
) {
  return useQuery<IResponse<IFetchMeData>, IResponseError>(
    [QueryKeys.ME],
    () => {
      return api.get<IFetchMeData>('/user');
    },
    queryOptions,
  );
}
