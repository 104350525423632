import React, { SVGProps } from 'react';

export const Profile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="PersonFilled">
      <path
        id="Vector"
        d="M10 10C11.8417 10 13.3334 8.50837 13.3334 6.66671C13.3334 4.82504 11.8417 3.33337 10 3.33337C8.15837 3.33337 6.66671 4.82504 6.66671 6.66671C6.66671 8.50837 8.15837 10 10 10ZM10 11.6667C7.77504 11.6667 3.33337 12.7834 3.33337 15V16.6667H16.6667V15C16.6667 12.7834 12.225 11.6667 10 11.6667Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Profile;
