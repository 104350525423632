/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface IFormContextState {
  isFormEnabled: boolean;
  setIsFormEnabled: (isFormEnabled: boolean) => void;
  hasChanges: boolean;
  setFormHasChanges: (hasChanges: boolean, doClick?: () => void) => void;
  isSubmitted: boolean;
  setFormIsSubmitted: (isSubmitted: boolean) => void;
  resetForm: () => void;
  hasError: boolean;
  setInputHasError: (
    inputId: string,
    hasError: boolean,
    failedValidationType: 'required' | 'invalid' | null,
  ) => void;
  clearErrors: () => void;
  inputHasErrorMap: FormInputErrorMapType;
}

const defaultState: IFormContextState = {
  isFormEnabled: false,
  setIsFormEnabled: (_enabled: boolean) => null,
  hasChanges: false,
  setFormHasChanges: (_hasChanges: boolean, doClick?: () => void) => null,
  isSubmitted: false,
  setFormIsSubmitted: (_isSubmitted: boolean) => null,
  resetForm: () => null,
  hasError: false,
  setInputHasError: (
    inputId: string,
    hasError: boolean,
    failedValidationType: 'required' | 'invalid' | null,
  ) => null,
  clearErrors: () => null,
  inputHasErrorMap: {},
};

const FormContext = createContext(defaultState);

export const useFormContext = () => {
  return useContext(FormContext);
};

export interface IFormProviderProps {
  children?: React.ReactNode;
}

export type FormInputErrorMapType = {
  [key in string]?: {
    hasError?: boolean;
    failedValidationType?: 'required' | 'invalid' | null;
  };
};

export const FormProvider: React.FC<IFormProviderProps> = ({
  children,
}: IFormProviderProps) => {
  const [isFormEnabled, setFormEnabled] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [inputHasErrorMap, setInputHasErrorMap] =
    useState<FormInputErrorMapType>({});

  const setIsFormEnabled = (enabled: boolean) => {
    setFormEnabled(enabled);
    setFormIsSubmitted(false);
  };

  const setFormHasChanges = (hasChanges: boolean) => {
    setHasChanges(hasChanges);
    setFormIsSubmitted(false);
  };

  const setFormIsSubmitted = (submitting: boolean) => {
    setIsSubmitted(submitting);
  };

  const resetForm = () => {
    setIsSubmitted(false);
    setIsFormEnabled(false);
    setHasChanges(false);
    // Clean Errors
    setInputHasErrorMap({});
  };

  const setInputHasError = (
    inputId: string,
    hasError: boolean,
    failedValidationType: 'required' | 'invalid' | null,
  ) => {
    setInputHasErrorMap((prevState) => ({
      ...prevState,
      [inputId]: {
        hasError,
        failedValidationType: failedValidationType,
      },
    }));
  };

  const clearErrors = () => setInputHasErrorMap({});

  // If any input has an error then the form has an error
  const hasError = Object.values(inputHasErrorMap).some(
    (itemHasError) => itemHasError?.hasError,
  );

  return (
    <FormContext.Provider
      value={{
        isFormEnabled,
        setIsFormEnabled,
        hasChanges,
        setFormHasChanges,
        isSubmitted,
        setFormIsSubmitted,
        resetForm,
        hasError,
        setInputHasError,
        clearErrors,
        inputHasErrorMap,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
