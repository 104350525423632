import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';

//Clients
export type IFetchPermissionMapClients = [IFetchPermissionMapClient];

export interface IFetchPermissionMapClient {
  tenant: {
    tenantId: number;
    code: string;
  };
  clientId: string;
};

export function useFetchPermissionMapClient(
  queryOptions?: UseQueryOptions<IResponse<IFetchPermissionMapClients>,IResponseError>
) {
  return useQuery<IResponse<IFetchPermissionMapClients>, IResponseError>(
    [QueryKeys.GET_PERMISSIONS_MAP_CLIENT],
    () => {
      return api.get<IFetchPermissionMapClients>('/permission/getclients');
    },
    queryOptions
  );
}

//Groups
export type IFetchPermissionMapGroups = [IFetchPermissionMapGroup];

export interface IFetchPermissionMapGroup {
  tenant: {
    tenantId: number;
    code: string;
  };
  groupId: string;
};

export function useFetchPermissionMapGroup(
  queryOptions?: UseQueryOptions<IResponse<IFetchPermissionMapGroups>,IResponseError>
) {
  return useQuery<IResponse<IFetchPermissionMapGroups>, IResponseError>(
    [QueryKeys.GET_PERMISSIONS_MAP_GROUP],
    () => {
      return api.get<IFetchPermissionMapGroups>('/permission/getgroups');
    },
    queryOptions
  );
}

//Subgroups
export type IFetchPermissionMapSubgroups = [IFetchPermissionMapSubgroup];

export interface IFetchPermissionMapSubgroup {
  tenant: {
    tenantId: number;
    code: string;
  };
  subgroupId: string;
};

export function useFetchPermissionMapSubgroup(
  queryOptions?: UseQueryOptions<IResponse<IFetchPermissionMapSubgroups>,IResponseError>
) {
  return useQuery<IResponse<IFetchPermissionMapSubgroups>, IResponseError>(
    [QueryKeys.GET_PERMISSIONS_MAP_SUBGROUP],
    () => {
      return api.get<IFetchPermissionMapSubgroups>('/permission/getsubgroups');
    },
    queryOptions
  );
}