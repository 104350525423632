import styled from 'styled-components';
import Font from 'components/shared/Font';

export const TenantsTitle = styled(Font)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  max-width: 57.5rem;
`;

export const BreadCrumbItem = styled(Font)`
  text-decoration: none;

  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
  }
`;
