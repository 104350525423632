import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ITenantBranding } from 'types/tenantBranding';

type ISetTenantBrandingData = ITenantBranding

interface ISetTenantBrandingVariables {
  tenantName?: string;
}

export function useSetTenantBranding(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetTenantBrandingData>,
    IResponseError,
    ISetTenantBrandingVariables
  >,
) {
  return useMutation<
    IResponse<ISetTenantBrandingData>,
    IResponseError,
    ISetTenantBrandingVariables
  >(
    [QueryKeys.SET_TENANT_BRANDING],
    (args) => {
      return api.post<ISetTenantBrandingData>('/branding/setbranding', args);
    },
    mutationOptions,
  );
}
