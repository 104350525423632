import { css } from 'styled-components';

export const BODY_LINE_HEIGHT = 14;
/*********************************
 * Building blocks (not exported)
 *********************************/

const book = css`
  font-weight: 300;
`;

const normal = css`
  font-weight: 400;
`;

const medium = css`
  font-weight: 500;
`;

const bold = css`
  font-weight: 700;
`;

const black = css`
  font-weight: 900;
`;

const h1 = css`
  font-size: 32px;
  line-height: 42px;
`;

const h2 = css`
  font-size: 28px;
  line-height: 37px;
`;

const h3 = css`
  font-size: 24px;
  line-height: 32px;
`;

const h4 = css`
  font-size: 22px;
  line-height: 30px;
`;

const h5 = css`
  font-size: 20px;
  line-height: 24px;
`;

const h6 = css`
  font-size: 16px;
  line-height: 24px;
`;

const body = css`
  font-size: 14px;
  line-height: ${BODY_LINE_HEIGHT}px;
`;

const smallBody = css`
  font-size: 14px;
  line-height: 20px;
`;

const subText = css`
  font-size: 12px;
  line-height: 18px;
`;

const smallText = css`
  font-size: 11px;
  line-height: 16px;
`;

const xsmallText = css`
  font-size: 10px;
  line-height: 14px;
`;

const caps = css`
  text-transform: uppercase;
`;

/************************************
 * EXPORTS - HEADERS
 ************************************/

export const H1_BOOK = css`
  ${h1};
  ${book};
`;

export const H1_MEDIUM = css`
  ${h1};
  ${medium};
`;

export const H1_BOLD = css`
  ${h1};
  ${bold};
`;

export const H2_BOOK = css`
  ${h2};
  ${book};
`;

export const H2_MEDIUM = css`
  ${h2};
  ${medium};
`;

export const H2_BOLD = css`
  ${h2};
  ${bold};
`;

export const H3 = css`
  ${h3};
`;

export const H3_BOOK = css`
  ${h3};
  ${book};
`;

export const H3_MEDIUM = css`
  ${h3};
  ${medium};
`;

export const H3_BOLD = css`
  ${h3};
  ${bold};
`;

export const H4_BOOK = css`
  ${h4};
  ${book};
`;

export const H4_MEDIUM = css`
  ${h4};
  ${medium};
`;

export const H4_BOLD = css`
  ${h4};
  ${bold};
`;

export const H5_BOOK = css`
  ${h5};
  ${book};
`;

export const H5_MEDIUM = css`
  ${h5};
  ${medium};
`;

export const H5_BOLD = css`
  ${h5};
  ${bold};
`;

export const H6 = css`
  ${h6};
`;

export const H6_BOOK = css`
  ${h6};
  ${book};
`;

export const H6_MEDIUM = css`
  ${h6};
  ${medium};
`;

export const H6_BOLD = css`
  ${h6};
  ${bold};
`;

/************************************
 * EXPORTS - BODY & SUPPORT
 ************************************/

export const TEXT_NORMAL = css`
  ${normal};
`;

export const TEXT_BOLD = css`
  ${bold};
`;

export const BODY_BOOK = css`
  ${body};
  ${book};
`;

export const BODY_CHIP = css`
  font-size: 13px;
  line-height: ${BODY_LINE_HEIGHT}px;
  ${normal};
`;

export const BODY_NORMAL = css`
  ${body};
  ${normal};
`;

export const BODY_1 = css`
  ${h6};
  ${normal};
`;

export const BODY_SMALL = css`
  ${smallBody};
`;

export const BODY_MEDIUM = css`
  ${body};
  ${medium};
`;

export const BODY_BOLD = css`
  ${body};
  ${bold};
`;

export const SMALL_BODY_BOOK = css`
  ${smallBody};
  ${book};
`;

export const SMALL_BODY_MEDIUM = css`
  ${smallBody};
  ${medium};
`;

export const SMALL_BODY_BOLD = css`
  ${smallBody};
  ${bold};
`;

export const SMALL_BODY_BLACK = css`
  ${smallBody};
  ${black};
`;

export const SUB_TEXT = css`
  ${subText};
`;

export const SUB_TEXT_BOOK = css`
  ${subText};
  ${book};
`;

export const SUB_TEXT_MEDIUM = css`
  ${subText};
  ${medium};
`;

export const SUB_TEXT_BOLD = css`
  ${subText};
  ${bold};
`;

export const SUB_TEXT_BOLD_CAPS = css`
  ${subText};
  ${bold};
  ${caps};
`;

export const SMALL_TEXT_BOOK = css`
  ${smallText};
  ${book};
`;

export const SMALL_TEXT_MEDIUM = css`
  ${smallText};
  ${medium};
`;

export const SMALL_TEXT_BOLD = css`
  ${smallText};
  ${bold};
`;

export const XSMALL_TEXT_MEDIUM = css`
  ${xsmallText};
  ${medium};
`;

export const XSMALL_TEXT_CAPS_BOOK = css`
  ${xsmallText};
  ${book};
  ${caps};
`;

export const XSMALL_TEXT_CAPS_BOLD = css`
  ${xsmallText};
  ${bold};
  ${caps};
`;

/************************************
 * EXPORTS - BUTTONS
 ************************************/
export const SMALL_BODY_BUTTON = css`
  ${bold}
  font-size: 14px;
  line-height: 16px;
`;

export const SMALL_BODY_BUTTON_CAPS = css`
  ${SMALL_BODY_BUTTON}
  ${caps}
`;

export const BODY_BUTTON = css`
  ${body}
  ${bold}
`;

export const BODY_BUTTON_CAPS = css`
  ${BODY_BUTTON}
  ${caps}
`;

export const FONT = `font-family: 'Gotham', sans-serif;`;
