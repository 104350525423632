import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form, { IState } from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { FormInput } from 'components/shared/FormInput/FormInput';
import { useFormContext } from 'context/formContext';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { useFetchSamlConfiguration } from 'api/hooks/useFetchSamlConfiguration';
import { useSetSamlConfiguration } from 'api/hooks/useSetSamlConfiguration';
import { useFetchSamlReplyUrl } from 'api/hooks/useFetchSamlReplyUrl';
import { useAuth } from 'context/Auth';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';

const FORM_DATA_DEFAULTS = {
  idpMetadataEndpoint: {
    legend: 'IdP Metadata URL',
  },
  applicationName: {
    legend: 'Enterprise Application Name',
  },
  applicationID: {
    legend: 'Enterprise Application ID',
  },
  entityId: {
    legend: 'Entity ID',
  },
  accessGroupId: {
    legend: 'Group ID for Access',
  },
  tenantAdminGroupId: {
    legend: 'Group ID for Tenant Admin',
  },
  productAdminGroupId: {
    legend: 'Group ID for Product Owner',
  },
  permissionsAdminGroupId : {
    legend: 'Group ID for Permission Admin'
  },
};

const FORM_KEY = 'form-contact';

interface IInternalTabProps {
  isTenantScoped?: boolean;
}

export const InternalTab: React.FC<IInternalTabProps> = ({
  isTenantScoped = false,
}: IInternalTabProps) => {
  const DISCARD_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Discard changes?',
    content: 'Are you sure you want to stop editing without saving changes?',
    acceptButtonText: 'Discard changes',
    cancelButtonText: 'Cancel',
  };

  const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Apply changes?',
    content: `Remember that you are changing sensitive details that will affect login into ${ isTenantScoped ? 'the' : 'your'} Tenant’s instance. <br/><br/>Are you sure you want to proceed?`,
    acceptButtonText: 'Apply Changes',
    cancelButtonText: 'Cancel',
  };

  const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Edit Single Sign-On details?',
    content:
      `Remember that you are changing sensitive details that will affect login into ${ isTenantScoped ? 'the' : 'your'} Tenant’s instance.<br /><br /> Are you sure you want to proceed?`,
    acceptButtonText: 'Start Editing',
    cancelButtonText: 'Cancel',
  };

  const DEFAULT_DIALOG_PROPS = {
    discard: DISCARD_DIALOG_PROPS,
    applyChanges: APPLY_CHANGES_DIALOG_PROPS,
    editApproval: EDIT_APPROVAL_DIALOG_PROPS,
  };

  const queryParams = useParams<{ tenantCode: string }>();
  const { user } = useAuth();
  const { setIsFormEnabled, setFormIsSubmitted, resetForm } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [infoToastOptions, setInfoToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );
  const tenantCode = isTenantScoped
    ? queryParams?.tenantCode
    : user?.tenantCode;

  const {
    data: fetchedSamlConfigurationResponse,
    isFetching: isSamlConfigurationFetching,
  } = useFetchSamlConfiguration(
    {
      tenantCode: tenantCode,
    },
    {
      // Never cache this request
      cacheTime: 0,
    },
  );

  const {
    data: fetchedSamlReplyUrlResponse,
    isFetching: isSamlReplyUrlFetching,
  } = useFetchSamlReplyUrl(
    {
      tenantCode: tenantCode,
    },
    {
      // Never cache this request
      cacheTime: 0,
    },
  );

  useEffect(() => {
    // Only initialize the form data if the data null
    if (fetchedSamlConfigurationResponse?.data && !data) {
      const {
        accessGroupId,
        applicationID,
        applicationName,
        entityId,
        idpMetadataEndpoint,
        productAdminGroupId,
        tenantAdminGroupId,
        permissionsAdminGroupId,
      } = fetchedSamlConfigurationResponse?.data?.response || {};

      const defaultValue = {
        idpMetadataEndpoint: {
          ...FORM_DATA_DEFAULTS.idpMetadataEndpoint,
          value: idpMetadataEndpoint,
          defaultValue: idpMetadataEndpoint,
        },
        applicationName: {
          ...FORM_DATA_DEFAULTS.applicationName,
          value: applicationName,
          defaultValue: applicationName,
        },
        applicationID: {
          ...FORM_DATA_DEFAULTS.applicationID,
          value: applicationID,
          defaultValue: applicationID,
        },
        entityId: {
          ...FORM_DATA_DEFAULTS.entityId,
          value: entityId,
          defaultValue: entityId,
        },
        accessGroupId: {
          ...FORM_DATA_DEFAULTS.accessGroupId,
          value: accessGroupId,
          defaultValue: accessGroupId,
        },
        tenantAdminGroupId: {
          ...FORM_DATA_DEFAULTS.tenantAdminGroupId,
          value: tenantAdminGroupId,
          defaultValue: tenantAdminGroupId,
        },
        productAdminGroupId: {
          ...FORM_DATA_DEFAULTS.productAdminGroupId,
          value: productAdminGroupId,
          defaultValue: productAdminGroupId,
        },
        permissionsAdminGroupId: {
          ...FORM_DATA_DEFAULTS.permissionsAdminGroupId,
          value: permissionsAdminGroupId,
          defaultValue: permissionsAdminGroupId,
        },
      };

      setData(defaultValue);
      setInitialData(defaultValue);
    }
  }, [fetchedSamlConfigurationResponse]);

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Single Sign-On have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    setFormIsSubmitted(true);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: setSamlConfigurationMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetSamlConfiguration({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleOnChange = useCallback((prop: keyof IState, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...(prevData as IState)[prop],
        value,
      },
    }));
  }, []);

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = async () => {
    await setSamlConfigurationMutation({
      idpMetadataEndpoint: data?.idpMetadataEndpoint?.value as string,
      applicationName: data?.applicationName?.value as string,
      applicationID: data?.applicationID?.value as string,
      entityId: data?.entityId?.value as string,
      accessGroupId: data?.accessGroupId?.value as string,
      tenantAdminGroupId: data?.tenantAdminGroupId?.value as string,
      productAdminGroupId: data?.productAdminGroupId?.value as string,
      permissionsAdminGroupId: data?.permissionsAdminGroupId?.value as string,
      tenantCode: tenantCode as string,
    });
  };

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;
  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  const copySamlReplyUrl = () => {
    const value =
      fetchedSamlReplyUrlResponse?.data?.response?.samlReplyUrl || '';
    navigator?.clipboard?.writeText?.(value);
    setInfoToastOptions({
      message: 'Copied to clipboard',
      toastType: 'success',
      open: true,
    });
  };

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Toast {...infoToastOptions} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        isLoading={isMutating}
        instantlyCloseOnAccept={false}
      />
      <ComponentSpinner
        active={isSamlConfigurationFetching || isSamlReplyUrlFetching}
      >
        {data && (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                mb: 3,
                mt: -2,
              }}
            >
              <Typography variant="caption" color="ActiveBorder">
                Reply URL
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography variant="subtitle1" color="ActiveBorder">
                  {fetchedSamlReplyUrlResponse?.data?.response?.samlReplyUrl ||
                    '--'}
                </Typography>
                <Tooltip title="Copy to clipboard">
                  <IconButton onClick={copySamlReplyUrl}>
                    <ContentCopyOutlined
                      color="disabled"
                      sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Form
              state={data}
              handleCancel={handleOnCancelEditMode}
              handleSubmit={handleSubmitChanges}
              key={formKey}
              isFlex
              handleShowApproveEditDialog={handleShowApproveEditDialog}
            >
              <FormInput
                required
                type="text"
                id="idpMetadataEndpoint"
                name="idpMetadataEndpoint"
                prop={'idpMetadataEndpoint'}
                label={FORM_DATA_DEFAULTS.idpMetadataEndpoint.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['idpMetadataEndpoint'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pr: '12px',
                }}
              />
              <FormInput
                required
                type="text"
                id="applicationName"
                name="applicationName"
                prop={'applicationName'}
                label={FORM_DATA_DEFAULTS.applicationName.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['applicationName'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pl: '12px',
                }}
                InputLabelProps={{
                  sx: {
                    left: '12px',
                  },
                }}
              />
              <FormInput
                required
                type="text"
                id="applicationID"
                name="applicationID"
                prop={'applicationID'}
                label={FORM_DATA_DEFAULTS.applicationID.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['applicationID'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pr: '12px',
                }}
              />
              <FormInput
                required
                type="text"
                id="entityId"
                name="entityId"
                prop={'entityId'}
                label={FORM_DATA_DEFAULTS.entityId.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['entityId'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pl: '12px',
                }}
                InputLabelProps={{
                  sx: {
                    left: '12px',
                  },
                }}
              />
              {/* GroupIDs */}
              <FormInput
                required
                type="text"
                id="accessGroupId"
                name="accessGroupId"
                prop={'accessGroupId'}
                label={FORM_DATA_DEFAULTS.accessGroupId.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['accessGroupId'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pr: '12px',
                }}
              />
              <FormInput
                required
                type="text"
                id="tenantAdminGroupId"
                name="tenantAdminGroupId"
                prop={'tenantAdminGroupId'}
                label={FORM_DATA_DEFAULTS.tenantAdminGroupId.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['tenantAdminGroupId'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  px: '12px',
                  paddingRight: 0,
                }}
                InputLabelProps={{
                  sx: {
                    left: '12px',
                  },
                }}
              />
              <FormInput
                required
                type="text"
                id="productAdminGroupId"
                name="productAdminGroupId"
                prop={'productAdminGroupId'}
                label={FORM_DATA_DEFAULTS.productAdminGroupId.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['productAdminGroupId'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pl: '12px',
                  paddingLeft: 0,
                  paddingRight: '12px',
                }}
                InputLabelProps={{
                  sx: {
                    left: '',
                  },
                }}
              />
              <FormInput
                required
                type="text"
                id="permissionsAdminGroupId"
                name="permissionsAdminGroupId"
                prop={'permissionsAdminGroupId'}
                label={FORM_DATA_DEFAULTS.permissionsAdminGroupId.legend}
                handleOnChange={handleOnChange}
                defaultValue={data['permissionsAdminGroupId'].defaultValue}
                width="50%"
                sx={{
                  marginRight: '0 !important',
                  pl: '12px',
                }}
                InputLabelProps={{
                  sx: {
                    left: '12px',
                  },
                }}
              />
            </Form>
          </>
        )}
      </ComponentSpinner>
    </>
  );
};

export default InternalTab;
