import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IJWTConfiguration } from 'types/jwtConfiguration';

type ISetJWTConfigurationData = IJWTConfiguration;

interface ISetJWTConfigurationVariables {
  signingKey: string;
  encryptionKey: string;
  corsDomain: string;
  tenantCode: string;
}

export function useSetJWTConfiguration(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetJWTConfigurationData>,
    IResponseError,
    ISetJWTConfigurationVariables
  >,
) {
  return useMutation<
    IResponse<ISetJWTConfigurationData>,
    IResponseError,
    ISetJWTConfigurationVariables
  >(
    [QueryKeys.SET_JWT_CONFIGURATION],
    (args) => {
      return api.post<ISetJWTConfigurationData>(
        '/login/jwtconfiguration',
        args,
      );
    },
    mutationOptions,
  );
}
