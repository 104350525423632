export enum DashboardTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface IAllDashboards {
  defaults?: {
    internal?: string;
    external?: string;
  };
  dashboards?: {
    id?: string;
    userClass?: DashboardTypes;
    name?: string;
  }[];
}
