import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ISamlConfiguration } from 'types/samlConfiguration';

type IFetchSamlConfiguration = ISamlConfiguration;

interface IFetchSamlConfigurationVariables {
  tenantCode?: string;
}

export function useFetchSamlConfiguration(
  variables: IFetchSamlConfigurationVariables,
  queryOptions?: UseQueryOptions<
    IResponse<IFetchSamlConfiguration>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchSamlConfiguration>, IResponseError>(
    [QueryKeys.GET_SAML_CONFIGURATION],
    () => {
      return api.get<IFetchSamlConfiguration>(
        `/login/samlconfiguration/${variables.tenantCode}`,
      );
    },
    queryOptions,
  );
}
