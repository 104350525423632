import React, { useCallback, useState } from 'react';
import { IState } from '../Form/Form';
import { FormControlLabel, Checkbox, Container } from './FormCheckbox.styles';

export const FORM_CHECKBOX_TESTID = 'FORM_CHECKBOX_TESTID';

export interface IFormCheckboxProps {
  label?: string;
  prop: keyof IState;
  handleOnChange?: (prop: keyof IState, checked: boolean) => void;
  defaultValue?: boolean;
  required?: boolean;
  id?: string;
  name?: string;
}

export type FormCheckboxType = React.ReactElement<typeof FormCheckbox>;

export const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  label,
  prop,
  defaultValue,
  handleOnChange,
  ...props
}: IFormCheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(defaultValue));

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsChecked(checked);
      handleOnChange?.(prop, checked); // Handle Parent State
    },
    [],
  );

  return (
    <Container data-testid={FORM_CHECKBOX_TESTID}>
      <FormControlLabel
        data-is-checked={isChecked}
        value={isChecked}
        control={
          <Checkbox
            color="primary"
            onChange={handleChange}
            checked={isChecked}
            data-testid={`${FORM_CHECKBOX_TESTID}-CHECKBOX`}
          />
        }
        label={label}
        data-testid={`${FORM_CHECKBOX_TESTID}-LABEL`}
        {...props}
      />
    </Container>
  );
};

export default FormCheckbox;
