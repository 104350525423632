import React, { useState, useCallback } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

import {
  AppBarLogo,
  AppBarName,
  AppBar as StyledAppBar,
  AppBarProfileMenu,
} from './AppBar.styles';
import { useAuth } from 'context/Auth';
import { useLogOut } from 'api/hooks/useLogOut';
import { removeAuthTokenFromCookie } from 'util/auth';
import { useFetchTenantBranding } from 'api/hooks/useFetchTenantBranding';

export const TEST_ID = 'AppBar';

export const AppBar: React.FC = () => {
  const { user } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { data: brandingFetchResponse } = useFetchTenantBranding({
    onSuccess(rawResponse) {
      // set page title
      document.title = `${
        rawResponse?.data?.response?.tenantName || 'Delta Dental'
      } - infobridgeanalytics.com`;
    },
  });

  const { mutate: logoutMutation } = useLogOut();

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [anchorElUser],
  );

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [anchorElUser]);

  const onLogOutClick = useCallback(async () => {
    handleCloseUserMenu();
    await logoutMutation();
    removeAuthTokenFromCookie();
    window.location.reload();
  }, [handleCloseUserMenu]);

  return (
    <StyledAppBar position="static" data-testid={TEST_ID}>
      <Container maxWidth={false} data-testid={`${TEST_ID}-Container`}>
        <Toolbar disableGutters>
          <AppBarLogo data-testid={`${TEST_ID}-Logo`} />
          <AppBarName
            companyName={brandingFetchResponse?.data?.response?.tenantName}
            data-testid={`${TEST_ID}-Name`}
            data-idx={`${TEST_ID}-Name`}
          />
          {user && (
            <AppBarProfileMenu
              profile={user}
              handleOpen={handleOpenUserMenu}
              handleClose={handleCloseUserMenu}
              anchorElement={anchorElUser}
              data-testid={`${TEST_ID}-Profile-Menu-Box`}
              data-idx={`${TEST_ID}-Profile-Menu-Box`}
            >
              <MenuItem
                onClick={onLogOutClick}
                data-testid={`${TEST_ID}-Profile-Menu-SignOut`}
                data-idx={`${TEST_ID}-Profile-Menu-SignOut`}
              >
                Sign out
              </MenuItem>
            </AppBarProfileMenu>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default AppBar;
