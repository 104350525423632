import React from 'react';
import Button, { ButtonProps, buttonClasses } from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';
import Font from 'components/shared/Font';
import { primaryMain, disabled, disabledBackground, white, primaryDark, primaryHover } from 'config/theme/colors';

export const Container = styled(MuiDialog)`
  & .MuiDialog-paper {
    margin: 0rem;
    width: 27.75rem;
  }
`;

export const CancelButton = styled(
  (props: ButtonProps) => <Button variant="text" {...props} />
)`
  &.${buttonClasses.root} {
    color: ${primaryMain};
    margin-right: 0.5rem;
    &:hover {
      background: ${primaryHover};
    }
  }
`;

export const AcceptButton = styled(
  (props: ButtonProps) => <Button variant="contained" {...props} />
)`
  &.${buttonClasses.root} {
    color: ${white};
    background: ${primaryMain};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    &:hover {
      background: ${primaryDark};
    }
  }
  &.${buttonClasses.disabled} {
    color: ${disabled};
    background: ${disabledBackground};
    border: 0px solid ${disabledBackground};
  }
`;

export const DialogTitle = styled(
  (props) => <Font variant="h6" {...props}>{props.children}</Font>
)`
  padding: 1rem 1.5rem;
  flex: 0 0 auto;
`;

export const DialogContentText = styled(
  (props) => <Font variant="body1" {...props}>{props.children}</Font>
)``;

export const DialogContent = styled(MuiDialogContent)``;
