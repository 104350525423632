import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import styled from 'styled-components';
import { green500, green600, primaryMain, textPrimary, whiteMain } from 'config/theme/colors';

export const ListItem = styled(
  MuiListItem
)`
  display: flex;
  height: 40px;
  align-items: center;
  align-self: stretch;
  color: ${whiteMain};
  margin: 0.25rem 0rem 0.25rem 0rem;
  background-color: ${green500};

  &:first-child {
    margin-top: 0rem;
  }

  &.Mui-selected,
  &.MuiListItemButton-root.Mui-selected {
    pointer-events: none; /* Prevent interaction */
    background-color: ${whiteMain};
    color: ${textPrimary};
    & .MuiListItemIcon-root {
      color: ${primaryMain};
    }
  }

  &.MuiListItemButton-root {
    background-color: transparent;
  }

  &:hover {
    background-color: ${green600};
    color: ${whiteMain};
    & .MuiListItemIcon-root {
      color: ${whiteMain};
    }
  }
`;

export const ListItemIcon = styled(
  MuiListItemIcon
)`
  color: ${whiteMain};
  min-width: 1.25rem;
  padding: 0rem 1rem 0rem 0rem;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const ListItemButton = styled(
  MuiListItemButton
)`
  padding: 0.125rem 1.5rem;
  height: 100%;
`;

export const ListItemText = styled(
  MuiListItemText
)`
  & .${listItemTextClasses.primary} {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;
