import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ITenantBranding } from 'types/tenantBranding';

type IFetchTenantBrandingData = ITenantBranding;

export function useFetchTenantBranding(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchTenantBrandingData>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchTenantBrandingData>, IResponseError>(
    [QueryKeys.TENANT_BRANDING],
    () => {
      return api.get<IFetchTenantBrandingData>('/branding/getbranding');
    },
    queryOptions,
  );
}
