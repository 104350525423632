import MuiFormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import MuiCheckbox, { checkboxClasses } from '@mui/material/Checkbox';
import MuiFormControl from '@mui/material/FormControl';
import { svgIconClasses } from '@mui/material/SvgIcon';
import styled from 'styled-components';
import { primaryHover, primaryMain, textPrimary } from 'config/theme/colors';
import { BODY_1 } from 'config/theme/typography';

export const Container = styled(MuiFormControl)``;

export const Checkbox = styled(MuiCheckbox)`
  &.${checkboxClasses.root} {
    &.${checkboxClasses.checked} {
      color: ${primaryMain};
      &:hover {
        background-color: ${primaryHover};
      }
    }
    & .${svgIconClasses.root} {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  flex-grow: 1;
  text-align: right;
  margin: 0;

  & .${formControlLabelClasses.label} {
    ${BODY_1};
    color: ${textPrimary};
  }
`;
