import React, { useCallback, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Box, Breadcrumbs } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import TabsPanel, { TabContent } from '../../shared/Tabs/TabsPanel';
import PageContent from '../../shared/PageContent';
import { ACTION_BAR_ID } from '../../shared/ActionBar/ActionBar';
import { useFormContext } from '../../../context/formContext';
import { useDialogContext } from '../../../context/dialogContext';
import useClickOutside from '../../../util/hooks/useClickOutside';
import { BreadCrumbItem, TenantsTitle } from './TenantSettings.styles';
import { TAB_CONTENT } from './Tabs/config';
import { useFetchTenants } from 'api/hooks/useFetchTenants';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { ITenant } from 'types/tenant';

export interface ITenantSettingsProps {
  className?: string;
}

export const TENANTS_SETTINGS_KEY = 'Tenants_Settings';

export const TenantSettings: React.FC<
  ITenantSettingsProps
> = ({}: ITenantSettingsProps) => {
  const routeParams = useParams<{ tenantCode: string }>();
  const {
    hasChanges: formHasChanges,
    hasError,
    setFormHasChanges,
    resetForm,
    isFormEnabled,
    isSubmitted,
  } = useFormContext();
  const { showDialog } = useDialogContext();

  const onDialogDiscardChanges = useCallback((doClick: () => void) => {
    setFormHasChanges(false);
    resetForm();
    doClick();
  }, []);

  const renderDialog = useCallback(
    (doClick: any) => {
      if (!isFormEnabled || isSubmitted) return;
      if (formHasChanges || hasError) {
        showDialog({
          title: 'Discard changes?',
          content:
            'Are you sure you want to stop editing without saving changes?',
          acceptButtonText: 'Discard changes',
          cancelButtonText: 'Cancel',
          onAccept: () => onDialogDiscardChanges(doClick),
        });
      }
    },
    [formHasChanges, hasError, isFormEnabled, isSubmitted],
  );

  useClickOutside(
    [
      `${ACTION_BAR_ID}_EDIT_MODE_BUTTON`,
      `${ACTION_BAR_ID}_SAVE_CHANGES_BUTTON`,
      `${ACTION_BAR_ID}_CANCEL_BUTTON`,
    ],
    renderDialog,
  );

  const { data: fetchTenantsResponse, isFetching: isTenantsFetching } =
    useFetchTenants();

  const selectedTenant: ITenant | undefined = useMemo(() => {
    if (!fetchTenantsResponse?.data?.response?.length) return;

    return fetchTenantsResponse?.data?.response?.find(
      (tenant: ITenant) => tenant.tenantCode === routeParams.tenantCode,
    );
  }, [routeParams, fetchTenantsResponse]);

  return (
    <PageContent key={TENANTS_SETTINGS_KEY}>
      <ComponentSpinner active={isTenantsFetching}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              mb: 1,
            }}
          >
            <BreadCrumbItem>
              <NavLink to="/tenants">Tenants</NavLink>
            </BreadCrumbItem>
            <BreadCrumbItem>
              <NavLink to={`/tenants/${selectedTenant?.tenantCode}`}>
                {selectedTenant?.tenantName}
              </NavLink>
            </BreadCrumbItem>
          </Breadcrumbs>
        </Box>
        <TenantsTitle variant="h4">{selectedTenant?.tenantName}</TenantsTitle>
        <TabsPanel name="Tenants_Settings">
          <TabContent {...TAB_CONTENT.SSO} />
          <TabContent {...TAB_CONTENT.ReportingData} />
          <TabContent {...TAB_CONTENT.EnabledReports} />
        </TabsPanel>
      </ComponentSpinner>
    </PageContent>
  );
};

export default TenantSettings;
