import { createTheme, ThemeOptions } from '@mui/material/styles';
import { green500, primaryBlue8, whiteMain } from './colors';

export default createTheme({
  palette: {
    blue: {
      main: primaryBlue8,
      contrastText: '#fff',
    },
    white: {
      main: whiteMain,
      contrastText: '#00',
    },
    red: {
      main: '#C62828',
      contrastText: '#fff',
    },
    green: {
      main: green500,
      contrastText: '#fff',
    },
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Gotham, sans-serif',
  },
} as ThemeOptions);
