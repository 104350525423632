import React from 'react';
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button';
import MuiBox, { BoxProps } from '@mui/material/Box';
import Card, { cardClasses } from '@mui/material/Card';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Lock from '@mui/icons-material/Lock';
import LockOpen from 'components/icons/LockOpenFilled';
import styled from 'styled-components';

import { active, disabled, disabledBackground, primaryHover, primaryMain, primaryOutlinedBorder, textSecondary, white } from 'config/theme/colors';
import Font from '../Font';

export interface IEditModeButtonIconProps extends IconButtonProps {
  isEnabled: boolean;
}

export interface IActionsBoxProps extends BoxProps {
  isEnabled: boolean;
}

// border-top: 0.063rem solid ${divider};
export const Container = styled(Card)`
  &.${cardClasses.root} {
    position: sticky;
    bottom: 0;
    display: flex;
    box-shadow: none;
    background: ${white};
    max-width: 57.5rem;
    margin-top: 2.25rem;
    padding: 0.75rem 0rem;
  }
`;

export const EditModeBox = styled(MuiBox)`
  display: flex;
  flex-grow: 1;
  vertical-align: middle;
  align-items: center;
`;

export const ActionsBox = styled(
  ({ isEnabled, ...props }: IActionsBoxProps) => isEnabled ? <MuiBox {...props} /> : null
)`
  display: flex;
  flex-grow: 0;
`;

export const EditModeLabel = styled(
  (props) => <Font variant="body2" {...props} />
)`
  color: ${textSecondary};
`;

export const EditModeButtonIcon = styled(
  ({ isEnabled, ...props }: IEditModeButtonIconProps) => {
    const icon = isEnabled ? <LockOpen /> : <Lock />;
    return (
      <IconButton {...props} disabled={isEnabled}>
        {icon}
      </IconButton>
    );
  }
)`
  color: ${active};
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
`;

export const CancelButton = styled(
  (props: ButtonProps) => <Button variant="text" {...props}>Cancel</Button>
)`
  &.${buttonClasses.root} {
    color: ${primaryMain};
    margin-right: 0.5rem;
    &:hover {
      background-color: ${primaryHover};
    }
  }
`;

export const SaveChangesButton = styled(
  (props: ButtonProps) => <Button variant="outlined" {...props}>Save Changes</Button>
)`
  &.${buttonClasses.root} {
    color: ${primaryMain};
    border: 1px solid ${primaryOutlinedBorder};
    &:hover {
      background-color: ${primaryHover};
    }
  }
  &.${buttonClasses.disabled} {
    color: ${disabled};
    border: 1px solid ${disabledBackground};
  }
`;
