import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { IDataSource } from 'types/datasource';

type IFetchDataSource = IDataSource;

interface IFetchDataSourceVariables {
  tenantCode?: string;
}

export function useFetchDataSource(
  variables: IFetchDataSourceVariables,
  queryOptions?: UseQueryOptions<
    IResponse<IFetchDataSource>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchDataSource>, IResponseError>(
    [QueryKeys.GET_DATA_SOURCE],
    () => {
      return api.get<IFetchDataSource>(
        `/datasource/getdatasource/${variables.tenantCode}`,
      );
    },
    queryOptions,
  );
}
