import React, {
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import FormSwitch, {
  IFormSwitchProps,
} from 'components/shared/FormSwitch/FormSwitch';
import FormInput, {
  IFormInputProps,
  InputType,
} from 'components/shared/FormInput/FormInput';
import FormInputPassword, {
  IFormInputPasswordProps,
} from 'components/shared/FormInputPassword/FormInputPassword';
import FormUploadFileInput, {
  IFormUploadFileInputProps,
} from 'components/shared/FormUploadFileInput/FormUploadFileInput';
import { IState } from 'components/shared/Form/Form';
import FormSelect, {
  IFormSelectProps,
  Value,
} from 'components/shared/FormSelect/FormSelect';
import { IFormContextState } from 'context/formContext';
import { detailedIsInputValid } from 'util/validation';
import FormCheckbox, {
  IFormCheckboxProps,
} from 'components/shared/FormCheckbox/FormCheckbox';

export interface IUseGetFormChildrenProps {
  children: ReactNode;
  formContext: IFormContextState;
  formData: IState;
}

/**
 * useGetFormChildren Hook
 *
 * A custom React hook for processing and enhancing form-related children components.
 *
 * @param {Object} props - The hook's input parameters.
 * @param {ReactNode} props.children - The React children components to be processed.
 * @param {IFormContextState} props.formContext - The Form Context.
 *
 * @returns {Object} - An object containing the processed form children and an 'isChanged' flag.
 */
export const useGetFormChildren = ({
  children,
  formContext,
  formData,
}: IUseGetFormChildrenProps) => {
  // State to track if any form field has changed
  const [isChanged, setIsChanged] = useState<boolean>(false);

  // Get the form context to update 'formHasChanges' in the parent form
  const { setFormHasChanges, setInputHasError, inputHasErrorMap } = formContext;

  /**
   * Validates an input value based on its type and required status.
   *
   * @param {Value} value - The input value to be validated.
   * @param {string} name - The name or identifier of the input.
   * @param {InputType} type - The type of input (e.g., 'text', 'email', 'number', 'password').
   * @param {boolean} [required] - Indicates whether the input is required (default is optional).
   */
  const validateInput = (
    value: Value | undefined,
    name: string,
    type: InputType,
    required?: boolean,
  ) => {
    // Check if the input value is invalid based on its type and required status
    const validationDetails = detailedIsInputValid(value, type, required);

    // If a callback function setInputHasError is provided,
    // update the Form Context to indicate if this input has an error
    setInputHasError?.(
      name,
      // isInvalid is expected here so using !isInvalid
      !validationDetails.isValid,
      validationDetails.failedValidationType,
    );

    // Return the validation result
    return validationDetails.isValid;
  };

  const validateAllInputs = () => {
    let areAllValid = true;
    // Loop through all form data fields
    Object.keys(formData).forEach((key) => {
      const field = formData[key];
      // Validate the input value based on its type and required status
      const isInputValid = validateInput(
        field?.value as Value,
        key,
        field.type || 'text',
        field.required,
      );

      if (!isInputValid) {
        areAllValid = false;
      }
    });

    return areAllValid;
  };

  const validateInputByName = (name: string) => {
    const field = formData[name];
    // Validate the input value based on its type and required status
    return validateInput(
      field?.value as Value,
      name,
      field.type || 'text',
      field.required,
    );
  };

  /**
   * Handle changes in form fields.
   *
   * @param {boolean} isChanged - Indicates whether the form has changed.
   */
  const handleIsChanged = (isChanged: boolean) => {
    setIsChanged(isChanged);
    setFormHasChanges(isChanged);
  };

  const prepareChildren = useCallback(
    (child: ReactNode) => {
      // If is null or undefined, return the child
      if (!isValidElement(child)) return child;

      if (child.type === FormSwitch) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormSwitchProps>;
        const switchProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          isChecked: switchProps.isChecked || false,
          legend: switchProps.legend || '',
          prop: switchProps.prop || '',
          handleOnChange: (prop: keyof IState, checked: boolean) => {
            switchProps.handleOnChange?.(prop, checked);
            handleIsChanged(true);
          },
        });
      }

      if (child.type === FormInput) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormInputProps>;
        const inputProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          hasGlobalError: inputHasErrorMap[inputProps.name],
          handleOnChange: (prop: keyof IState, value: string) => {
            inputProps.handleOnChange?.(prop, value);
            validateInput(
              value,
              inputProps.name,
              inputProps.type,
              inputProps.required,
            );
            handleIsChanged(!!value);
          },
        });
      }

      if (child.type === FormInputPassword) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormInputPasswordProps>;
        const inputProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          hasGlobalError: inputHasErrorMap[inputProps.name],
          handleOnChange: (prop: keyof IState, value: string) => {
            inputProps.handleOnChange?.(prop, value);
            validateInput(
              value,
              inputProps.name,
              'password',
              inputProps.required,
            );
            handleIsChanged(!!value);
          },
        });
      }

      if (child.type === FormSelect) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormSelectProps>;
        const inputProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          hasGlobalError: inputHasErrorMap[inputProps.name],
          handleOnSelect: (prop: keyof IState, value: Value) => {
            inputProps.handleOnSelect?.(prop, value);
            // Report changes unless it is dataSourceType
            if (prop === 'dataSourceType') {
              handleIsChanged(false);
            } else {
              handleIsChanged(!!value);
            }
            validateInput(value, prop.toString(), 'text', inputProps.required);
          },
        });
      }

      if (child.type === FormUploadFileInput) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormUploadFileInputProps>;
        const inputProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          handleOnChange: (prop: keyof IState, value: any) => {
            inputProps.handleOnChange?.(prop, value);
            // handleIsChanged(!!value);
            validateInput(
              value.name,
              prop.toString(),
              'file',
              inputProps.required,
            );
          },
        });
      }

      if (child.type === FormCheckbox) {
        const clonedChild =
          child as unknown as React.ReactElement<IFormCheckboxProps>;
        const inputProps = clonedChild.props;

        return React.cloneElement(clonedChild, {
          handleOnChange: (prop: keyof IState, value: any) => {
            inputProps.handleOnChange?.(prop, value);
            handleIsChanged(true);
          },
        });
      }

      // If none of the above, return the child
      return child;
    },
    [children, inputHasErrorMap],
  );

  const formChildren = useMemo(
    () =>
      React.Children.toArray(children).map((child) => {
        if (isValidElement(child)) {
          return prepareChildren(child);
        }
        return child;
      }),
    [prepareChildren],
  );

  return {
    formChildren,
    isChanged,
    validateAllInputs,
    validateInputByName,
  };
};
