import React from 'react';
import Box from '@mui/material/Box';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import styled from 'styled-components';
import DeltaDentalVector from 'components/icons/DeltaDentalVector';
import { primaryMain } from 'config/theme/colors';

export const StyledDrawerWrapper = styled(Box)<{
  $isStatic?: boolean;
}>`
  width: ${({ $isStatic }) => $isStatic ? '14.75rem' : 'auto'};
  height: 100vh;
`;

export const Container = styled(
  ({ isStatic, ...props }: DrawerProps & {
    isStatic?: boolean;
  }) =>
  <StyledDrawerWrapper $isStatic={isStatic}>
    <Drawer
      open
      variant='permanent'
      anchor='left'
      style={{
        width: '14.75rem'
      }}
      {...props}
    />
  </StyledDrawerWrapper>
)`
  & .MuiPaper-root {
    min-height: 100vh;
    display: flex;
    width: 14.75rem;
    padding: 1rem 0;
    flex-direction: column;
    align-items: flex-start;
    background: ${primaryMain};
    border-right: none;
    margin-top: 4rem;
  }
`;

export const StyledAvatar = styled(
  ({ ...props }: AvatarProps) =>
    <Avatar sx={{ width: 321, height: 266 }} variant='square' {...props}>
      <DeltaDentalVector />
    </Avatar>
)`
  position: fixed;
  left: -2.6rem;
  bottom: 0rem;
  background-color: transparent;
  padding: 0rem;
  font-family: 'Gotham, sans-serif';

  svg {
    width: 321px;
    height: 266px;
  }
`;
