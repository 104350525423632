import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from 'styled-components';

import { divider, primaryMain, secondaryMain, whiteMain } from 'config/theme/colors';
import { BODY_NORMAL, SUB_TEXT } from 'config/theme/typography';
import DeltaDentalLogo from 'components/icons/DeltaDentalLogo';
import ProfileIcon from 'components/icons/Profile';
import { IUser } from 'types/user';
import { TEST_ID } from './AppBar';

interface IAppBarNameProps extends BoxProps {
  companyName?: string;
}

interface IAppBarProfileMenuProps extends BoxProps {
  profile: IUser;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElement: null | HTMLElement;
}

export const AppBar = styled(MuiAppBar)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid ${divider};
  background: ${whiteMain};
  box-shadow: none;
  color: ${primaryMain};
  position: fixed;
  z-index: 999;

  & .MuiContainer-root {
    margin: 0;
    width: 100%;
  }
`;

export const AppBarName = styled(
  ({ companyName, ...props }: IAppBarNameProps) =>
    <Box sx={{ flexGrow: 1 }} {...props}>
      <Typography variant="body2">
        {companyName}
      </Typography>
    </Box>
)`
  & .MuiTypography-root {
    ${BODY_NORMAL};
    color: ${primaryMain};
  }
`;

export const AppBarLogo = styled(
  (props: BoxProps) => <Box {...props}><DeltaDentalLogo /></Box>
)`
  margin-right: 2rem;
`;

export const AppBarProfileMenu = styled(
  ({
    profile,
    handleOpen,
    handleClose,
    anchorElement,
    ...props
  }: IAppBarProfileMenuProps) => {
    const userAvatar = <ProfileIcon />;

    return (
      <Box sx={{ flexGrow: 0 }} {...props}>
        <Tooltip title={profile.name}>
          <IconButton
            onClick={handleOpen}
            sx={{ p: 0 }}
            data-testid={`${TEST_ID}-Profile-Menu-Button`}
            data-idx={`${TEST_ID}-Profile-Menu-Button`}
          >
            <Avatar>{userAvatar}</Avatar>
            <Box>
              <Typography variant="body1">{profile.displayName}</Typography>
            </Box>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="appbar-profile-menu"
          data-testid={`${TEST_ID}-Profile-Menu`}
          data-idx={`${TEST_ID}-Profile-Menu`}
          sx={{
            mt: '2.18rem',
          }}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElement)}
          onClose={handleClose}
        >
          {props.children}
        </Menu>
      </Box>
    );
  },
)`
  & .MuiButtonBase-root {
    display: flex;
    gap: 0.5rem;
  }

  & .MuiAvatar-root {
    background-color: ${secondaryMain};
    width: 1.5rem;
    height: 1.5rem;
  }
  & .MuiButtonBase-root {
    border-radius: 0%;
    background-color: ${whiteMain};
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
  & .MuiTypography-root {
    text-align: left;
  }
  & .MuiTypography-body1 {
    padding-top: 0.25rem;
    ${BODY_NORMAL};
    color: rgba(0, 0, 0, 0.87);
  }
  & .MuiTypography-body2 {
    ${SUB_TEXT};
    color: rgba(0, 0, 0, 0.6);
  }
`;
