import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { DataSourceType, IDataSource } from 'types/datasource';

type ISetDataSource = IDataSource;

interface ISetDataSourceVariables {
  servername: string;
  port: string;
  username: string;
  password: string;
  database: string;
  tenantCode: string;
  datasourceType: DataSourceType;
}

export function useSetDataSource(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetDataSource>,
    IResponseError,
    ISetDataSourceVariables
  >,
) {
  return useMutation<
    IResponse<ISetDataSource>,
    IResponseError,
    ISetDataSourceVariables
  >(
    [QueryKeys.SET_DATA_SOURCE],
    ({ datasourceType, ...args }) => {
      return api.post<ISetDataSource>(
        `/datasource/setdatasource/${datasourceType}`,
        args,
      );
    },
    mutationOptions,
  );
}
