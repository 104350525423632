import React from 'react';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import Font from '../Font';

interface IComponentSpinner {
  active?: boolean;
  text?: string;
  children?: React.ReactNode;
  spinnerContainerSx?: SxProps<Theme>
  wrapperSx?: SxProps<Theme>
}

const ComponentSpinner: React.FC<IComponentSpinner> = ({
  active = true,
  text,
  children,
  spinnerContainerSx,
  wrapperSx,
}: IComponentSpinner) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ...wrapperSx,
      }}
    >
      {!active && children}
      {active && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            minHeight: '100px',
            ...spinnerContainerSx,
          }}
        >
          <CircularProgress color="success" />
          {text && <Font sx={{ mt: 2 }}>{text}</Font>}
        </Box>
      )}
    </Box>
  );
};

export default ComponentSpinner;
