/* eslint-disable @typescript-eslint/naming-convention */

export const primaryMain = '#41A928';
export const secondaryMain = '#573D82';

export const textPrimary = '#000000';
export const textPrimaryRGB = 'rgba(0, 0, 0, 0.87)';
export const textSecondary = 'rgba(0, 0, 0, 0.6)';

export const whiteMain = '#FFFFFF';
export const infoMain = '#00ACC9';

export const divider = 'rgba(0, 0, 0, 0.12)';

export const green500 = '#41A928';
export const green600 = '#389A1F';

export const blue900 = '#00606A';
export const blue50 = '#E0F7FC';
export const blueGray50 = '#ECEFF1';

export const deepBlue900 = '#00606A';
export const deepBlue50 = '#E0F7FC';

export const deepOrange900 = '#BF360C';
export const deepOrange50 = '#FBE9E7';

export const red = '#C62828';

export const coral7 = '#EB5757';

export const yellow4 = '#FBCE53';

export const green8 = '#09873C';
export const green6 = '#56C568';
export const green4 = '#A9DFBF';
export const green2 = '#8ed07f';
export const green1 = ' rgba(65, 169, 40, 1)';

export const secondaryBlue7 = '#4372B0';

export const primaryBlue8 = '#345580';
export const primaryBlue1 = '#ECF1F7';

export const gray10 = '#111111';
export const gray8 = '#505963';
export const gray7 = '#6A727A';
export const gray2 = '#E9EBF0';
export const gray1 = '#F5F7FA';
export const gray100 = '#F5F5F5';

export const black = '#000000';
export const white = '#FFFFFF';

export const alertInfoBackground = '#E0F7FC';
export const alertInfoColor = '#00606A';

export const disabled = 'rgba(0, 0, 0, 0.38)';
export const active = 'rgba(0, 0, 0, 0.56)';

export const primaryOutlinedBorder = 'rgba(45, 176, 53, 0.5)';
export const disabledBackground = 'rgba(0, 0, 0, 0.12)';
export const knowFillDisabled = 'rgba(245, 245, 245, 1)';
export const primaryHover = 'rgba(45, 176, 53, 0.04)';
export const primaryDark = 'rgba(56, 154, 31, 1)';
export const errorMain = 'rgba(211, 47, 47, 1)';
export const successMain = 'rgba(0, 0, 0, 0.87)';
export const actionHover = 'rgba(0, 0, 0, 0.04)';
