import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import {
  AcceptButton,
  CancelButton,
  Container,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from './Dialog.styles';
import { CircularProgress } from '@mui/material';

export const DIALOG_ID = 'DIALOG';

export interface IDialogProps {
  title?: string;
  content?: string | React.ReactNode;
  isOpen: boolean;
  acceptButtonText?: string;
  cancelButtonText?: string;
  onClose?: () => void;
  onCancel?: () => void;
  onAccept?: () => void;
  instantlyCloseOnAccept?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  isAcceptDisabled?: boolean;
}

export const Dialog: React.FC<IDialogProps> = ({
  title,
  content,
  isOpen,
  acceptButtonText,
  cancelButtonText,
  onAccept,
  onCancel,
  onClose,
  instantlyCloseOnAccept = true,
  isLoading = false,
  children,
  isAcceptDisabled = false,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  const handleAccept = () => {
    onAccept?.();
    if (instantlyCloseOnAccept) {
      handleClose();
    }
  };

  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };

  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  return (
    <Container
      open={isOpen}
      onClose={handleClose}
      onClick={handleBackdropClick}
      id={DIALOG_ID}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText dangerouslySetInnerHTML={{ __html: content }} />
        {children}
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={handleCancel}
          id={`${DIALOG_ID}_CANCEL_BUTTON`}
          disabled={isLoading}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {isLoading && <CircularProgress size={14} color="inherit" />}
          {cancelButtonText || 'Cancel'}
        </CancelButton>
        <AcceptButton
          onClick={handleAccept}
          autoFocus
          id={`${DIALOG_ID}_ACCEPT_BUTTON`}
          disabled={isLoading || isAcceptDisabled}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {isLoading && <CircularProgress size={14} color="inherit" />}
          {acceptButtonText}
        </AcceptButton>
      </DialogActions>
    </Container>
  );
};

export default Dialog;
