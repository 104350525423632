import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import api from 'api';

interface IPermissionRequest {
  clientId: string;
  groupId: string;
  subgroupId: string;
  userLogin: string;
}

interface IAddPermissionResponse {
  permissionId: number;
  tenant: {
    tenantId: number;
    code: string;
  };
  userLogin: string;
  clientId: string;
  groupId: string;
  subgroupId: string;
  groupName: string;
}

export function useAddPermission(
  mutationOptions?: UseMutationOptions<
    IResponse<IAddPermissionResponse>,
    IResponseError,
    IPermissionRequest
    >,
){
  return useMutation<
    IResponse<IAddPermissionResponse>,
    IResponseError,
    IPermissionRequest
    >(
      [QueryKeys.ADD_PERMISSION],
      (args) => {
        return api.post<IAddPermissionResponse>(`/permission/addpermission`, args);
      },
      mutationOptions
    );
}
