import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ISamlConfiguration } from 'types/samlConfiguration';

type ISetSamlConfigurationData = ISamlConfiguration;

interface ISetSamlConfigurationVariables {
  idpMetadataEndpoint: string;
  entityId: string;
  accessGroupId: string;
  tenantAdminGroupId: string;
  productAdminGroupId: string;
  permissionsAdminGroupId: string;
  applicationID: string;
  applicationName: string;
  tenantCode: string;
}

export function useSetSamlConfiguration(
  mutationOptions?: UseMutationOptions<
    IResponse<ISetSamlConfigurationData>,
    IResponseError,
    ISetSamlConfigurationVariables
  >,
) {
  return useMutation<
    IResponse<ISetSamlConfigurationData>,
    IResponseError,
    ISetSamlConfigurationVariables
  >(
    [QueryKeys.SET_SAML_CONFIGURATION],
    (args) => {
      return api.post<ISetSamlConfigurationData>(
        '/login/samlconfiguration',
        args,
      );
    },
    mutationOptions,
  );
}
