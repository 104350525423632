import React from 'react';
import { ListItem, ListItemIcon, ListItemButton, ListItemText } from './NavItem.styles';

export interface INavItem {
  icon: React.ReactNode;
  name: string;
  url: string;
  isSelected?: boolean;
}

export interface IDrawerItemProps {
  item: INavItem;
  onSelectItem?: (item: INavItem) => void;
  currentRoute?: string;
}

export const NavItem: React.FC<IDrawerItemProps> = ({ item, onSelectItem, currentRoute }: IDrawerItemProps) => {
  return (
    <ListItem key={item.name} disablePadding selected={item.url === currentRoute || item.isSelected} onClick={() => onSelectItem?.(item)}>
      <ListItemButton>
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
