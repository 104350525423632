export enum QueryKeys {
  UPDATE_SETTINGS_REPORT_VISIBILITY = 'UPDATE_SETTINGS_REPORT_VISIBILITY',
  FILE_UPLOAD = 'FILE_UPLOAD',
  LOGIN_CONFIGURATION = 'LOGIN_CONFIGURATION',
  ME = 'ME',
  LOGOUT = 'LOGOUT',
  TENANT_BRANDING = 'TENANT_BRANDING',
  SET_TENANT_BRANDING = 'SET_TENANT_BRANDING',
  GET_CONTACT = 'GET_CONTACT',
  SET_CONTACT = 'SET_CONTACT',
  GET_TENANTS = 'GET_TENANTS',
  GET_SAML_CONFIGURATION = 'GET_SAML_CONFIGURATION',
  SET_SAML_CONFIGURATION = 'SET_SAML_CONFIGURATION',
  GET_SAML_REPLY_URL = 'GET_SAML_REPLY_URL',
  GET_JWT_CONFIGURATION = 'GET_JWT_CONFIGURATION',
  SET_JWT_CONFIGURATION = 'SET_JWT_CONFIGURATION',
  GET_DATA_SOURCE = 'GET_DATA_SOURCE',
  SET_DATA_SOURCE = 'SET_DATA_SOURCE',
  GET_ALL_DASHBOARDS = 'GET_ALL_DASHBOARDS',
  SET_DEFAULT_DASHBOARDS = 'SET_DEFAULT_DASHBOARDS',
  GET_PUBLISHED_DASHBOARDS = 'GET_PUBLISHED_DASHBOARDS',
  SET_PUBLISHED_DASHBOARDS = 'SET_PUBLISHED_DASHBOARDS',
  GET_DEFAULT_DASHBOARD = 'GET_DEFAULT_DASHBOARD',
  GET_PERMISSIONS_TABLE = 'GET_PERMISSIONS_TABLE',
  SET_REMOVE_PERMISSIONS = 'SET_REMOVE_PERMISSIONS',
  GET_PERMISSIONS_MAP_CLIENT = 'GET_PERMISSIONS_MAP_CLIENT',
  GET_PERMISSIONS_MAP_GROUP = 'GET_PERMISSIONS_MAP_GROUP',
  GET_PERMISSIONS_MAP_SUBGROUP = 'GET_PERMISSIONS_MAP_SUBGROUP',
  ADD_PERMISSION = 'ADD_PERMISSION'
}
