import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import Form, { IState } from 'components/shared/Form/Form';
import FormSwitch from 'components/shared/FormSwitch/FormSwitch';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import { useFormContext } from 'context/formContext';
import { useFetchPublishedDashboards } from 'api/hooks/useFetchPublishedDashboards';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import { useSetPublishedDashboards } from 'api/hooks/useSetPublishedDashboards';

const DISCARD_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Discard changes?',
  content: 'Are you sure you want to stop editing without saving changes?',
  acceptButtonText: 'Discard changes',
  cancelButtonText: 'Cancel',
};

const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Apply changes?',
  content:
    'Remember you are changing which Dashboards the Tenant will have access to.<br /><br />Are you sure you want to proceed?',
  acceptButtonText: 'Apply Changes',
  cancelButtonText: 'Cancel',
};

const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
  isOpen: false,
  title: 'Edit Enabled Dashboards details?',
  content:
    'Remember that any changes made here will affect which Dashboards the Tenant will have access to.<br /><br /> Are you sure you want to proceed?',
  acceptButtonText: 'Start Editing',
  cancelButtonText: 'Cancel',
};

const DEFAULT_DIALOG_PROPS = {
  discard: DISCARD_DIALOG_PROPS,
  applyChanges: APPLY_CHANGES_DIALOG_PROPS,
  editApproval: EDIT_APPROVAL_DIALOG_PROPS,
};

const FORM_KEY = 'form-enabled-reports';

export const EnabledReportsTab = () => {
  const queryParams = useParams<{
    tenantCode: string;
  }>();
  const { setIsFormEnabled, resetForm, setFormIsSubmitted } = useFormContext();
  const [data, setData] = useState<IState | null>(null);
  const [initialData, setInitialData] = useState<IState | null>(null);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );
  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const { data: fetchedPublishedDashboardsResponse, isFetching } =
    useFetchPublishedDashboards(
      {
        tenantCode: queryParams.tenantCode,
      },
      {
        onSuccess: (requestResponse) => {
          const publishedDashboards = requestResponse?.data?.response || [];

          let dataToSet = publishedDashboards.reduce(
            (acc, dashboard) => ({
              ...acc,
              [dashboard.name]: {
                legend: dashboard.name.replaceAll(/_/g, ' '),
                value: dashboard.isPublished,
              },
            }),
            {} as IState,
          );

          const isAllCheckedByDefault = publishedDashboards.every(
            (dashboard) => dashboard.isPublished,
          );

          dataToSet = {
            ...dataToSet,
            all: {
              legend: 'All',
              value: isAllCheckedByDefault,
            },
          };

          setData(dataToSet);
          setInitialData(dataToSet);
        },
      },
    );

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Reports Visibility have been saved and applied successfully.',
      toastType: 'success',
    });
    handleCloseDialog();
    setInitialData(data);
    setIsFormEnabled(false);
    setFormIsSubmitted(true);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    isLoading: isMutating,
    isSuccess,
    isError,
    mutate: setPublishedDashboardsMutation,
  } = useSetPublishedDashboards({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleSwitchChange = useCallback(
    (prop: keyof IState, isChecked: boolean) => {
      setData((prevData) => {
        const newData = {
          ...prevData,
          [prop]: {
            ...(prevData as IState)[prop],
            value: isChecked,
          },
        };

        const shouldAllBeChecked = Object.entries(newData)
          .filter(
            ([key]) =>
              // Filter out the 'all' switch (it's a special case and it has it's own component with a divider)
              key !== 'all',
          )
          .every(([, item]) => item.value);

        return {
          ...newData,
          all: {
            ...(prevData as IState)?.all,
            value: shouldAllBeChecked,
          },
        };
      });
    },
    [],
  );

  const handleOnCancelEditMode = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(initialData);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
  };

  const handleOnApplyChangesDialog = async () => {
    const dashboardsToPublish = Object.entries(data as IState)
      // Filter out the 'all' switch (it's a special case and it has it's own component with a divider)
      .filter(([key]) => key !== 'all')
      .map(([key, item]) => ({
        id: fetchedPublishedDashboardsResponse?.data?.response?.find(
          (dashboard) => dashboard.name === key,
        )?.id as string,
        isPublished: Boolean(item.value),
      }));

    await setPublishedDashboardsMutation({
      tenantCode: queryParams.tenantCode as string,
      dashboards: dashboardsToPublish,
    });
  };

  const handleSubmitChanges = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
    setIsModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnEditApprovalDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
  };

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnEditApprovalDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;

  }, [
    handleOnDisCardChangesDialog,
    handleCloseDialog,
    handleOnApplyChangesDialog,
    handleOnEditApprovalDialog,
  ]);

  const formSwitches = useMemo(() => {
    return (
      Object.entries((data as IState) || {})
        // Filter out the 'all' switch (it's a special case and it has it's own component with a divider)
        .filter(([key]) => key !== 'all')
        .map(([key, item]) => (
          <FormSwitch
            key={key}
            prop={key}
            legend={item.legend || ''}
            isChecked={Boolean(item.value)}
            handleOnChange={handleSwitchChange}
          />
        ))
    );
  }, [data]);

  const handleAllSwitchChange = useCallback(
    (_prop: keyof IState, isChecked: boolean) => {
      setData((prevData) => {
        const newState = {
          ...prevData,
          all: {
            ...(prevData as IState)?.all,
            value: isChecked,
          },
          ...Object.entries(prevData as IState)
            // Filter out the 'all' switch (it's a special case and it has it's own component with a divider)
            .filter(([key]) => key !== 'all')
            .reduce((acc, [key, item]) => {
              return {
                ...acc,
                [key]: {
                  ...item,
                  value: isChecked,
                },
              };
            }, {} as IState),
        };

        return newState;
      });
    },
    [data],
  );

  return (
    <>
      <Toast {...toastOptions} open={isRequestFinished} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        instantlyCloseOnAccept={false}
        isLoading={isMutating}
      />
      <ComponentSpinner active={isFetching}>
        {data && (
          <Form
            state={data}
            handleCancel={handleOnCancelEditMode}
            handleSubmit={handleSubmitChanges}
            key={formKey}
            className="switches"
            handleShowApproveEditDialog={handleShowApproveEditDialog}
          >
            <FormSwitch
              key="all"
              prop="all"
              legend="All"
              isChecked={Boolean(data.all.value)}
              handleOnChange={handleAllSwitchChange}
            />
            <Divider sx={{ my: 2 }} />
            {formSwitches}
          </Form>
        )}
      </ComponentSpinner>
    </>
  );
};

export default EnabledReportsTab;
