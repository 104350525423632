import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Form, {
  IForwardedProps,
  IState,
  Value,
} from 'components/shared/Form/Form';
import Dialog, { IDialogProps } from 'components/presentation/Dialog/Dialog';
import Toast from 'components/shared/Toast/Toast';
import { IToastProps } from 'components/shared/Toast/Toast.types';
import usePrevious from 'api/hooks/usePrevious';
import { FormInput } from 'components/shared/FormInput/FormInput';
import { FormInputPassword } from 'components/shared/FormInputPassword/FormInputPassword';
import FormUploadFileInput, {
  IJSONContent,
} from 'components/shared/FormUploadFileInput/FormUploadFileInput';
import FormSelect from 'components/shared/FormSelect/FormSelect';
import { useFormContext } from 'context/formContext';
import FormCheckbox from 'components/shared/FormCheckbox/FormCheckbox';
import { DataSourceType } from 'types/datasource';
import { useFetchDataSource } from 'api/hooks/useFetchDatasource';
import ComponentSpinner from 'components/shared/Spinner/ComponentSpinner';
import Font from 'components/shared/Font';
import { Box, Button } from '@mui/material';
import { useSetDataSource } from 'api/hooks/useSetDataSource';
import { useAuth } from 'context/Auth';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'constants/query-keys';

export type ReportDataFields = {
  [dataSourceType in DataSourceType]: IState;
};

const dataSourceTypeSelect = {
  value: DataSourceType.SNOWFLAKE,
  defaultValue: DataSourceType.SNOWFLAKE,
  legend: 'Data Source Type',
  options: [
    { value: DataSourceType.SNOWFLAKE, label: 'Snowflake' },
    { value: DataSourceType.REDSHIFT, label: 'Redshift' },
    { value: DataSourceType.AMAZONRDSMYSQL, label: 'MySQL' },
    { value: DataSourceType.AMAZONRDSPOSTGREQL, label: 'PostgreSQL' },
    { value: DataSourceType.AMAZONRDSMARIADB, label: 'MariaDB' },
    { value: DataSourceType.AMAZONRDSSQLSERVER, label: 'SQL Server' },
    { value: DataSourceType.AMAZONRDSAURORAMYSQL, label: 'Aurora MySql' },
    { value: DataSourceType.AMAZONRDSAURORAPGSQL, label: 'Aurora PostgreSQL' },
  ],
  required: true,
};

const RELATIONAL_DB_DEFAULTS: IState = {
  dataSourceType: dataSourceTypeSelect,
  servername: {
    value: '',
    defaultValue: '',
    legend: 'Servername',
    required: true,
    type: 'text',
  },
  port: {
    value: null,
    defaultValue: null,
    legend: 'Port',
    required: true,
    type: 'number',
  },
  username: {
    value: '',
    defaultValue: '',
    legend: 'Username',
    required: true,
    type: 'text',
  },
  password: {
    value: '',
    defaultValue: '',
    legend: 'Password',
    required: true,
    type: 'password',
  },
  database: {
    value: '',
    defaultValue: '',
    legend: 'Database Name',
    required: true,
    type: 'text',
  },
};

const REPORT_DATA_STATE: ReportDataFields = {
  [DataSourceType.SNOWFLAKE]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.REDSHIFT]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSMYSQL]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSPOSTGREQL]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSMARIADB]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSSQLSERVER]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSAURORAMYSQL]: RELATIONAL_DB_DEFAULTS,
  [DataSourceType.AMAZONRDSAURORAPGSQL]: RELATIONAL_DB_DEFAULTS,
};

const FORM_KEY = 'form-Database';

interface IProps {
  isTenantScoped?: boolean;
}

export const ReportingDataTab: React.FC<IProps> = ({
  isTenantScoped = false,
}: IProps) => {
  const EDIT_APPROVAL_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Replace the Current Data Source?',
    content: `Any changes made to the Data Source will affect what data is underlying ${
      isTenantScoped ? 'the Tenant’s Reports' : 'your Reports'
    }.<br /><br /><b>Note:</b> Replacing the Data Source will permanently remove any previous record. Please ensure you have these details recorded elsewhere if needed.<br /><br />Are you sure you want to proceed?`,
    acceptButtonText: 'Yes, continue',
    cancelButtonText: 'Cancel',
  };

  const DISCARD_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Discard changes?',
    content: 'Are you sure you want to stop editing without saving changes?',
    acceptButtonText: 'Discard changes',
    cancelButtonText: 'Cancel',
  };

  const APPLY_CHANGES_DIALOG_PROPS: IDialogProps = {
    isOpen: false,
    title: 'Replace the Current Data Source?',
    content: `Any changes made to the Data Source will affect what data is underlying ${
      isTenantScoped ? 'the Tenant’s Reports' : 'your Reports'
    }.<br /><br /> <b>Note:</b> Replacing the Data Source will permanently remove any previous record. Please ensure you have these details recorded elsewhere if needed.<br /> <br />Are you sure you want to proceed?`,
    acceptButtonText: 'Apply Changes',
    cancelButtonText: 'Cancel',
  };

  const DEFAULT_DIALOG_PROPS = {
    editApproval: EDIT_APPROVAL_DIALOG_PROPS,
    discard: DISCARD_DIALOG_PROPS,
    applyChanges: APPLY_CHANGES_DIALOG_PROPS,
  };

  const [formReference, setFormReference] = useState<IForwardedProps | null>(
    null,
  );
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const queryParams = useParams<{ tenantCode: string }>();
  const tenantCode = isTenantScoped
    ? queryParams?.tenantCode
    : user?.tenantCode;
  const defaultDBType = DataSourceType.SNOWFLAKE;
  const {
    setIsFormEnabled,
    resetForm,
    clearErrors,
    setFormHasChanges,
    hasChanges,
  } = useFormContext();
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<IState>(
    REPORT_DATA_STATE[DataSourceType.SNOWFLAKE],
  );
  const [dataSourceType, setDataSourceType] =
    useState<DataSourceType>(defaultDBType);
  const [selectedJSONFileContent, setSelectedJSONFileContent] =
    useState<IJSONContent>();
  const [triggerCancelAction, setTriggerCancelAction] =
    useState<boolean>(false);
  const [formKey, setFormKey] = useState<string>(FORM_KEY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toastOptions, setToastOptions] = useState<IToastProps | null>();
  const [currentDialog, setCurrentDialog] = useState<IDialogProps>(
    DEFAULT_DIALOG_PROPS.discard,
  );
  const previousData = usePrevious(data);

  const {
    data: fetchCurrentDataSourceResponse,
    isFetching: isDataSourceLoading,
  } = useFetchDataSource({
    tenantCode,
  });

  const handleCloseDialog = () => setIsModalOpen(false);

  const resetFormKey = () =>
    setFormKey(`${FORM_KEY}-${new Date().toTimeString()}`);

  const onApplyChangesSuccess = () => {
    setToastOptions({
      message:
        'Changes to Reporting Data have been saved and applied successfully.',
      toastType: 'success',
    });
    setIsFormModalOpen(false);
    handleCloseDialog();
    setIsFormEnabled(false);
    resetForm();
    resetFormKey();
    // refetching query to get the latest changes
    queryClient.refetchQueries([QueryKeys.GET_DATA_SOURCE]);
  };

  const onApplyChangesError = () => {
    setToastOptions({
      message: 'An unexpected error occurred while saving. Please try again.',
      toastType: 'error',
    });
    handleCloseDialog();
  };

  const {
    mutate: setDataSourceMutation,
    isLoading: isMutating,
    isSuccess,
    isError,
  } = useSetDataSource({
    onSuccess: onApplyChangesSuccess,
    onError: onApplyChangesError,
  });

  const isRequestFinished = !isMutating && (isError || isSuccess);

  const handleStateChange = useCallback((prop: keyof IState, value: Value) => {
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...prevData[prop],
        value,
      },
    }));
  }, []);

  const handleOnChange = (prop: keyof IState, value: Value) => {
    handleStateChange(prop, value);
    setTriggerCancelAction(false);
  };

  const onFileSelected = (file: File, content?: IJSONContent) => {
    if (file) {
      setSelectedJSONFileContent(content);
      setFormHasChanges(false);
    }
  };

  const handleOnSelectDBType = (prop: keyof IState, value: Value) => {
    const dbType = value as DataSourceType;
    setDataSourceType(dbType);
    setData(REPORT_DATA_STATE[dbType]);
    setTriggerCancelAction(true);
    clearErrors();
  };

  const handleOnSelect = (prop: keyof IState, value: Value) => {
    // For DataSourceType we should update another state
    if (prop === 'dataSourceType') {
      handleOnSelectDBType(prop, value);
    } else {
      handleStateChange(prop, value);
    }
  };

  const handleOnCancelEditMode = () => {
    if (hasChanges) {
      setCurrentDialog(DEFAULT_DIALOG_PROPS.discard);
      setIsModalOpen(true);
    } else {
      // Reset form if user only change data source type
      handleOnDisCardChangesDialog();
    }
  };

  const handleOnApproveEditDialog = () => {
    setIsFormEnabled(true);
    handleCloseDialog();
    setIsFormModalOpen(true);
  };

  const handleShowApproveEditDialog = () => {
    setCurrentDialog(DEFAULT_DIALOG_PROPS.editApproval);
    setIsModalOpen(true);
  };

  const handleOnDisCardChangesDialog = () => {
    setData(previousData);
    setDataSourceType(defaultDBType);
    resetForm();
    resetFormKey();
    handleCloseDialog();
    setIsFormEnabled(false);
    setTriggerCancelAction(false);
    setIsFormModalOpen(false);
  };

  const handleOnApplyChangesDialog = async () => {
    await setDataSourceMutation({
      database: data.database.value as string,
      datasourceType: dataSourceType,
      password: data.password.value as string,
      port: data.port.value?.toString() as string,
      servername: data.servername.value as string,
      username: data.username.value as string,
      tenantCode: tenantCode as string,
    });
  };

  const handleSubmitChanges = () => {
    if (formReference?.validateAllInputs()) {
      setCurrentDialog(DEFAULT_DIALOG_PROPS.applyChanges);
      setIsModalOpen(true);
    }
  };

  const formInputs = useMemo(() => {
    return Object.entries(data).map(([key, item]) => {
      // dataSourceType and project are the only ones that are FormSelect
      const isSelect = ['dataSourceType', 'project'].includes(key);
      const isPassword = key === 'password';
      const isFile = key === 'file';
      const isCheckbox = key === 'enableSSL';
      // Set a key with dataSourceType as prefix to each input
      // in order to re render component and prevent keeping previous states.
      const inputKey = `${dataSourceType}-${key}`;

      if (isSelect) {
        return (
          <FormSelect
            key={inputKey}
            name={key}
            required
            id={key}
            prop={key}
            options={data[key].options || []}
            label={item.legend || ''}
            handleOnSelect={handleOnSelect}
            defaultValue={dataSourceType}
            disabled={data[key].disabled}
            fullWidth
            sx={{
              mb: 3,
            }}
          />
        );
      }

      if (isPassword) {
        return (
          <FormInputPassword
            required
            key={inputKey}
            type={'password'}
            id={key}
            name={key}
            label={item.legend || ''}
            prop={key}
            handleOnChange={handleOnChange}
            defaultValue={data[key].defaultValue}
            inputSx={{
              width: '100%',
            }}
            inputContainerSx={{
              width: '100% !important',
              mb: 3,
            }}
            fullWidth
          />
        );
      }

      if (isFile) {
        return (
          <FormUploadFileInput
            required={data[key].required}
            key={inputKey}
            id={key}
            fileTypes={['json']}
            name={key}
            prop={key}
            handleOnChange={handleOnChange}
            onSuccess={onFileSelected}
            fullWidth
            sx={{
              mb: 3,
            }}
          />
        );
      }

      if (isCheckbox) {
        return (
          <FormCheckbox
            label={data[key].legend}
            required={data[key].required}
            key={inputKey}
            id={key}
            name={key}
            prop={key}
            handleOnChange={handleOnChange}
            defaultValue={!!data[key].defaultValue}
          />
        );
      }

      return (
        <FormInput
          required
          key={inputKey}
          type={key === 'port' ? 'number' : 'text'}
          id={key}
          name={key}
          prop={key}
          label={item.legend || ''}
          handleOnChange={handleOnChange}
          defaultValue={data[key].defaultValue}
          width="100%"
          sx={{
            mb: 3,
          }}
        />
      );
    });
  }, [data]);

  useMemo(() => {
    DEFAULT_DIALOG_PROPS.editApproval.onAccept = handleOnApproveEditDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.editApproval.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.discard.onAccept = handleOnDisCardChangesDialog;
    DEFAULT_DIALOG_PROPS.discard.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.discard.onClose = handleCloseDialog;

    DEFAULT_DIALOG_PROPS.applyChanges.onAccept = handleOnApplyChangesDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onCancel = handleCloseDialog;
    DEFAULT_DIALOG_PROPS.applyChanges.onClose = handleCloseDialog;
  }, [
    handleOnApproveEditDialog,
    handleCloseDialog,
    handleOnDisCardChangesDialog,
    handleOnApplyChangesDialog,
  ]);

  // TODO: Uncomment this when we'll have the BIG QUERY data source endpoint
  // useEffect(() => {
  //   if (
  //     dataSourceType === DataSourceType.BIG_QUERY &&
  //     selectedJSONFileContent
  //   ) {
  //     const isArray = Array.isArray(selectedJSONFileContent);
  //     const options: IFormSelectOption[] = [];
  //     if (isArray) {
  //       selectedJSONFileContent.map((item) => {
  //         options.push({
  //           label: item.project_id,
  //           value: item.project_id,
  //         });
  //       });
  //     } else {
  //       options.push({
  //         label: selectedJSONFileContent.project_id,
  //         value: selectedJSONFileContent.project_id,
  //       });
  //     }

  //     setData((prevData) => ({
  //       ...prevData,
  //       project: {
  //         ...prevData.project,
  //         options,
  //         disabled: false,
  //         placeholder: 'Select project',
  //       },
  //     }));
  //   }
  // }, [selectedJSONFileContent, dataSourceType]);

  return (
    <>
      <Toast open={isRequestFinished} {...toastOptions} />
      <Dialog
        {...currentDialog}
        isOpen={isModalOpen}
        instantlyCloseOnAccept={false}
        isLoading={isMutating}
      />
      <Dialog
        isOpen={isFormModalOpen}
        title="Connect New Data Source"
        acceptButtonText="Save Changes"
        cancelButtonText="Cancel"
        isLoading={isMutating}
        instantlyCloseOnAccept={false}
        onAccept={handleSubmitChanges}
        isAcceptDisabled={!hasChanges}
        onCancel={handleOnCancelEditMode}
      >
        <Form
          state={data}
          handleShowApproveEditDialog={handleShowApproveEditDialog}
          key={formKey}
          triggerCancelAction={triggerCancelAction}
          isLockAble={false}
          isFlex
          isFlexColumn
          noMarginsOnFormControls
          onLoadForm={setFormReference}
        >
          {formInputs}
        </Form>
      </Dialog>
      <ComponentSpinner active={isDataSourceLoading}>
        <Box
          sx={{
            width: '100%',
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            padding: '16px',
          }}
        >
          <Font
            variant="h6"
            sx={{
              mb: 1,
            }}
          >
            Current Connection
          </Font>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font variant="body1">Connection Type</Font>
              <Font variant="subtitle1">
                {fetchCurrentDataSourceResponse?.data?.response?.providerType}
              </Font>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font variant="body1">Creation Date</Font>
              <Font variant="subtitle1">
                {dayjs(
                  fetchCurrentDataSourceResponse?.data?.response
                    ?.itemCreatedDate,
                ).format('DD/MM/YYYY[,] HH:mm A')}
              </Font>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font variant="body1">Last Modified</Font>
              <Font variant="subtitle1">
                {dayjs(
                  fetchCurrentDataSourceResponse?.data?.response
                    ?.itemModifiedDate,
                ).format('DD/MM/YYYY[,] HH:mm A')}
              </Font>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            color="success"
            onClick={handleShowApproveEditDialog}
          >
            REPLACE DATA SOURCE
          </Button>
        </Box>
      </ComponentSpinner>
    </>
  );
};

export default ReportingDataTab;
