import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';
import { ISamlReplyUrl } from 'types/samlReplyUrl';

type IFetchSamlReplyUrl = ISamlReplyUrl;

interface IFetchSamlReplyUrlVariables {
  tenantCode?: string;
}

export function useFetchSamlReplyUrl(
  variables: IFetchSamlReplyUrlVariables,
  queryOptions?: UseQueryOptions<
    IResponse<IFetchSamlReplyUrl>,
    IResponseError
  >,
) {
  return useQuery<IResponse<IFetchSamlReplyUrl>, IResponseError>(
    [QueryKeys.GET_SAML_REPLY_URL],
    () => {
      return api.get<IFetchSamlReplyUrl>(
        `/login/samlreplyurl/${variables.tenantCode}`,
      );
    },
    queryOptions,
  );
}
