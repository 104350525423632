import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import api from 'api';
import { IResponseError, IResponse } from 'api/types';
import { QueryKeys } from 'constants/query-keys';

interface IFetchLoginConfigurationData {
  tenant?: {
    tenantId: number;
    code: string;
  };
  loginClass?: {
    name: string;
    behavior: string;
    redirectTarget: string;
  };
}


export function useFetchLoginConfiguration(
  queryOptions?: UseQueryOptions<
    IResponse<IFetchLoginConfigurationData>,
    IResponseError
  >,
) {
  return useQuery<
    IResponse<IFetchLoginConfigurationData>,
    IResponseError
  >(
    [QueryKeys.LOGIN_CONFIGURATION],
    () => {
      return api.get<IFetchLoginConfigurationData>('/login/configuration');
    },
    queryOptions,
  );
}
