import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { BODY_BOOK, H3_MEDIUM } from 'config/theme/typography';
import { gray8, primaryBlue8, secondaryBlue7 } from 'config/theme/colors';

export interface IBlankSlateProps {
  className?: string;
  containerStyle?: React.CSSProperties;
  titleText: string;
  titleStyle?: React.CSSProperties;
  description?: React.ReactNode;
  descriptionStyle?: React.CSSProperties;
  icon?: React.ReactNode;
  fitToViewport?: boolean;
}

export const BlankSlate: React.FC<IBlankSlateProps> = ({
  titleText,
  description,
  icon,
  descriptionStyle,
  fitToViewport,
  ...props
}) => {
  const containerRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (fitToViewport && containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();
      // Substract 1 additional px to consider borders of elements above the slate
      containerRef.current.style.height = `calc(100vh - ${
        boundingRect.top + 1
      }px)`;
      containerRef.current.style.padding = '0';
    }
  }, [fitToViewport]);

  return (
    <Container
      ref={containerRef}
      className={props.className}
      style={props.containerStyle}
    >
      {icon || null}
      <Content>
        <Title style={props.titleStyle} data-testid="content-title">
          {titleText}
        </Title>
        {description && (
          <Description
            style={descriptionStyle}
            data-testid="content-description"
          >
            {description}
          </Description>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
  padding-top: 14.438rem;
  margin: 0 auto;
  min-height: 20rem;
`;

const Content = styled.div`
  display: grid;
  gap: 1rem;
  text-align: center;
`;

export const Title = styled.h3`
  color: ${primaryBlue8};
  ${H3_MEDIUM};
`;
Title.displayName = 'Title';

const Description = styled.p`
  &,
  & * {
    ${BODY_BOOK};
    letter-spacing: 0.005em;
  }
  color: ${gray8};

  a {
    color: ${secondaryBlue7};
  }
`;
Description.displayName = 'Description';

export default BlankSlate;
